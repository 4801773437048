import React, { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios"
import NotyfContext from "../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import { PageLoader } from '../../../components/Elements.js'
import { QrReader } from 'react-qr-reader';
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion"
import { domainConfig } from "../../../assets/config.js"
import { faArrowLeft, faRefresh, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import {
    MediaPermissionsErrorType,
    requestMediaPermissions
} from 'mic-check';
// import { MoreInfoQRCode } from '../components/Contacts'

const QRcode = () => {
    const { dataApplication } = useGlobalState();
    const [videoPermission, setVideoPermission] = useState();
    const [updatePermission, setUpdatePermission] = useState(false);
    const { t } = useTranslation();
    const [QRcodeMessage, setQRcodeMessage] = useState(t('t66'));
    const [visitanteData, setVisitanteData] = useState({ 'visitante_id': 0 })
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        requestMediaPermissions({ audio: false, video: true })
            .then(function () {
                setVideoPermission(true);
            })
            .catch(function (err) {
                const { type } = err;
                if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: t('t68'),
                        ripple: true,
                        dismissible: true,
                    });
                    // browser does not have permission to access camera or microphone
                } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                    // user didn't allow app to access camera or microphone
                    setVideoPermission(false)
                    requestMediaPermissions({ audio: false, video: true })
                        .then(function () {
                            setVideoPermission(true);
                        })
                        .catch(function () {
                            notyf.open({
                                type: "danger",
                                message: t('t68'),
                                ripple: true,
                                dismissible: true,
                            });
                        });
                } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                    setVideoPermission(true)
                    return
                } else {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: t('t67'),
                        ripple: true,
                        dismissible: true,
                    });
                    // not all error types are handled by this library
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePermission])

    if (videoPermission === undefined) {
        return (
            <motion.div className="pageLoaderContainer" key={'pageLoader'} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width="75" />
            </motion.div>
        )
    }

    return (
        <motion.div className="default-box justify-content-start" initial={{ opacity: 0 }} key={'contactContainer'}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="box-full">
                <AnimatePresence mode="wait">
                    <QRcodeHandler key={'contactHandler' + visitanteData.visitante_id} visitanteData={visitanteData} setVisitanteData={setVisitanteData} QRcodeMessage={QRcodeMessage}
                        setQRcodeMessage={setQRcodeMessage} videoPermission={videoPermission} setUpdatePermission={setUpdatePermission}>
                    </QRcodeHandler>
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

export const QRcodeHandler = ({ visitanteData, setVisitanteData, QRcodeMessage, setQRcodeMessage, videoPermission, setUpdatePermission }) => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const scanning = useRef(false)

    function handleResult(url) {
        if (scanning.current === false && visitanteData.visitante_id === 0) {
            setQRcodeMessage(t('t92') + '...')
            scanning.current = true
            let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            let segments = url.split('/');
            let lastSegment = segments[segments.length - 1];
            // eslint-disable-next-line no-unused-vars
            let [result, queryString] = lastSegment.split('?');
            let decoded = decodeURIComponent(result);
            if (!base64regex.test(decoded)) {
                setTimeout(() => {
                    setQRcodeMessage(t('t65'))
                    scanning.current = false
                    return
                }, 2500);
            }
            decoded = window.atob(decoded)
            let id = decoded.split("=")
            id = id[1]
            // eslint-disable-next-line eqeqeq
            if (id > 0 && id != dataApplication.visitante.visitante_id) {
                axios.post(`/api/eventos/addNetworkingContacts`, {
                    evento_id: dataApplication.evento_id,
                    customizacao_id: appState.customizacao_id,
                    visitante_id: dataApplication.visitante.visitante_id,
                    contato_id: id,
                }).then(function (response) {
                    if (response.status === 202) {
                        setQRcodeMessage(t('t63'))
                        setTimeout(() => {
                            scanning.current = false
                        }, 2500);
                    } else {
                        if (response.data.message !== undefined) {
                            setTimeout(() => {
                                scanning.current = false
                                setVisitanteData(response.data.message)
                            }, 2500);
                        } else {
                            if (response.status === 200) {
                                setQRcodeMessage(t('t64'))
                                setTimeout(() => {
                                    scanning.current = false
                                }, 2500);
                            } else {
                                setQRcodeMessage(t('t65'))
                                setTimeout(() => {
                                    scanning.current = false
                                }, 2500);
                            }
                        }
                    }
                }).catch(function () {
                    setQRcodeMessage(t('t65'))
                    setTimeout(() => {
                        scanning.current = false
                    }, 2500);
                })
            } else {
                setQRcodeMessage(t('t65'))
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            }
        } else {
            setQRcodeMessage(t('t65'))
            setTimeout(() => {
                scanning.current = false
            }, 2500);
        }
    }

    if (visitanteData.visitante_id > 0) {
        const dataElements = Object.keys(visitanteData.json).map((key) => (
            <>
                <p key={key} className="fw-bold mb-0">{visitanteData.json[key].label}</p>
                <p key={key} className="opacity-75">{visitanteData.json[key].value}</p>
            </>
        ));
        return (
            <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className="topNavCard">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => setVisitanteData({ 'visitante_id': 0 }))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="pageTitle primaryColor">{t('t88')}</div>
                </div>
                <div>
                    <img className="avatarProfile mb-2" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + visitanteData.img} alt="" />
                </div>
                <div className="vertically-centered">
                    <div className="mb-3">
                        {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                            if (visitanteData.json[field.inputID]) {
                                return (
                                    <h5 key={'contactsParticipantDetails' + index} className=" primaryColorT mt-2 mb-0 contactText1">
                                        {visitanteData.json[field.inputID].value}
                                    </h5>
                                );
                            }
                            return ""
                        })}
                    </div>
                    <div>
                        <div className="vertically-centered pe-4 frameProfileSeparator">
                            <p className="mb-1">{t('t57')}</p>
                            <h5 className="mb-0 primaryColorT">{visitanteData.total_contatos}</h5>
                        </div>
                        <div className="vertically-centered ps-4">
                            <p className="mb-1">{t('t62')}</p>
                            <h5 className="mb-0 primaryColorT">#{visitanteData.ranking}</h5>
                        </div>
                    </div>
                </div>
                <hr className="mb-0"></hr>
                <div className="profileCard">
                    {dataElements}
                </div>
            </motion.div>
        )
    } else {
        return (
            <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className="topNavCard">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => navigate(`/${location.search}`))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="pageTitle primaryColor">{t('t69')}</div>
                </div>

                <p className="baseColorText">{t('t70')}<br></br>{t('t71')}</p>
                <div className="d-flex justify-content-center mb-3">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn mx-2" onClick={(() => window.location.reload())}>
                        <FontAwesomeIcon icon={faRefresh} />
                    </div>
                    {videoPermission ? (
                        <div className="backIconSize iconSize primaryColor primaryColorText btn mx-2">
                            <FontAwesomeIcon icon={faVideo} />
                        </div>
                    ) : (
                        <div className="backIconSize iconSize primaryColor primaryColorText btn mx-2" onClick={(() => setUpdatePermission((prevCount) => prevCount + 1))}>
                            <FontAwesomeIcon icon={faVideoSlash} />
                        </div>
                    )}
                </div>
                {videoPermission ? (
                    <>
                        <div className="qrcode-frame primaryColor">
                            <QrReader
                                style={{ width: '100%' }}
                                className="qrcode-frame primaryColor"
                                delay={1500}
                                constraints={{
                                    facingMode: 'environment'
                                }}
                                onResult={(result) => {
                                    if (!scanning.current) {
                                        if (!!result && result !== undefined) {
                                            handleResult(result?.text)
                                        } else {
                                            setQRcodeMessage(t('t66'))
                                        }
                                    }
                                }}
                            />
                        </div>
                        < div className="qrcode-feedback primaryColor vertically-centered mb-0">
                            <p className="mb-0">{QRcodeMessage}</p>
                        </div>
                    </>
                ) : (
                    <div className="qrcode-frame primaryColor my-5">
                        <div className="qrcode-feedback primaryColor vertically-centered my-2">
                            <p className="mb-0">Houve um problema com a câmera, tente recarregar a página</p>
                        </div>
                    </div>
                )}
            </motion.div >
        )
    }
}

export default QRcode