import React, { useReducer, useEffect, useState, useContext } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios'
import HelmetStyle from '../components/HelmetStyle.js'
import { shuffleArray, selectQuestions, convertMilliseconds } from "../../../components/Functions"
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

let socket
const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      };
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'resetUserHistory':
      return {
        ...state,
        userHistory: {},
      }
    case 'setHistory':
      return {
        ...state,
        history: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setResult':
      return {
        ...state,
        finalResult: action.payload,
      }
    case 'prepareQuestions':
      let newOrder = [...state.questions]; // Create a shallow copy to avoid direct mutation
      if (state.selectQuestions && state.selectQuestions.status) {
        newOrder = selectQuestions(newOrder, state.selectQuestions.value);
      } else {
        if (state.shuffleQuestions) {
          newOrder = shuffleArray(newOrder);
        }
      }
      newOrder.forEach(elementA => {
        if (elementA.shuffleAnswers) {
          elementA.answers = shuffleArray(elementA.answers)
        }
      })
      return {
        ...state,
        questionsPrepared: newOrder,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication } = useGlobalState();
  const initialState = {
    ...dataApplication.data,
    questionsPrepared: {}, // Set act to 1 in the new initial state object
  };
  const [appState, dispatch] = useReducer(reducer, initialState);
  const [score, setScore] = useState(false)
  const [start, setStart] = useState(false)
  const [exerciseView, setExerciseView] = useState('answers')
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  const [dateUnix, setDateUnix] = useState(new Date())
  const [showAns, setShowAns] = useState(false)
  const [endType, setEndtype] = useState('end')
  const [isPlaying, setIsPlaying] = useState(true)
  const [resetGame, setResetGame] = useState(0)
  const initialScore = {
    'currentIndex': 0, 'value': 0, 'totalCorrect': 0, 'time': 0,
    'answers': [{ order: 0, time: 0, value: 0, question_id: 0, exercise: 0 }]
  }

  //Presenter states
  const [showPresentation, setShowPresentation] = useState(false)

  const handleNextStep = () => {
    setDateUnix(new Date())
    setLoadingQuestion(false)
    setShowAns(false)
    if (score.currentIndex + 1 < appState.questionsPrepared.length) {
      setScore(prevState => ({
        ...prevState,
        currentIndex: prevState.currentIndex + 1
      }));
      setStart(1)
    } else {
      setStart(5)
    }
  }

  const getHistory = async () => {
    try {
      const response = await axios.get(
        `/api/eventos/history/${dataApplication.evento_id}?customizacao_id=${appState.customizacao_id}`,
        { customizacao_id: appState.customizacao_id }
      );

      let history = response.data.message;
      history.forEach((historyItem) => {
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime =
          convertedTime['minutes'] +
          ':' +
          convertedTime['seconds'] +
          ':' +
          convertedTime['milliseconds'];
        historyItem.time = convertedTime;
        historyItem.score = historyItem.value
      });
      let data = sortRanking(history);
      dispatch({ type: 'setHistory', payload: data });

      return data; // Return the processed data
    } catch (error) {
      console.log(error);
      throw error; // Throw error to handle it outside
    }
  };

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + appState.aplicativo_id + '&customizacao_id=' + appState.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        console.log('login')
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.history, appState, initialScore)
        dispatch({ type: 'setHistory', payload: data.history });
        dispatch({ type: 'setResult', payload: data.result });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: 'prepareQuestions' });
    if (appState.userHistory && Object.keys(appState.userHistory).length > 0) {
      setScore({ 'currentIndex': appState.userHistory.currentIndex, 'value': appState.userHistory.value, 'totalCorrect': appState.userHistory.totalCorrect, 'time': appState.userHistory.time, 'answers': appState.userHistory.answers })
      let totalQuestions = appState.selectQuestions?.status ? appState.selectQuestions?.value : appState.questions.length
      if (appState.userHistory.answers.length >= totalQuestions) {
        // Game already done
        setStart(5)
      } else {
        // Continue game
        setStart(appState.noStartAnimation ? 1 : 0)
      }
    } else {
      setScore(initialScore)
      setStart(appState.noStartAnimation ? 1 : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (resetGame > 0) {
      dispatch({ type: 'resetUserHistory' });
      dispatch({ type: 'prepareQuestions' });
      setExerciseView('answers')
      setLoadingQuestion(false)
      setDateUnix(new Date())
      setShowAns(false)
      setScore({ 'currentIndex': 0, 'value': 0, 'totalCorrect': 0, 'time': 0, 'answers': [{ order: 0, time: 0, value: 0, question_id: 0, exercise: 0 }] })
      setStart(appState.noStartAnimation ? 1 : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetGame])

  return (
    <AppContext.Provider value={{
      appState, dispatch, score, setScore, start, setStart, showPresentation, setShowPresentation, showAns, setShowAns,
      dateUnix, setDateUnix, exerciseView, setExerciseView, endType, setEndtype, isPlaying, setIsPlaying, loadingQuestion,
      setLoadingQuestion, getHistory, setResetGame, initialScore, handleNextStep
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const sortRanking = (data) => {
  data.sort((a, b) => {
    const currentIndexA = a.currentIndex || 0;
    const currentIndexB = b.currentIndex || 0;

    const scoreA = a.value || 0;
    const scoreB = b.value || 0;

    const timeA = a.timeX || 0;
    const timeB = b.timeX || 0;

    if (currentIndexA !== currentIndexB) {
      return currentIndexB - currentIndexA;
    }
    if (scoreA !== scoreB) {
      return scoreB - scoreA;
    }
    if (timeA !== timeB) {
      return timeA - timeB;
    }
    return 0
  });
  return data;
};

const getData = () => {
  socket.emit('getData');
};

export {
  AppContext,
  GameProvider,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, history) {
  participants.forEach(participant => {
    history.forEach(historyItem => {
      if (participant.visitante_id === historyItem.visitante_id) {
        historyItem.participantDetails = JSON.parse(participant.json);
        Object.assign(historyItem, JSON.parse(historyItem.json));
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
        historyItem.time = convertedTime
        historyItem.score = historyItem.value
      }
    });
  });
  let sortedParticipants = sortRanking(history)
  return { 'history': sortedParticipants };
}