

import React, { useState } from 'react'
import { useAppState } from '../context/AppContext.js'
import { TextWithLineBreaks, convertMilliseconds } from "../../../components/Functions.js"
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

const End = () => {
    const { handleExit } = useGlobalState();
    const { appState, setStart, setResetGame, score: currentScore, } = useAppState();
    // eslint-disable-next-line no-unused-vars
    const [score, setScore] = useState(currentScore)

    const convertTime = (time) => {
        let convertedTime = convertMilliseconds(time);
        return convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
    }

    return (
        <>
            {appState.endText && appState.endText.showImage && appState.endText.showImage.status && <div className="logoBox">
                <img className="resultImage" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.endText.showImage.value} alt=""></img>
            </div>}
            <div className={appState.endText && appState.endText.showImage && appState.endText.showImage.status ? 'mt-4' : ''}>
                {appState.endText.title.status && <div className="col">
                    <h5 className="mb-3"><TextWithLineBreaks text={appState.endText.title.value}></TextWithLineBreaks></h5>
                </div>}
                {appState.endText.description.status && <div className="col">
                    <p className="mb-3"><TextWithLineBreaks text={appState.endText.description.value}></TextWithLineBreaks></p>
                </div>}
                {appState.showScore &&
                    <>
                        <div className="horizontally-centered mb-3">
                            <div className='endQuizzScoreIcon h3'>
                                {/* <FontAwesomeIcon icon={faTrophy} size={'lg'} /> */}
                                {score.player1.value + score.player2.value}
                                <br></br>
                                <p className="mb-0">pontos</p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <p className="mb-0">Tempo total: {convertTime(score.time)}</p>
                            {appState.memoryGame.allowTwoPlayers.status && <p className="mb-0">Jogador 1: <span className="opacity-75 pe-5">{score.player1.value} pontos</span>Jogador 2: <span className="opacity-75">{score.player2.value} pontos</span></p>}
                        </div>
                    </>
                }
            </div>
            <div>
                {appState.showRanking && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setStart(3)} >Ver ranking</button>}
                {appState.retry.status && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => setResetGame((prevState) => prevState + 1)}>{appState.retry.text}</button>}
                {appState.reset?.status && <button className="primaryColor primaryColorText btn mt-3 mx-2" onClick={() => handleExit()}>{appState.reset?.text ? appState.reset.text : 'Finalizar'}</button>}
            </div>
        </>
    )
}

export default End