import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useGlobalState } from './hooks/useCustomization';
import HelmetStyle from './components/HelmetStyle.js';
import { domainConfig } from "./assets/config.js"
import LogoFlex from "./assets/img/logoFlex.svg"
import { Routes } from "./Routes"
import Token from './components/Token'

export const App = () => {
  const { dataApplication, styleVersion } = useGlobalState();
  const [loader, setLoader] = useState(false)

  return (
    <>
      {dataApplication && <HelmetStyle key={styleVersion} />}
      <AnimatePresence initial={true} mode="wait">
        {!loader ? (
          <Initialization key={'Initialization'} setLoader={setLoader}></Initialization>
        ) : (
          <Routes key={'Routes'} />
        )}
      </AnimatePresence>
    </>
  )
}

const Initialization = ({ setLoader }) => {
  const { dataApplication, token, setToken, setMiniLoader, error, setError, fontLoaded, setFontLoaded, showTokenScreen, setShowTokenScreen } = useGlobalState();
  const [loaderLogo, setLoaderLogo] = useState(false)
  const [loaderBackground, setLoaderBackground] = useState(false)
  const [loaderFooter, setLoaderFooter] = useState(false)
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imageLogo = new Image()
  const imageBackground = new Image()
  const imageFooter = new Image()
  const video = document.createElement('video');

  video.onloadedmetadata = () => {
    setLoaderBackground(true)
  };

  imageLogo.onload = function () {
    setLoaderLogo(true)
  }

  imageLogo.onerror = function () {
    setTimeout(() => {
      setMiniLoader(false)
      setError('Evento com recursos inválidos')
    }, 1500);
  };

  imageBackground.onload = function () {
    setLoaderBackground(true)
  }

  imageBackground.onerror = function () {
    setTimeout(() => {
      setMiniLoader(false)
      setError('Evento com recursos inválidos')
    }, 1500);
  };

  imageFooter.onload = function () {
    setLoaderFooter(true)
  }

  imageFooter.onerror = function () {
    setTimeout(() => {
      setError('Evento com recursos inválidos')
    }, 1500);
  };

  const preloadImages = (urls, id) => {
    let loadedImagesCount = 0;
    const totalImages = urls.length;

    if (totalImages === 0) {
      setImagesLoaded(true);
      return;
    }

    urls.forEach((url) => {
      const img = new Image();
      img.src = domainConfig.imageServer + '/customizacao/' + id + '/' + url;
      img.onload = () => {
        loadedImagesCount += 1;
        if (loadedImagesCount === totalImages) {
          setImagesLoaded(true);
        }
      };
      img.onerror = () => {
        setError('Evento com recursos inválidos')
        setImagesLoaded(false);
        return;  // Stop further execution
      };
    });
  };

  useEffect(() => {
    // useEffect that handles the page loading animation
    let time = 1500
    if (dataApplication.customizacao && dataApplication.customizacao.removeLogoFlex) {
      time = 0
    }
    if (dataApplication && loaderLogo && loaderFooter && loaderBackground && !error && fontLoaded && token && imagesLoaded) {
      setTimeout(() => {
        setLoader(true);
      }, time);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderBackground, loaderLogo, loaderFooter, error, fontLoaded, token, showTokenScreen, imagesLoaded])

  useEffect(() => {
    // Pre-load background if exists
    if (dataApplication) {
      // Pre-load background if exists
      if (dataApplication.customizacao.background.type === 'image' || dataApplication.customizacao.background.type === 'video') {
        if (dataApplication.customizacao.background.type === 'video') {
          video.src = domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.background.url;
        } else {
          imageBackground.src = domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.background.url;
        }
      } else {
        setLoaderBackground(true)
      }
      // Pre-load logo if exists
      if (dataApplication.customizacao.logo !== '') {
        imageLogo.src = domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo;
      } else {
        setLoaderLogo(true)
      }
      // Pre-load footer if exists
      if (dataApplication.customizacao.layout.type === 1 && dataApplication.customizacao.layout.footer.type === 'image') {
        imageFooter.src = domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.layout.footer.url;
      } else {
        setLoaderFooter(true)
      }
      // Pre-load font if exists
      if (dataApplication.customizacao.fontFamily) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?family=${dataApplication.customizacao.fontFamily.replace(/ /g, '+')}&display=swap`;
        document.head.appendChild(link);
        setFontLoaded(true);
      }
      // Pre-load apps images
      if (dataApplication.apps.length > 0) {
        dataApplication.apps.forEach(element => {
          switch (element.aplicativo_id) {
            case 1:
              let cardUrls = element.memoryGame.cards.value.map(card => card.url);
              let frontUrl = element.memoryGame.cards.front;
              let allUrls1 = [...cardUrls, frontUrl];
              preloadImages(allUrls1, element.customizacao_id);
              break;
            case 2:
              if (element.questions && element.questions.length > 0) {
                let extractUrls = (questions) => {
                  let urls = [];
                  questions.forEach((question) => {
                    // Collect the question's main image URL if it exists
                    if (question.url && question.type !== 3) {
                      urls.push(question.url);
                    }
                    // Collect all answer image URLs
                    question.answers.forEach((answer) => {
                      if (answer.url) {
                        urls.push(answer.url);
                      }
                    });
                  });
                  return urls;
                };
                const allUrls2 = extractUrls(element.questions);
                preloadImages(allUrls2, element.customizacao_id);
              } else {
                setImagesLoaded(true);
              }
              break;
            case 6:
              let allUrls = [element.showResult.winnerImage, element.showResult.loserImage, element.showResult.waitingImage];
              if(element.showResult.drawImage){
                allUrls.push(element.showResult.drawImage)
              }
              preloadImages(allUrls, element.customizacao_id);
              break;
            default:
              setImagesLoaded(true);
              break;
          }
        });
      } else {
        setImagesLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApplication.customizacao])

  if (showTokenScreen || error) {
    return (
      <div className="loaderContainer">
        <motion.div
          className="App-start"
          style={{ backgroundColor: "#4ba700" }}
          key={'TokenScreen'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0 }}>
          <Token setShowTokenScreen={setShowTokenScreen} setLoader={setLoader} setToken={setToken} error={error}></Token>
        </motion.div>
      </div>
    )
  } else {
    return (
      <div className="loaderContainer">
        {dataApplication.customizacao && <motion.div
          className="App-start"
          style={dataApplication.customizacao && !dataApplication.customizacao.removeLogoFlex ? ({ backgroundColor: "#4ba700" }) : ({ backgroundColor: "#fff" })}
          key={'loadingScreen'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0 }}>
          {dataApplication.customizacao && !dataApplication.customizacao.removeLogoFlex ? (<img src={LogoFlex} alt="Logo" className="logo"></img>) : ("")}
        </motion.div>}
      </div>
    )
  }
}