import React, { useReducer, useContext, useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"
import { convertDate } from "../../../components/Functions"

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'setMessages':
      return {
        ...state,
        messages: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication, setLoading } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateMessages, setUpdateMessages] = useState(0)
  const [showMessages, setShowMessages] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const filtersRef = useRef('approved');

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        setUpdateMessages((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.messages, appState)
        data.messages = filterMessages(data.messages);
        setLoading(false)
        dispatch({ type: 'setMessages', payload: data.messages });
        dispatch({ type: 'setParticipants', payload: data.participants });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showMessages, setShowMessages, showPresentation,
      filtersRef, updateMessages, setUpdateMessages, setShowPresentation
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = () => {
  socket.emit('getData');
};

const updateData = () => {
  socket.emit('updateData');
};

export {
  AppContext,
  GameProvider,
  updateData,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, history) {
  const wordCountMap = new Map();
  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};
    participant.data_visitacao = convertDate(participant.data_visitacao)
    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      if (detail.value === false) {
        detail.value = 'Não assinalado'
      } else if (detail.value === true) {
        detail.value = 'Assinalado'
      }
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);
    participant.history = []

    history.forEach(historyItem => {
      if (participant.visitante_id === historyItem.visitante_id) {
        if (historyItem.json) {
          historyItem.data = JSON.parse(historyItem.json);
          participant.data = historyItem.data
        }
        historyItem.participantDetails = JSON.parse(participant.json);
        participant.history.push(historyItem);
        const words = JSON.parse(historyItem.message)
        historyItem.message = words.join(", ");
        if (historyItem.aprovado > 0) {
          words.forEach(word => {
            const lowercaseWord = word.toLowerCase(); // Convert words to lowercase to ensure case-insensitivity
            const count = wordCountMap.get(lowercaseWord) || 0;
            wordCountMap.set(lowercaseWord, count + 1);
          });
        }
      }
    });

    const wordCountArray = Array.from(wordCountMap, ([text, value]) => ({ text, value }));
    // Sort the words by frequency (descending order)
    wordCountArray.sort((a, b) => b.value - a.value);
    history.wordCloud = wordCountArray
  });

  return { 'participants': participants, 'messages': history };
}

function filterMessages(data) {
  // Sort the data in one go
  data.sort((a, b) => {
    if (a.data < b.data) return -1;
    if (a.data > b.data) return 1;
    return 0;
  });

  // Initialize arrays for each category
  const approvedMessages = [];
  const waitingMessages = [];
  const notApproved = [];

  // Single loop to categorize messages
  data.forEach(item => {
    if (item.aprovado === 1) {
      approvedMessages.push(item);
    } else if (item.aprovado === 0) {
      waitingMessages.push(item);
    } else if (item.aprovado === 2) {
      notApproved.push(item);
    }
  });

  // Return the categorized messages
  return {
    all: data,
    approved: approvedMessages,
    waiting: waitingMessages,
    notApproved: notApproved
  };
}