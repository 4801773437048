import React, { useState } from 'react';
import { domainConfig } from "../../../assets/config.js"
import { useAppState } from '../context/AppContext.js'
import { TextWithLineBreaks } from "../../../components/Functions.js"

const Result = () => {
    const { appState, handleNextStep, score } = useAppState();
    const [result] = useState(appState.result && appState.result.length > 0 ? getQuizResult(score.answers, appState) : false);

    return (
        <div className='col-md-12 px-3'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            {result.title && result.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={result.title.value}></TextWithLineBreaks></h5></div>}
            {result.url && <div className="logoBox">
                <img className="resultImage mb-4" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + result.url} alt=""></img>
            </div>}
            {result.description && result.description.status && <div className="col mb-3">
                <p><TextWithLineBreaks text={result.description.value}></TextWithLineBreaks></p>
            </div>}
            <div className="text-center">
                <button className="primaryColor primaryColorText btn mt-3" onClick={() => handleNextStep()}>Continuar</button>
            </div>
        </div>
    )
}

export const getQuizResult = (answers, appState) => {
    let result = []
    answers.forEach(element => {
        appState.questions.forEach(ans => {
            if (element.question_id === ans.question_id) {
                ans.answers.forEach(value => {
                    if (element.order === value.order) {
                        if (!result[value.resultId]) {
                            result[value.resultId] = 0
                        }
                    }
                });
            }
        });
    });

    const resultId = result.reduce((maxIndex, currentValue, currentIndex, array) => {
        if (currentValue !== null && (maxIndex === -1 || currentValue > array[maxIndex])) {
            return currentIndex;
        }
        return maxIndex;
    }, -1);

    const finalResult = appState.result.find(r => r.resultId === resultId);
    return finalResult
}

export default Result