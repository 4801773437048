import React from 'react'
import { Dropdown, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faDisplay,
    faVolumeHigh,
    faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import Fullscreen from '../../../components/FullScreen.js'
import { domainConfig } from "../../../assets/config.js"
import { RouletteModal } from '../Presenter.js'

export const NavbarPresenter = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, showPresentation, isPlaying, setIsPlaying, setShowPresentation } = useAppState();

    const togglePlay = () => {
        setIsPlaying(prevState => !prevState)
    }

    return (
        <>
            <div className="horizontally-centered">
                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo d-inline-flex"></img>
            </div>
            <div></div>
            <div className="horizontally-centered">
                {appState.apresentation.configs.sound && <button className="btn me-2 primaryColor primaryColorText" onClick={() => togglePlay()}>
                    {isPlaying === true ? (
                        <FontAwesomeIcon icon={faVolumeHigh} />) : (<FontAwesomeIcon icon={faVolumeXmark} />)}
                </button>}
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Modo de apresentação</Tooltip>}
                >

                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowPresentation(true)}>
                        <FontAwesomeIcon icon={faDisplay} className="primaryColorText" />
                    </button>
                </OverlayTrigger>
                <Dropdown className="d-inline" align="end">
                    <Dropdown.Toggle className="btn primaryColor primaryColorText" variant="" id="dropdown-autoclose-true">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Fullscreen />
                        <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal show={showPresentation} fullscreen={true}>
                <div className="modalPresenter d-flex justify-content-center overflow-hidden text-side">
                    <RouletteModal></RouletteModal>
                </div>
            </Modal>
        </>
    )
}