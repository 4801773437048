import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "t1": "Select the language",
      "t2": "Portuguese",
      "t3": "English",
      "t4": "Spanish",
      "t5": "Make new connections",
      "t6": "during the event",
      "t7": "Point your camera at the other participant's",
      "t8": "QR CODE to add to your contact list",
      "t9": "Skip",
      "t10": "Check the ranking of",
      "t11": "participants with more connections",
      "t12": "Participants with the most connections",
      "t13": "will be highlighted at the top of the list",
      "t14": "Let's get Started",
      "t15": "Welcome!",
      "t16": "Privacy Policy",
      "t17": "Need an account",
      "t18": "Create Account",
      "t19": "Email",
      "t20": "Password",
      "t21": "Forgot the Password?",
      "t22": "Login",
      "t23": 'Something went wrong! Please Try Again',
      "t24": "Profile picture is required",
      "t25": "Invalid image format",
      "t26": 'Edit Profile',
      "t27": 'Create Profile',
      "t28": "Full Name",
      "t29": "Nickname",
      "t30": "Password",
      "t31": "Confirm your Password",
      "t32": "Number",
      "t33": "Company Area",
      "t34": "Company",
      "t35": "City",
      "t36": "State",
      "t37": "Country",
      "t38": "Tell us a little about yourself",
      "t39": "Tell us a little about your hobbies",
      "t40": "Save",
      "t41": "Discard",
      "t42": "Save changes?",
      "t43": "Required field",
      "t44": "At least",
      "t45": "Maximum of",
      "t46": "characters",
      "t47": "Passwords doesn't match",
      "t48": "Invalid Email",
      "t49": "Email already registered",
      "t50": "Invalid email for this event",
      "t51": "Invalid telephone number",
      "t52": "Successful connection",
      "t53": "About",
      "t54": "Company area",
      "t55": "Back",
      "t56": "No connections found",
      "t57": "Contacts",
      "t58": "Connections",
      "t59": "Unknown",
      "t60": "Missing",
      "t61": "Add",
      "t62": "Ranking",
      "t63": "You already have this connection",
      "t64": 'User not registered',
      "t65": 'Invalid Code',
      "t66": 'Code not found',
      "t67": 'Something went wrong, please try again',
      "t68": 'Check camera permission',
      "t69": 'QR Code Reader',
      "t70": 'Enable the front camera and center the QR Code',
      "t71": 'on the screen, a new window will appear',
      "t72": 'Email not found',
      "t73": 'Incorrect password',
      "t74": "To add new contacts use the QR reader pointing to the other participant's QR Code",
      "t75": 'Check infos and more about the connections you made during the event',
      "t76": 'Check the ranking of people with more connections made during the event',
      "t77": 'Edit your profile information and picture',
      "t78": 'Edit',
      "t80": 'This is',
      "t81": 'Our',
      "t82": 'Moment',
      "t83": 'Send',
      "t84": "Your password has been sent to your email (Check spam)",
      "t85": "Developer mode",
      "t86": "My QR Code",
      "t87": "Function disabled in developer mode",
      "t88": "Contact information",
      "t89": "My position",
      "t90": "Last contact",
      "t91": "Download",
      "t92": "Reading QR Code"
    },
  },
  pt: {
    translation: {
      "t1": "Selecione um idioma",
      "t2": "Português",
      "t3": "Inglês",
      "t4": "Espanhol",
      "t5": "Aproveite o evento",
      "t6": "para gerar novas conexões",
      "t7": "Aponte sua câmera para o QR CODE",
      "t8": "do outro participante para adicionar a sua lista de contatos",
      "t9": "Pular",
      "t10": "Veja o ranking de pessoas",
      "t11": "com mais conexões!",
      "t12": "Os participantes com mais",
      "t13": "conexões ficarão destacados no topo da lista",
      "t14": "Começar",
      "t15": "Bem vindo!",
      "t16": "Privacidade",
      "t17": "Não tem uma conta",
      "t18": "Criar conta",
      "t19": "Email",
      "t20": "Senha",
      "t21": "Esqueceu a senha?",
      "t22": "Login",
      "t23": 'Aconteceu algum problema, tente novamente',
      "t24": "É preciso adicionar uma foto de perfil",
      "t25": "Formato de imagem inválido",
      "t26": 'Editar perfil',
      "t27": 'Criar perfil',
      "t28": "Nome completo",
      "t29": "Apelido",
      "t30": "Senha",
      "t31": "Confirme sua senha",
      "t32": "Telefone",
      "t33": "Área de atuação",
      "t34": "Empresa",
      "t35": "Cidade",
      "t36": "Estado",
      "t37": "País",
      "t38": "Fale um pouco sobre você",
      "t39": "Principais Hobbies",
      "t40": "Salvar",
      "t41": "Descartar",
      "t42": "Salvar mudanças?",
      "t43": "Campo obrigatório",
      "t44": "Mínimo de",
      "t45": "Máximo de",
      "t46": "caracteres",
      "t47": "As senhas não correspondem",
      "t48": "Email inválido",
      "t49": "Email já cadastrado",
      "t50": "Email não registrado para esse evento",
      "t51": "Telefone inválido",
      "t52": "Conexão realizada",
      "t53": "Sobre",
      "t54": "Área de atuação",
      "t55": "Voltar",
      "t56": "Nenhum contato encontrado",
      "t57": "Contatos",
      "t58": "Conexões",
      "t59": "Faltam",
      "t60": "Não coletados",
      "t61": "Adicionar",
      "t62": "Ranking",
      "t63": "Você já adicionou esse contato",
      "t64": 'Usuário não cadastrado',
      "t65": 'Código inválido',
      "t66": 'Nenhum código encontrado',
      "t67": 'Houve algum problema, tente novamente',
      "t68": 'Habilite sua câmera',
      "t69": 'Leitor de QR Code',
      "t70": 'Habilite a câmera frontal e centralize o QR Code',
      "t71": 'na tela abaixo, uma nova janela irá aparecer',
      "t72": 'Email não encontrado',
      "t73": 'Senha incorreta',
      "t74": 'Para adicionar novos contatos utilize o leitor QR apontando para o código do outro participante',
      "t75": 'Veja as informações dos seus contatos adquiridos no evento',
      "t76": 'Veja a posição das pessoas no ranking de maior networking durante o evento',
      "t77": 'Edite suas informações de contato e foto de perfil',
      "t78": 'Editar',
      "t80": 'Este',
      "t81": 'momento',
      "t82": 'É nosso',
      "t83": 'Enviar',
      "t84": "Sua senha foi enviada para seu email (Verifique o spam)",
      "t85": "Modo desenvolvedor",
      "t86": "Meu QR Code",
      "t87": "Função desabilitada no modo desenvolvedor",
      "t88": "Informações do contato",
      "t89": "Minha posição",
      "t90": "Último contato",
      "t91": "Baixar",
      "t92": "Lendo QR Code"
    },
  },
  es: {
    translation: {
      "t1": "Selecciona el idioma",
      "t2": "Portugués",
      "t3": "Inglés",
      "t4": "Español",
      "t5": "Aprovecha el evento para crear",
      "t6": "Nuevas conexiones",
      "t7": "¡Apunte su cámara al QR CODE",
      "t8": "del otro participante para agregarlo a su lista de contactos",
      "t9": "saltar",
      "t10": "¡Verifica el ranking de las",
      "t11": "personas con más conexiones!",
      "t12": "Los participantes con más",
      "t13": "conexiones recibirán premios",
      "t14": "Comenzar",
      "t15": "¡Bienvenido!",
      "t16": "Privacidad",
      "t17": "¿No tiene una cuenta",
      "t18": "Crear cuenta",
      "t19": "Email",
      "t20": "Contraseña",
      "t21": "¿Olvidaste la contraseña?",
      "t22": "Login",
      "t23": 'Hubo un problema, por favor intenta nuevamente',
      "t24": "Necesario upload de una foto",
      "t25": "Formato de imagen invalido",
      "t26": 'Editar perfil',
      "t27": 'Crear perfil',
      "t28": "Nombre Completo",
      "t29": "Apodo",
      "t30": "Contraseña",
      "t31": "Confirma la Contraseña",
      "t32": "Teléfono",
      "t33": "Departamento en el cual trabaja",
      "t34": "Empresa",
      "t35": "Ciudad",
      "t36": "Estado",
      "t37": "País",
      "t38": "Háblame un poco sobre ti",
      "t39": "Háblame un poco sobre tus Hobbies",
      "t40": "Guardar",
      "t41": "Descartar",
      "t42": "¿Guardar Cambios?",
      "t43": "Información obligatória",
      "t44": "Mínimo de",
      "t45": "Máximo de",
      "t46": "caracteres",
      "t47": "Verifica las Contraseñas",
      "t48": "Email inválido",
      "t49": "Email ya registrado",
      "t50": "Email no registrado para este evento",
      "t51": "Telefono inválido",
      "t52": "Conexion realizada",
      "t53": "Sobre",
      "t54": "Departamento",
      "t55": "Volver",
      "t56": "Conexion no encontrada",
      "t57": "Contactos",
      "t58": "Conexiones",
      "t59": "Desconocidos",
      "t60": "Não coletados",
      "t61": "Nueva Conexión",
      "t62": "Ranking",
      "t63": "Ya has agregado ese contacto",
      "t64": 'Usuario no registrado',
      "t65": 'Código inválido',
      "t66": 'No se encontró ningún código',
      "t67": 'Hubo un problema, por favor intenta nuevamente',
      "t68": 'Active su cámara',
      "t69": 'Lector QR Code',
      "t70": 'Habilite la cámara frontal y centre el QR Code',
      "t71": 'en la pantalla, aparecerá una nueva ventana',
      "t72": 'Email no registrado',
      "t73": 'Contraseña incorrecta',
      "t74": 'Para agregar nuevos contactos utilice el lector de QR apuntando al QR Code del otro participante',
      "t75": 'Revisa la información de las conexiones que hiciste durante el evento',
      "t76": 'Revisa la posición de las personas en el ranking de mayor networking durante el evento',
      "t77": 'Edita tu información de contacto y foto de perfil',
      "t78": 'Editar',
      "t80": 'Este es',
      "t81": 'nuestro',
      "t82": 'momento',
      "t83": 'Mandar',
      "t84": "Su contraseña ha sido enviada a su correo electrónico (Verificar spam)",
      "t85": "Modo desarrollador",
      "t86": "Mi Código QR",
      "t87": "Función deshabilitada en modo desarrollador",
      "t88": "Información del contacto",
      "t89": "Mi posición",
      "t90": "Último contacto",
      "t91": "descargar",
      "t92": "Leyendo QR Code"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
});
