import React, { useState } from "react"
import { Dropdown } from 'react-bootstrap'
const FullScreen = () => {
    const [isFullscreen, setFullscreen] = useState(false)

    const requestFullscreen = () => {
        if (document.body.requestFullscreen) {
            document.body.requestFullscreen()
        } else if (document.body.mozRequestFullScreen) {
            document.body.mozRequestFullScreen()
        } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen()
        } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen()
        }
        setFullscreen(true)
    }

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        }
        setFullscreen(false)
    }

    return (
        <Dropdown.Item onClick={isFullscreen ? exitFullscreen : requestFullscreen}>
            Tela cheia
        </Dropdown.Item>
    )
}

export default FullScreen 