import React from 'react'
import BaseLayout from "../../components/BaseLayout"
import { useGlobalState } from '../../hooks/useCustomization.js';

const RouletteDraw = () => {
    const { handleExit } = useGlobalState();

    return (
        <BaseLayout showBackButton={true} backButtonHandler={() => handleExit()} >
            <div className="col">
                <h5 className="mb-3">Oops!</h5>
            </div>
            <div className="col">
                <p>Esse aplicativo não possui um modo usuário</p>
            </div>
        </BaseLayout>
    )
}

export default RouletteDraw