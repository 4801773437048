import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext.js'
import { PageLoader } from '../../../components/Elements.js'
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import NotyfContext from "../../../contexts/NotyfContext";
import photoFrame2 from "../assets/img/Frame2.png"
import photoFrame from "../assets/img/photo-frame.png"
import { domainConfig } from "../../../assets/config.js"

export const MoreInfo = ({ data, setData }) => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const { t } = useTranslation();

    const dataElements = Object.keys(data.json).map((key, index) => (
        <>
            <p key={key} className="fw-bold mb-0">{data.json[key].label}</p>
            <p key={key} className="opacity-75">{data.json[key].value}</p>
        </>
    ));

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }} exit={{ opacity: 0 }}>
            <div className="topNavCard">
                <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => setData(false))}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="pageTitle primaryColor">{t('t88')}</div>
            </div>
            <div className="col-12">
                <img className="avatarProfile mb-2" src={data.img ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + data.img : photoFrame} alt="" />
                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                    if (data.json[field.inputID]) {
                        return (
                            <h5 key={'contactsParticipantDetails' + index} className="mt-2 mb-0 contactText1">
                                {data.json[field.inputID].value}
                            </h5>
                        );
                    }
                    return ""
                })}
            </div>
            <hr className="mb-0"></hr>
            <div className="profileCard">
                {dataElements}
            </div>
        </motion.div>
    )
}

const Contacts = ({ addUser }) => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const [contacts, setContacts] = useState(false)
    const [data, setData] = useState()
    const location = useLocation();
    const [QRcodeMessage, setQRcodeMessage] = useState(false);
    const notyf = useContext(NotyfContext);
    const { t } = useTranslation();
    let keyID = data ? data.visitante_id : 'keyID'

    function handleResult(id) {
        axios.post(`/api/eventos/addNetworkingContacts`, {
            evento_id: dataApplication.evento_id,
            customizacao_id: appState.customizacao_id,
            visitante_id: dataApplication.visitante.visitante_id,
            contato_id: id,
        }).then(function (response) {
            if (response.status === 202) {
                setQRcodeMessage(t('t63'))
            } else {
                if (response.data.message !== undefined) {
                    setData(response.data.message)
                    setQRcodeMessage(false)
                } else {
                    if (response.status === 200) {
                        setQRcodeMessage(t('t64'))
                    } else {
                        setQRcodeMessage(t('t65'))
                    }
                }
            }
        }).catch(function (error) {
            setQRcodeMessage(t('t65'))
        })
    }

    useEffect(() => {
        if (addUser) {
            const url = `${location.pathname}${location.search}${location.hash}`
            let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            let segments = url.split('/');
            let lastSegment = segments[segments.length - 1];
            // eslint-disable-next-line no-unused-vars
            let [result, queryString] = lastSegment.split('?');
            let decoded = decodeURIComponent(result);
            if (!base64regex.test(decoded)) {
                setTimeout(() => {
                    setQRcodeMessage(t('t65'))
                    return
                }, 2500);
            }
            decoded = window.atob(decoded)
            let id = decoded.split("=")
            id = id[1]
            // eslint-disable-next-line eqeqeq
            if (id > 0 && id != dataApplication.visitante.visitante_id) {
                handleResult(id)
            } else {
                setQRcodeMessage(t('t65'))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (QRcodeMessage) {
            notyf.open({
                type: "danger",
                message: QRcodeMessage,
                ripple: true,
                dismissible: true,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRcodeMessage])

    useEffect(() => {
        axios.get(`/api/eventos/networkingContacts/${dataApplication.visitante.visitante_id}?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&fieldsToShow=${JSON.stringify(dataApplication.customizacao.form.fieldsToShow)}`)
            .then(function (response) {
                setTimeout(() => {
                    setContacts(response.data.message)
                }, 250);
            })
            .catch(function (error) {
                console.log(error)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!contacts || (addUser && !data && !QRcodeMessage)) {
        return (
            <motion.div className="pageLoaderContainer" key={'pageLoader'} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width="75" />
            </motion.div>
        )
    }

    return (
        <motion.div className="default-box justify-content-start" initial={{ opacity: 0 }} key={'contactContainer'}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="box-full">
                <AnimatePresence mode="wait">
                    <ContactHandler key={'contactHandler' + keyID} contacts={contacts} data={data} setData={setData} addUser={addUser}></ContactHandler>
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

const ContactHandler = ({ contacts, data, setData, addUser }) => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const [listContacts, setListContacts] = useState(true)
    const [listNotCollected, setListNotCollected] = useState(false)
    const { t } = useTranslation();


    function handleFilter(toggle) {
        if (toggle === 'collected') {
            if (listContacts && !listNotCollected) {
                return
            }
            setListContacts((prevValue => !prevValue))
        } else {
            if (listNotCollected && !listContacts) {
                return
            }
            setListNotCollected((prevValue => !prevValue))
        }
    }

    if (data) {
        const dataElements = Object.keys(data.json).map((key, index) => (
            <>
                <p key={key} className="fw-bold mb-0">{data.json[key].label}</p>
                <p key={key} className="opacity-75">{data.json[key].value}</p>
            </>
        ));

        return (
            <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className="topNavCard">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => addUser ? navigate(`/contatos${location.search}`) : setData(false))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="pageTitle primaryColor">{t('t88')}</div>
                </div>
                <div>
                    <img className="avatarProfile mb-2" src={data.img ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + data.img : photoFrame} alt="" />
                </div>
                <div className="vertically-centered">
                    <div className="mb-3">
                        {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                            if (data.json[field.inputID]) {
                                return (
                                    <h5 key={'contactsParticipantDetails' + index} className=" primaryColorT mt-2 mb-0 contactText1">
                                        {data.json[field.inputID].value}
                                    </h5>
                                );
                            }
                            return ""
                        })}
                    </div>
                    <div>
                        <div className="vertically-centered pe-4 frameProfileSeparator">
                            <p className="mb-1">{t('t57')}</p>
                            <h5 className="mb-0 primaryColorT">{dataApplication.visitante.total_contatos}</h5>
                        </div>
                        <div className="vertically-centered ps-4">
                            <p className="mb-1">{t('t62')}</p>
                            <h5 className="mb-0 primaryColorT">#{dataApplication.visitante.ranking}</h5>
                        </div>
                    </div>
                </div>
                <hr className="mb-0"></hr>
                <div className="profileCard">
                    {dataElements}
                </div>
            </motion.div>
        )
    } else {
        return (
            <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className="topNavCard">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => navigate(`/${location.search}`))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="pageTitle primaryColor">{t('t57')}</div>
                </div>
                <div className="vertically-centered contactCard">
                    <div>
                        <div className="vertically-centered pe-4 frameProfileSeparator">
                            <p className="mb-1">{t('t58')}</p>
                            <h4 className="mb-0 primaryColorT">{contacts && contacts.conexoes.length}</h4>
                        </div>
                        <div className="vertically-centered ps-4">
                            <p className="mb-1">{t('t59')}</p>
                            <h4 className="mb-0 primaryColorT">{contacts && contacts.faltantes.length}</h4>
                        </div>
                    </div>
                </div>
                <div className="d-flex my-3 mx-0">
                    <button type="button" className={listContacts ? ('btn primaryColor mx-1 flex-1') : ('btn primaryColor-outline mx-1  flex-1')} onClick={(() => handleFilter('collected'))}>{t('t57')}</button>
                    <button type="button" className={listNotCollected ? ('btn primaryColor mx-1 flex-1') : ('btn primaryColor-outline mx-2 flex-1')} onClick={(() => handleFilter('notCollected'))}>{t('t60')}</button>
                    <button type="button" className="backIconSize iconSize primaryColor primaryColorText btn mx-1" onClick={(() => navigate(`/qrcode${location.search}`))}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="row mx-0 justify-content-between">
                    {(contacts.conexoes.length > 0 && listContacts) || (contacts.faltantes.length > 0 && listNotCollected) ? (
                        <>
                            {listContacts && contacts && contacts.conexoes.map((props, index) => {
                                index++
                                if (listContacts) {
                                    return (
                                        <div className="col mb-3 text-center" key={'contacts1' + index}>
                                            <div className="d-inline-block">
                                                <div className="avatarDivProfile pointer" onClick={(() => setData(props))} >
                                                    <div className="frameX"></div>
                                                    <img className="avatarProfileContato01" src={props.img ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + props.img : photoFrame} alt="" />
                                                </div>
                                                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (props.json[field.inputID]) {
                                                        return (
                                                            <p key={'contactsParticipantDetails' + index} className="mt-2 mb-0 contactText1">
                                                                {props.json[field.inputID].value}
                                                            </p>
                                                        );
                                                    }
                                                    return ""
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col mb-3 text-center" key={'contacts1' + index}>
                                            <div className="d-inline-block">
                                                <div className="avatarDivProfile pointer">
                                                    <img className="avatarProfileContato01" src={props.img ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + props.img : photoFrame} alt="" />
                                                </div>
                                                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (props.json[field.inputID]) {
                                                        return (
                                                            <p key={'contactsParticipantDetails' + index} className="mt-2 mb-0 contactText1">
                                                                {props.json[field.inputID].value}
                                                            </p>
                                                        );
                                                    }
                                                    return ""
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            }
                            )}
                            {listNotCollected && contacts.faltantes.map((props, index) => {
                                index++
                                return (
                                    <div className="col mb-3" key={'contacts2' + index}>
                                        <div className="d-inline-block text-center">
                                            <div className="avatarDivProfile opacity-50">
                                                <div className="frameX"></div>
                                                <img className="avatarProfileContato01" src={photoFrame2} alt="" />
                                            </div>
                                            {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                if (props.json[field.inputID]) {
                                                    return (
                                                        <p key={'contactsParticipantDetails' + index} className="mt-2 mb-0 contactText1">
                                                            {props.json[field.inputID].value}
                                                        </p>
                                                    );
                                                }
                                                return ""
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </>
                    ) : (
                        <>
                            <div className="ranking-group pt-4">
                                <p>Nenhum contato encontrado</p>
                            </div>
                        </>
                    )}
                </div>
            </motion.div>
        )
    }
}

export default Contacts