import { Helmet } from 'react-helmet-async';
import { domainConfig } from "../../../assets/config.js";

const HelmetStyle = ({ appState }) => {

    return (
        <Helmet>
            <style>
                {`
                       html {
                            --textSideColor: ${appState.apresentation.textSide?.textColor};
                        }
                        .modalPresenter .image-side {
                            flex: ${appState.apresentation.imageSide?.value};
                        }
                        .modalPresenter{
                            flex-direction: ${appState.apresentation.imageSide?.position === 'start' ? 'row' : 'row-reverse'}
                        }
                        .modalPresenter .text-side{
                            flex: ${appState.apresentation.textSide?.value};
                            background-color: ${appState.apresentation.textSide?.backgroundColor};
                        } 
                        .modalPresenter .form-control,
                         .modalPresenter label,
                         .modalPresenter p,
                         .modalPresenter .p,
                         .modalPresenter .btn {
                            font-size: ${appState.apresentation.textSide?.p}rem!important;
                        } 
                        .modalPresenter h5,
                        .modalPresenter .h5 {
                            font-size: ${appState.apresentation.textSide?.h5}rem!important;
                        } 
                        .modalPresenter .messagesSize{
                            font-family:${appState.apresentation.textSide.fontFamily}!important;
                            font-size: ${appState.apresentation.textSide?.messageSlider}rem!important;
                        }
                        `}
            </style>
            {appState.apresentation.showQRCode ? (
                <style>
                   {`
                        .modalQRCodeShare {
                            top: ${appState.apresentation.showQRCode.position.y === 'top' ? ("10px") : ("")};
                            left: ${appState.apresentation.showQRCode.position.x === 'left' ? ("10px") : ("")};
                            bottom: ${appState.apresentation.showQRCode.position.y === 'bottom' ? ("10px") : ("")};
                            right: ${appState.apresentation.showQRCode.position.x === 'right' ? ("10px") : ("")};
                        }
                        `}
                </style>
            ) : ("")}
            {appState.apresentation.imageSide.background && appState.apresentation.imageSide.background !== "" ? (
                <style>
                    {`
                        .modalPresenter .image-side {
                            background-image: url("${domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.imageSide.background}")
                        }
                        `}
                </style>
            ) : ("")}
        </Helmet>
    )
};

export default HelmetStyle;