import { Helmet } from 'react-helmet-async';

const HelmetStyle = ({ appState }) => {

    return (
        <Helmet>
            <style>
                {`      
                        html {
                            --textSideColor: ${appState.apresentation.textSide?.textColor};
                        }
                        .modalPresenter.text-side{
                            background-color: ${appState.apresentation.textSide?.backgroundColor};
                        } 
                         .modalPresenter .form-control,
                         .modalPresenter label,
                         .modalPresenter p,
                         .modalPresenter .p,
                         .modalPresenter .btn {
                            font-size: ${appState.apresentation.textSide?.p}rem!important;
                        } 
                        .modalPresenter h5,
                        .modalPresenter .h5 {
                            font-size: ${appState.apresentation.textSide?.h5}rem!important;
                        }   
                        .modalPresenter .App-logo{
                            max-width: ${appState.apresentation.configs.logoSize}px!important;
                        }
                        .modalPresenter .loadingContainer .App-logo {
                            max-width: ${appState.apresentation.configs.logoSize}px!important;
                        }
                        .modalPresenter .cameraButtonContainerEnd {
                            bottom: ${appState.apresentation.configs.buttonPositionEnd}%;
                        }
                        `}
            </style>
        </Helmet>
    )
};

export default HelmetStyle;