import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import { useGlobalState } from '../../../hooks/useCustomization';
import { TextWithLineBreaks } from "../../../components/Functions.js"
import { PageLoader } from "../../../components/Elements"
import { AnimatePresence, motion } from 'framer-motion';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const backgroundColors = ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 165, 0, 1)', 'rgba(255, 0, 255, 1)', 'rgba(181, 255, 41,1)', 'rgba(196, 0, 0,1)', 'rgba(182, 167, 196,1)']

const Chart = () => {
  const { dataApplication } = useGlobalState();
  const { appState, score: currentScore, handleNextStep, getHistory } = useAppState();
  // eslint-disable-next-line no-unused-vars
  const [score, setScore] = useState(currentScore);
  const [chartTag, setChartTag] = useState(true);
  const [chartData, setChartData] = useState(false);
  let totalQuestions = appState.selectQuestions?.status ? appState.selectQuestions?.value : appState.questions.length

  useEffect(() => {
    // Function to fetch and wait for history data
    const fetchData = async () => {
      try {
        const data = await getHistory(); // Wait for getHistory to complete
        setChartData(data); // Use the data to set chartData
      } catch (error) {
        console.error('Failed to fetch history:', error);
      }
    };

    fetchData(); // Call the fetchData function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence mode={'wait'}>
      {chartData ? (
        <>
          {score.answers.length >= totalQuestions ? (
            <motion.div className="row">
              {appState.questions.map((props, index) => (
                <ToggleChart props={props} index={index} chartData={chartData}></ToggleChart>
              )
              )}
              <div className="mt-3">
                <button className="btn primaryColor primaryColorText" onClick={() => handleNextStep()}>Continuar</button>
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }} className='col-md-12 px-3' key={'chart'}>
              <h5 className="questionTitle">{score.currentIndex + 1}. <TextWithLineBreaks text={appState.questionsPrepared[score.currentIndex].question}></TextWithLineBreaks></h5>
              <span className="badge primaryColor primaryColorText my-2">{score.currentIndex + 1} de {appState.questionsPrepared.length}</span>
              {chartTag ?
                <BarChart questions={appState.questionsPrepared} chartTag={chartTag} setChartTag={setChartTag} currentQuestion={score.currentIndex} chartData={chartData}></BarChart>
                :
                <PieChart questions={appState.questionsPrepared} chartTag={chartTag} setChartTag={setChartTag} currentQuestion={score.currentIndex} chartData={chartData}></PieChart>
              }
              <div className="mt-3">
                <button className="btn primaryColor primaryColorText" onClick={() => handleNextStep()}>Continuar</button>
              </div>
            </motion.div>
          )}
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} className='default-box' key={'loaderDiv'}>
          <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const ToggleChart = ({ props, index, chartData }) => {
  const { appState } = useAppState();
  const [chartTag, setChartTag] = useState(true)

  return (
    <div className="col-md-12 p-3">
      <h5 className={`questionTitle`}>{index + 1}. <TextWithLineBreaks text={props.question}></TextWithLineBreaks></h5>
      {/* <div><span className="badge primaryColor primaryColorText mb-0">({exerciseType(appState.questions[index].exercise)})</span></div> */}
      {chartTag ?
        <BarChart key={'BarChart' + index} questions={appState.questions} chartTag={chartTag} setChartTag={setChartTag} currentQuestion={index} chartData={chartData}></BarChart>
        :
        <PieChart key={'PieChart' + index} questions={appState.questions} chartTag={chartTag} setChartTag={setChartTag} currentQuestion={index} chartData={chartData}></PieChart>
      }
    </div>
  )
}

const PieChart = ({ questions, chartTag, setChartTag, currentQuestion, chartData }) => {
  const { dataApplication } = useGlobalState();
  let labels = []
  let score = []
  let values = []
  let totalAnswer = 0
  let index = 0
  questions[currentQuestion].answers.forEach(element => {
    var a = [];
    a.push(element.text.slice(0, 50));
    var i = 1;
    while (element.text.length > (i * 50)) {
      a.push(element.text.slice(i * 50, (i + 1) * 50));
      i++;
    }
    if (element.text.length > 50) {
      a[0] = a[0] + '...'
    }
    values[index] = 0
    labels.push(a)
    Object.keys(chartData).forEach(key => {
      chartData[key].answers && chartData[key].answers.forEach(elementA => {
        if (elementA !== null && elementA.question_id === questions[currentQuestion].question_id) {
          if (elementA.order === element.order) {
            totalAnswer++
            values[index] = values[index] + 1
          }
        }
      });
    });
    index++
  });
  let labelIndex = 0
  labels.forEach(element => {
    element[0] = element[0] + ' (' + values[labelIndex] + ')'
    labelIndex++
  });
  score = [{
    data: values,
    borderWidth: 1,
    backgroundColor: backgroundColors,
    borderColor: [
      dataApplication.customizacao.primaryColorText,
    ],
  }]

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint) => { return (xDatapoint[0].label) },
          label: () => { return "" },
        },
      },
      legend: {
        display: true,
        textAlign: 'left',
        position: 'bottom',
        backgroundColor: "#000",
        labels: {
          color: dataApplication.customizacao.baseColorText,
          backgroundColor: "#000",
          position: 'bottom'
        }
      },
      dataLabel: {
        display: true
      },
      datalabels: {
        formatter: (val, context) => {
          if (val > 0) {
            const number = `${parseFloat((Number(val) * 100) / totalAnswer)}`
            return Math.round(number) + `%`;
          } else {
            return ""
          }
        },
        //formatter: (val, context) => `${val}%`,
        color: "black",
        font: {
          size: '14rem',
          weight: "bold"
        },
      },
      title: {
        display: false
      },
    },
  };
  const data = {
    labels: labels,
    datasets: score,
  }

  return (
    <div className="chartCard mb-3" key={'pieSize'}>
      <div>
        <button className="btn primaryColor primaryColorText me-3" onClick={(() => setChartTag((prevState) => !prevState))}>{!chartTag ? <FontAwesomeIcon icon={faChartBar} /> : <FontAwesomeIcon icon={faChartPie} />}</button>
        <span>{questions[currentQuestion].exercise === 2 ? "Total de votos: " : "Total de respostas: "}<span className="opacity-75">{totalAnswer}</span></span>
      </div>
      <div>
        <Pie className="pieSize" options={options} data={data} />
      </div>
    </div>
  )
}

const BarChart = ({ questions, chartTag, setChartTag, currentQuestion, chartData }) => {
  const { dataApplication } = useGlobalState();
  let labels = []
  let score = []
  let data = {};
  let options = {};
  let index = 0
  let totalAnswer = 0
  
  questions[currentQuestion].answers.forEach(element => {
    var a = [];
    a.push(element.text.slice(0, 50));
    var i = 1;
    while (element.text.length > (i * 50)) {
      a.push(element.text.slice(i * 50, (i + 1) * 50));
      i++;
    }
    if (element.text.length > 50) {
      a[0] = a[0] + '...'
    }
    labels.push(a)
    let data = []
    data[index] = 0
    Object.keys(chartData).forEach(key => {
      chartData[key].answers && chartData[key].answers.forEach(elementA => {
        if (elementA !== null && elementA.question_id === questions[currentQuestion].question_id) {
          if (elementA.order === element.order) {
            totalAnswer++
            data[index]++
          }
        }
      });
    });
    score[index] = {
      label: a[0] + ' (' + data[index] + ')',
      data: data,
      borderWidth: 1,
      backgroundColor: backgroundColors[index],
      borderColor: [
        dataApplication.customizacao.primaryColorText,
      ],
    }
    index++
  })
  options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        color: dataApplication.customizacao.baseColorText,
        ticks: {
          display: false,
          color: dataApplication.customizacao.baseColorText,
        }
      },
      y: {
        ticks: {
          color: dataApplication.customizacao.baseColorText,
          font: {
            size: 16,
          },
          stepSize: 1,
          beginAtZero: true,
          offset: false,
        }
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint) => { return (xDatapoint[0].label + ' (' + xDatapoint[0].raw + ')') },
          label: () => { return "" },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        backgroundColor: "#000",
        labels: {
          color: dataApplication.customizacao.baseColorText,
          backgroundColor: "#000",
          position: 'top'
        }
      },
      datalabels: {
        formatter: (val, context) => {
          if (val > 0) {
            const number = `${parseFloat((Number(val) * 100) / totalAnswer)}`
            return Math.round(number) + `%`;
          } else {
            return ""
          }
        },
        //formatter: (val, context) => `${val}%`,
        color: "black",
        font: {
          size: '14rem',
          weight: "bold"
        },
      },
    },
  };
  data = {
    labels: labels,
    datasets: score,
  }
  return (
    <div className="chartCard" key={'BarSize'}>
      <div>
        <button className="btn primaryColor primaryColorText me-3" onClick={(() => setChartTag(prevState => !prevState))}>{!chartTag ? <FontAwesomeIcon icon={faChartBar} /> : <FontAwesomeIcon icon={faChartPie} />}</button>
        <span>{questions[currentQuestion].exercise === 2 ? "Total de votos: " : "Total de respostas: "}<span className="opacity-75">{totalAnswer}</span></span>
      </div>
      <div>
        <Bar className="pieSize" options={options} data={data} />
      </div>
    </div>
  )
}

export default Chart