import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion"
import Login from './pages/Login'
import Home from './pages/Home'
import Contacts from './pages/Contacts';
import Ranking from './pages/Ranking';
import QRcode from './pages/QRcode';
import Edit from './pages/Edit'
import AuthGuard from '../../components/AuthGuard.js';
import './assets/style.css'

const Networking = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode={'wait'}>
            <Routes location={location} key={location.pathname}>
                <Route path="/*" element={<AuthGuard><Home /></AuthGuard>} />
                <Route path="/contatos" element={<AuthGuard><Contacts addUser={false} /></AuthGuard>} />
                <Route path="/contatos/adicionar/:id" element={<AuthGuard><Contacts addUser={true} /></AuthGuard>} />
                <Route path="/login" element={<Login />} />
                <Route path="/ranking" element={<AuthGuard><Ranking /></AuthGuard>} />
                <Route path="/perfil" element={<AuthGuard><Edit /></AuthGuard>} />
                <Route path="/qrcode" element={<AuthGuard><QRcode /></AuthGuard>} />
            </Routes>
        </AnimatePresence>
    )
}

export default Networking