import React from 'react';
import { domainConfig } from "../assets/config"

const AudioEmbed = ({ audioId, customizacao_id }) => {
  const embedUrl = domainConfig.imageServer + "/customizacao/" + customizacao_id + "/" + audioId;

  return (
    <audio controls>
      <source src={embedUrl} type="audio/mpeg" /></audio>
  );
};

export default AudioEmbed;