import React, { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCamera } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"
import NotyfContext from "../../../contexts/NotyfContext";
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext'
import axios from 'axios'
import { FormComponent } from "../../../Register"
import { domainConfig } from "../../../assets/config.js"
import { onSelectFile } from "./Login"

const Edit = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)
    const notyf = useContext(NotyfContext);
    const { t, i18n } = useTranslation();
    const json = localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id) ? JSON.parse(localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id)) : { 'language': 'pt', 'tutorial': true }
    const [language, setLanguage] = useState(json.language ? json.language : 'pt')
    const formikRef = useRef(null);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    function registerUser(values, actions) {
        let axiosJson = {
            evento_id: dataApplication.evento_id,
            customizacao_id: appState.customizacao_id,
            visitante_id: dataApplication.visitante.visitante_id,
            json: {},
        }
        if (values.autorization) {
            delete values.autorization
        }
        Object.entries(values).forEach(entry => {
            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
            if (field) {
                axiosJson.json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            }
        })

        axiosJson.json = JSON.stringify(axiosJson.json)
        const formData = new FormData();
        formData.append("file", selectedFile);
        if (selectedFile) {
            axios
                .post(`/api/upload/customizacao/${appState.customizacao_id}`, formData)
                .then((responseX) => {
                    if (responseX.data.success === true) {
                        axiosJson.img = responseX.data.message
                        axios.put(`/api/eventos/registerData`, axiosJson).then(async function (response) {
                            navigate(`/${location.search}`)
                        }).catch(function (error) {
                            actions.setSubmitting(false)
                            notyf.open({
                                type: "danger",
                                message: t('t23'),
                                ripple: true,
                                dismissible: true,
                            });
                        })
                    } else {
                        actions.setSubmitting(false)
                        notyf.open({
                            type: "danger",
                            message: t('t23'),
                            ripple: true,
                            dismissible: true,
                        });
                    }
                })
                .catch((error) => {
                    actions.setSubmitting(false)
                    notyf.open({
                        type: "danger",
                        message: t('t23'),
                        ripple: true,
                        dismissible: true,
                    });
                });
        } else {
            axios.put(`/api/eventos/registerData`, axiosJson).then(async function (response) {
                navigate(`/${location.search}`)
            }).catch(function (error) {
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: t('t23'),
                    ripple: true,
                    dismissible: true,
                });
            })
        }
    }

    function handleLanguage(lg) {
        i18n.changeLanguage(lg)
        setLanguage(lg)
        localStorage.setItem(dataApplication.token + '&customization_id=' + appState.customizacao_id, JSON.stringify({ 'tutorial': false, 'language': lg }));
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    return (
        <motion.div className="default-box justify-content-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}>
            <div className="box-full"><div className="topNavCard">
                <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="pageTitle primaryColor">{t('t26')}</div>
                <div>
                    <Dropdown className="d-inline" align={"start"}>
                        <Dropdown.Toggle className="backIconSize primaryColor primaryColorText btn w-100" variant="" id="dropdown-autoclose-true">
                            {language}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className={language === 'pt' ? 'active' : ''} onClick={() => handleLanguage('pt')}>{t('t2')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleLanguage('en')}>{t('t3')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleLanguage('es')}>{t('t4')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
                <div className="col-md-12 text-center mb-3">
                    <label
                        className="position-relative pointer"
                        htmlFor="my-file"
                    >
                        <img className="avatarProfileRegister" src={!preview ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + dataApplication.visitante.img : preview} alt="" />
                        <div className="cameraIcon">
                            <FontAwesomeIcon icon={faCamera} />
                        </div>
                    </label>
                    <input
                        className="d-none"
                        type="file"
                        id="my-file"
                        onChange={(e) => {
                            onSelectFile(e, setSelectedFile, notyf, t)
                        }}
                    />
                </div>
                <FormComponent formikRef={formikRef} registerUser={registerUser} editValues={true} customizacao_id={appState.customizacao_id} t={t}></FormComponent>
                <div className="text-center mt-3">
                    <button className="btn primaryColor primaryColorText" type="submit" onClick={submitFormExternally} >
                        {dataApplication.customizacao.form.buttonText}
                    </button>
                </div>
            </div>
        </motion.div>
    )
}

export default Edit