

import React, { useState, useEffect, useContext, useRef } from 'react'
import { Formik } from "formik"
import * as Yup from "yup";
import { Tabs, Tab, Modal, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AnimatePresence, motion } from "framer-motion"
import NotyfContext from "../../contexts/NotyfContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatInTimeZone } from "date-fns-tz";
import {
    faArrowLeft,
    faRefresh,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, updateContexto, checkQRCode } from "./context/AppContext.js"
import { PageLoader } from '../../components/Elements.js'
import { TextWithLineBreaks, convertDate, ColumnSortingTable } from "../../components/Functions.js"
import { QrReader } from "react-qr-reader";
import {
    MediaPermissionsErrorType,
    requestMediaPermissions
} from 'mic-check';
import winnerSound from "../../assets/sounds/30311764_the-winner_by_gamechestaudio_preview.mp3"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const PrizeDrawAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 10000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Sorteios`}>
                                    <Sorteio></Sorteio>
                                </Tab>
                                <Tab eventKey="k2" title={`Resultados (${appState.results.length})`}>
                                    <Resultados></Resultados>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 775px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Painel = ({ prizeDrawType }) => {

    switch (prizeDrawType) {
        case 'DrawDefault':
            return (
                <DrawDefault></DrawDefault>
            )
        case 'DrawName':
            return (
                <DrawName></DrawName>
            )
        case 'DrawNumber':
            return (
                <DrawNumber></DrawNumber>
            )
        default:
            return ("")
    }
}

const Sorteio = () => {
    const { dataApplication } = useGlobalState();
    const { appState, prizeDrawType, setPrizeDrawType } = useAppState();
    const [contextData, setContextData] = useState(appState.contexto)

    useEffect(() => {
        setContextData(appState.contexto)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.contexto.prizeDrawState])

    function resetDraw() {
        const modifiedPayload = {
            ...appState.contexto,
            prizeDrawState: {},
            data: {},
            draw: {}
        };
        updateContexto(modifiedPayload);
    }

    return (
        <AnimatePresence mode="wait" initial={false}>
            {(() => {
                switch (contextData.prizeDrawState) {
                    default:
                    case 'waiting':
                        return (
                            <motion.div key={'prizeDrawState01'} initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.25 } }}
                                exit={{ opacity: 0 }}>
                                <Row className="text-start mx-3">
                                    <h6 className="text-center">Novo sorteio</h6>
                                    <div className="col-md-8 offset-md-2" md={8}>
                                        <Form.Group as={Col} sm={12}>
                                            <Form.Check
                                                className="checkboxFontSize"
                                                checked={prizeDrawType === 'DrawName' ? true : false}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setPrizeDrawType('DrawName')
                                                }}
                                                label={`Lista de nomes`}>
                                            </Form.Check>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12}>
                                            <Form.Check
                                                className="checkboxFontSize"
                                                checked={prizeDrawType === 'DrawNumber' ? true : false}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setPrizeDrawType('DrawNumber')
                                                }}
                                                label={`Lista de números`}>
                                            </Form.Check>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12}>
                                            <Form.Check
                                                className="checkboxFontSize"
                                                checked={prizeDrawType === 'DrawDefault' ? true : false}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setPrizeDrawType('DrawDefault')
                                                }}
                                                label={`Apenas participantes registrados`}>
                                            </Form.Check>
                                        </Form.Group>
                                    </div>
                                </Row>
                                <Painel key={prizeDrawType} prizeDrawType={prizeDrawType}></Painel>
                            </motion.div>
                        )
                    case 'sorting':
                        return (
                            <motion.div key={'prizeDrawState02'} className="vertically-centered py-5" initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.25 } }}
                                exit={{ opacity: 0 }}>
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                                <h5 className="opacity-75 mt-3">Sorteando...</h5>
                            </motion.div>
                        )
                    case 'show':
                        return (
                            <motion.div key={'prizeDrawState03'} className="prizePresenterContainer" initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.25 } }}
                                exit={{ opacity: 0 }}>
                                <h6 className="mb-3">Resultado do último sorteio<OverlayTrigger overlay={<Tooltip>Novo sorteio</Tooltip>}>
                                    <button className="btn btn-sm ms-3 primaryColor primaryColorText" onClick={() => resetDraw()}><FontAwesomeIcon className="vertically-centered cursor-pointer primaryColorT" size={"lg"} icon={faRefresh} /></button>
                                </OverlayTrigger></h6>
                                <div className="col-md-8 mb-5">
                                    <div className="card-body text-start">
                                        <p className="mb-1">Título: <span className="opacity-75">{contextData.data?.title}</span></p>
                                        <p className="mb-1">Data: <span className="opacity-75">{convertDate(contextData.data?.data)}</span></p>
                                        <p className="mb-0">Premiação: <span className="opacity-75">{contextData.data?.prize !== '' ? contextData.data?.prize : ''}</span></p>
                                        <div className="prizePresenterContainer mt-5">
                                            <AnimatePresence mode="wait">
                                                <ShowWinners contextData={contextData}></ShowWinners>
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        )
                }
            })()}
        </AnimatePresence>
    )
}

const Resultados = () => {
    const { appState } = useAppState();
    const [fields, setFields] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)

    const openParticipantHistory = (value) => {
        setShowModal(true);
        setModalData(value)
    }

    const returnDrawType = (value) => {
        switch (value) {
            case `DrawDefault`:
                return (
                    "Apenas participantes"
                );
            case `DrawName`:
                return (
                    "Sorteio por nomes"
                );
            case `DrawNumber`:
                return (
                    "Sorteio por números"
                );

            default:
                return ("");
        }
    }

    useEffect(() => {
        let fieldsX = []
        fieldsX.push({
            Header: 'Tipo de sorteio',
            accessor: 'type',
            Cell: (cell) => {
                return returnDrawType(cell.row.original.type)
            },
        })
        fieldsX.push({
            Header: 'Título',
            accessor: 'title',
        })
        fieldsX.push({
            Header: 'Data',
            accessor: 'data',
            Cell: (cell) => {
                const dateX = new Date();
                const now = formatInTimeZone(dateX, "America/Sao_Paulo", "dd/MM/yyyy HH:mm");
                return (now);
            },
        })
        fieldsX.push({
            Header: 'Premiação',
            accessor: 'prize',
        })
        fieldsX.push({
            Header: '',
            accessor: 'sorteados',
            disableSortBy: true,
            Filter: false,
            Cell: (cell) => {
                return (
                    <OverlayTrigger overlay={<Tooltip>Histórico</Tooltip>}>
                        <div onClick={() => openParticipantHistory(cell.row.original)} style={{ width: '50px' }}>
                            <FontAwesomeIcon icon={faUser} size={'lg'} className="me-2 cursor-pointer primaryColorT" />{cell.row.original.result.length}
                        </div>
                    </OverlayTrigger>
                );
            },
        })
        setFields(fieldsX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (fields) {
        return (
            <div>
                <div className="tablePresenter">
                    <ColumnSortingTable
                        columns={fields}
                        data={appState.results}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "data" || cellinfo.column.id === "sorteados" ? "center" : "left",
                            },
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </div>
                <Modal
                    show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onHide={(() => setShowModal(false))}>
                        <Modal.Title>Histórico</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p className="mb-0 modalDataFields">
                                Tipo de sorteio: <span className="opacity-75">{returnDrawType(modalData.type)}</span>
                            </p>
                            <p className="mb-0 modalDataFields">
                                Título: <span className="opacity-75">{modalData.title}</span>
                            </p>
                            <p className="mb-0 modalDataFields">
                                Data: <span className="opacity-75">{modalData.data}</span>
                            </p>
                            <p className="mb-0 modalDataFields">
                                Premiação: <span className="opacity-75">{modalData.prize}</span>
                            </p>
                        </div>
                        <div className="text-center">
                            <h6>Sorteado(s)</h6>
                        </div>
                        <ResultHistoryModal modalData={modalData} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}

const DrawDefault = () => {
    const { appState, registerData } = useAppState();

    function removeWinnersHandler() {
        const modifiedPayload = {
            ...appState.contexto,
            draw: {
                ...appState.contexto.draw,
                removeWinners: !appState.contexto.draw.removeWinners,
            }
        };
        updateContexto(modifiedPayload);
    }

    return (
        <motion.div className="row" initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.25 } }}
            exit={{ opacity: 0 }}>
            <Formik
                validationSchema={Yup.object().shape({
                    title: Yup.string()
                        .required("Campo obrigatório")
                        .max(100, `Máximo de 100 caracteres`),
                    total_draw_times: Yup.number()
                        .required("Campo obrigatório")
                        .positive('Número maior que 0'),
                    prize: Yup.string()
                        .max(100, `Máximo de 100 caracteres`),

                })}
                onSubmit={registerData}
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={{
                    type: 'DrawDefault',
                    title: '',
                    total_draw_times: 1,
                    removeWinners: appState.contexto.draw && appState.contexto.draw.removeWinners ? appState.contexto.draw.removeWinners : '',
                    prize: "",
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="text-start">
                        <Row className="mx-0">
                            <div className="col-md-8 col-12 offset-md-2 mt-3">
                                <Row className="mx-0">
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'title'}
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                            isValid={touched.title && !errors.title}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Quantos participantes você deseja sortear?<span className="ms-2 badge primaryColor primaryColorText">{appState.participantsValidate.length}</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={'total_draw_times'}
                                            value={values.total_draw_times}
                                            onChange={handleChange}
                                            isInvalid={!!errors.total_draw_times}
                                            isValid={touched.total_draw_times && !errors.total_draw_times}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.total_draw_times}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Descrever prêmio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'prize'}
                                            value={values.prize}
                                            onChange={handleChange}
                                            isInvalid={!!errors.prize}
                                            isValid={touched.prize && !errors.prize}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.prize}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Check
                                            className="checkboxFontSize"
                                            checked={values.removeWinners ? true : false}
                                            type="checkbox"
                                            name={"removeWinners"}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'removeWinners',
                                                    values.removeWinners ? false : true
                                                );
                                                removeWinnersHandler();
                                            }}
                                            label={`Remover participantes já sorteados`}
                                            isInvalid={!!errors.removeWinners}
                                            feedback={errors.removeWinners}
                                            feedbackType="invalid">
                                        </Form.Check>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Row>
                        <div className="text-center">
                            <button className="btn primaryColor primaryColorText mx-0 mt-3" type="submit" disabled={isSubmitting}>Sortear</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </motion.div>
    )
}

const DrawName = () => {
    const { registerData } = useAppState();

    return (
        <motion.div className="row" initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.25 } }}
            exit={{ opacity: 0 }}>
            <Formik
                validationSchema={Yup.object().shape({
                    title: Yup.string()
                        .required("Campo obrigatório")
                        .max(100, `Máximo de 100 caracteres`),
                    total_draw_times: Yup.number()
                        .required("Campo obrigatório")
                        .positive('Número maior que 0')
                        .test('number', 'Total de sorteados não pode ser menor que o disponível', function (valueX) {
                            let names = []
                            if (this.parent.names) {
                                names = this.parent.names.split(/\r?\n/);
                            }
                            if (valueX > names.length) {
                                return false
                            }
                            return true
                        }),
                    names: Yup.string()
                        .required("Campo obrigatório"),
                    prize: Yup.string()
                        .max(100, `Máximo de 100 caracteres`),

                })}
                onSubmit={registerData}
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={{
                    type: 'DrawName',
                    title: '',
                    total_draw_times: 1,
                    names: '',
                    prize: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="text-start">
                        <Row className="mx-0 mt-3">
                            <div className="col-md-8 col-12 offset-md-2">
                                <Row className="mx-0">
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'title'}
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                            isValid={touched.title && !errors.title}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Quantos nomes você deseja sortear?</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={'total_draw_times'}
                                            value={values.total_draw_times}
                                            onChange={handleChange}
                                            isInvalid={!!errors.total_draw_times}
                                            isValid={touched.total_draw_times && !errors.total_draw_times}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.total_draw_times}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Insira os nomes abaixos separados por linha</Form.Label>
                                        <Form.Control
                                            style={{ height: "100px" }}
                                            type="text"
                                            as="textarea"
                                            name={'names'}
                                            value={values.names}
                                            onChange={handleChange}
                                            isInvalid={!!errors.names}
                                            isValid={touched.names && !errors.names}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.names}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Descrever prêmio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'prize'}
                                            value={values.prize}
                                            onChange={handleChange}
                                            isInvalid={!!errors.prize}
                                            isValid={touched.prize && !errors.prize}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.prize}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Row>
                        <div className="text-center">
                            <button className="btn primaryColor primaryColorText mx-0 mt-3" type="submit" disabled={isSubmitting}>Sortear</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </motion.div>
    )
}

const DrawNumber = () => {
    const { registerData } = useAppState();

    return (
        <motion.div className="row" initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.25 } }}
            exit={{ opacity: 0 }}>
            <Formik
                validationSchema={Yup.object().shape({
                    title: Yup.string()
                        .required("Campo obrigatório")
                        .max(100, `Máximo de 100 caracteres`),
                    total_draw_times: Yup.number()
                        .required("Campo obrigatório")
                        .positive('Número maior que 0')
                        .test('number', 'Total de sorteados não pode ser maior que a faixa de números', function (valueX) {
                            let rangeSize = this.parent.max_number - this.parent.min_number + 1;
                            if (valueX > rangeSize) {
                                return false
                            }
                            return true
                        }),
                    min_number: Yup.number()
                        .required("Campo obrigatório")
                        .positive('Número maior que 0'),
                    max_number: Yup.number()
                        .required("Campo obrigatório")
                        .positive('Número maior que 0'),
                    prize: Yup.string()
                        .max(100, `Máximo de 100 caracteres`),

                })}
                onSubmit={registerData}
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={{
                    type: 'DrawNumber',
                    title: '',
                    total_draw_times: 1,
                    min_number: 1,
                    max_number: 100,
                    prize: "",
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className="text-start">
                        <Row className="mx-0 mt-3">
                            <div className="col-md-8 col-12 offset-md-2">
                                <Row className="mx-0">
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'title'}
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                            isValid={touched.title && !errors.title}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Quantos números você deseja sortear?</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={'total_draw_times'}
                                            value={values.total_draw_times}
                                            onChange={handleChange}
                                            isInvalid={!!errors.total_draw_times}
                                            isValid={touched.total_draw_times && !errors.total_draw_times}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.total_draw_times}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12}>
                                        <Form.Label>A faixa de números será entre:</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} className='mb-3'>
                                        <Form.Control
                                            type="number"
                                            name={'min_number'}
                                            value={values.min_number}
                                            onChange={handleChange}
                                            isInvalid={!!errors.min_number}
                                            isValid={touched.min_number && !errors.min_number}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.min_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} className='mb-3'>
                                        <Form.Control
                                            type="number"
                                            name={'max_number'}
                                            value={values.max_number}
                                            onChange={handleChange}
                                            isInvalid={!!errors.max_number}
                                            isValid={touched.max_number && !errors.max_number}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.max_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Descrever prêmio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'prize'}
                                            value={values.prize}
                                            onChange={handleChange}
                                            isInvalid={!!errors.prize}
                                            isValid={touched.prize && !errors.prize}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.prize}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        </Row>
                        <div className="text-center">
                            <button className="btn primaryColor primaryColorText mx-0 mt-3" type="submit" disabled={isSubmitting}>Sortear</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </motion.div>
    )
}

export const PrizeDrawQRCodeModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowQRCode, checkQRCodeResult } = useAppState();
    const [videoPermission, setVideoPermission] = useState(false);
    const [QRcodeMessage, setQRcodeMessage] = useState('Nenhum código encontrado');
    const notyf = useContext(NotyfContext);
    const scanning = useRef(false)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowQRCode(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (checkQRCodeResult) {
            if (checkQRCodeResult.visitante_id) {
                setQRcodeMessage('Participação validada!')
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            } else {
                setQRcodeMessage('Código inválido')
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            }
        }
    }, [checkQRCodeResult])

    function handleResult(result) {
        if (scanning.current === false) {
            setQRcodeMessage('Lendo código QR...')
            scanning.current = true
            try {
                var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
                if (!base64regex.test(result)) {
                    setQRcodeMessage('Código inválido')
                    setTimeout(() => {
                        scanning.current = false
                        return
                    }, 2500);
                }
                let decoded = window.atob(result);
                let id = decoded.replace("flex-id", "");
                if (id > 0) {
                    checkQRCode(id)
                } else {
                    setQRcodeMessage('Código inválido')
                    setTimeout(() => {
                        scanning.current = false
                    }, 2500);
                }
            } catch (error) {
                setQRcodeMessage('Código inválido')
                setTimeout(() => {
                    scanning.current = false
                }, 2500);
            }
        } else {
            setQRcodeMessage('Código inválido')
            setTimeout(() => {
                scanning.current = false
            }, 2500);
        }

    }

    useEffect(() => {
        requestMediaPermissions({ audio: false, video: true })
            .then(function () {
                setVideoPermission(true);
            })
            .catch(function (err) {
                const { type } = err;
                if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: "Habilite sua câmera",
                        ripple: true,
                        dismissible: true,
                    });
                    // browser does not have permission to access camera or microphone
                } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                    // user didn't allow app to access camera or microphone
                    setVideoPermission(false)
                    requestMediaPermissions({ audio: false, video: true })
                        .then(function () {
                            setVideoPermission(true);
                        })
                        .catch(function (err) {
                            notyf.open({
                                type: "danger",
                                message: "Habilite sua câmera",
                                ripple: true,
                                dismissible: true,
                            });
                        });
                } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                    setVideoPermission(true)
                    return
                } else {
                    setVideoPermission(false)
                    notyf.open({
                        type: "danger",
                        message: 'Houve algum problema, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                    // not all error types are handled by this library
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && <div className="modalQRCodeShare">
                <div>
                    {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                    <QRCode className="bg-white p-3 qrcodeContainer" value={appState.apresentation.showQRCode.link !== '' ? appState.apresentation.showQRCode.link : domainConfig.aplicativos + '/?token=' + dataApplication.token} />
                </div>
            </div>}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                    {appState.apresentation.title.status && <h5 className="my-5"><TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks></h5>}
                </div>
            </div>
            <div className="qrReaderContainer">
                <AnimatePresence mode="wait">
                    {videoPermission ? (
                        <motion.div className="qrReaderBox" key={'qrCodeFrame'} initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}>
                            <QrReader
                                className="qrcode-frame"
                                delay={1500}
                                constraints={{
                                    facingMode: 'environment'
                                }}
                                onResult={(result, error) => {
                                    if (!scanning.current) {
                                        if (!!result && result !== undefined) {
                                            handleResult(result?.text)
                                        } else {
                                            setQRcodeMessage('Nenhum código encontrado')
                                        }
                                    }
                                }}
                            />
                            <div className="qrcode-feedback vertically-centered mb-0">
                                <p className="mb-0">{QRcodeMessage}</p>
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div key={'loaderIcon'} initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}>
                            <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    )
}

export const PrizeDrawModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowPresentation } = useAppState();
    const [contextData, setContextData] = useState(appState.contexto)

    useEffect(() => {
        setContextData(appState.contexto)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.contexto.prizeDrawState])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && <div className="modalQRCodeShare">
                <div>
                    {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                    <QRCode className="bg-white p-3 qrcodeContainer" value={appState.apresentation.showQRCode.link !== '' ? appState.apresentation.showQRCode.link : domainConfig.aplicativos + '/?token=' + dataApplication.token} />
                </div>
            </div>}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo}
                        alt="Logo"
                        className="App-logo pb-0" />
                    {appState.apresentation.prize.title.status && <h5 className="my-5"><TextWithLineBreaks text={appState.apresentation.prize.title.value}></TextWithLineBreaks></h5>}
                </div>
            </div>
            <div className="prizePresenterContainer">
                <AnimatePresence mode="wait">
                    {(() => {
                        switch (appState.contexto.prizeDrawState) {
                            default:
                            case 'waiting':
                                return (
                                    <motion.div className="vertically-centered" key={'waiting'} initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <h5 className="opacity-75">Aguardando sorteio...</h5>
                                    </motion.div>
                                )
                            case 'sorting':
                                return (
                                    <motion.div className="vertically-centered" key={'sorting'} initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                                        <h5 className="opacity-75 mt-3">Sorteando...</h5>
                                    </motion.div>
                                )
                            case 'show':
                                return (
                                    <ShowWinners contextData={contextData}></ShowWinners>
                                )
                        }
                    })()}
                </AnimatePresence>
            </div>
        </>
    )
}

const ShowWinners = ({ contextData }) => {
    const { dataApplication } = useGlobalState();
    const { appState, isPlaying } = useAppState();
    const winnerAudio = useRef(new Audio(winnerSound));

    useEffect(() => {
        if (!isPlaying || contextData.prizeDrawState !== 'show') {
            winnerAudio.current.volume = 0;
        } else {
            winnerAudio.current.volume = 0.7;
        }
    }, [isPlaying, contextData.prizeDrawState])

    useEffect(() => {
        winnerAudio.current.play();
    }, [contextData.prizeDrawState])

    return (
        <>
            <motion.img key={'prizegif'} initial={{ opacity: 0 }} className="drawPrizeGif pb-3"
                animate={{ opacity: 1, scale: [0.3, 1.2, 1], transition: { duration: 1 } }}
                exit={{ opacity: 0 }}
                src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.prize.image}
                alt="prizeImage">
            </motion.img>
            <div className="d-flex flex-wrap">
                {(() => {
                    switch (contextData.data?.type) {
                        case 'DrawDefault':
                            return (
                                <>
                                    {contextData.draw && contextData.draw.result && contextData.draw.result.map((props, index) => {
                                        let delay = 1 + (0.2 * index)
                                        let roundedDelay = parseFloat(delay.toFixed(2));
                                        if (!props) {
                                            return ""
                                        }
                                        return (
                                            <motion.div key={'DrawDefaultBox' + index} className="presentWinnerBox" initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: roundedDelay } }}
                                                exit={{ opacity: 0 }}>
                                                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (props[field.inputID]) {
                                                        return (
                                                            <p key={'presentWinnerParticipantDetails' + index} className='mb-0'>
                                                                {props[field.inputID].value}
                                                            </p>
                                                        );
                                                    }
                                                    return ""
                                                })}
                                            </motion.div>
                                        );
                                    })}
                                </>
                            )
                        case 'DrawName':
                            return (
                                <>
                                    {contextData.draw && contextData.draw.map((props, index) => {
                                        let delay = 1 + (0.2 * index)
                                        let roundedDelay = parseFloat(delay.toFixed(2));
                                        if (!props) {
                                            return ""
                                        }
                                        return (
                                            <motion.div key={'DrawNameBox' + index} className="presentWinnerBox" initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: roundedDelay } }}
                                                exit={{ opacity: 0 }}>{props}
                                            </motion.div>
                                        );
                                    })}
                                </>
                            )
                        case 'DrawNumber':
                            return (
                                <>
                                    {contextData.draw && contextData.draw.map((props, index) => {
                                        let delay = 1 + (0.2 * index)
                                        let roundedDelay = parseFloat(delay.toFixed(2));
                                        if (!props) {
                                            return ""
                                        }
                                        return (
                                            <motion.div key={'DrawNumberBox' + index} className="presentWinnerBox" initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: roundedDelay } }}
                                                exit={{ opacity: 0 }}>{props}
                                            </motion.div>
                                        );
                                    })}
                                </>
                            )
                        default:
                            break;
                    }
                })()}
            </div>
        </>
    )
}

const ResultHistoryModal = ({ modalData }) => {
    const { dataApplication } = useGlobalState();

    const ItemList = ({ index }) => {
        if (modalData.type === `DrawDefault`) {
            return (
                <li className="list-group-item" key={'listGroup' + index}>
                    <div className="row">
                        <div className="col">
                            {dataApplication.customizacao.form.fieldsToShow.map((inputID, indexList) => {
                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                if (modalData.result[index][field.inputID]) {
                                    return (
                                        <p key={'presentWinnerParticipantDetails' + index} className='mb-0'>
                                            {modalData.result[index][field.inputID].value}
                                        </p>
                                    );
                                }
                                return ""
                            })}
                        </div>
                    </div>
                </li>
            )
        } else {
            return (
                <li className="list-group-item" key={'listGroup' + index}>
                    <div className="row">
                        <div className="col">
                            <p className='mb-0 text-center'>{modalData.result[index]}</p>
                        </div>
                    </div>
                </li>
            )
        }
    }

    return (
        <ul className="list-group text-start my-3">
            {modalData.result && modalData.result.length > 0 ? (
                <div>
                    {modalData.result && Object.keys(modalData.result).map(key => (
                        <ItemList key={'itemList' + key} index={key}></ItemList>
                    ))}
                </div>
            ) : (
                <p className="mt-5 text-center">Não foi encontrado nenhum resultado</p>
            )}
        </ul>
    );
}

export default PrizeDrawAdmin
