

import React, { useEffect, useContext, useRef, useState } from 'react'
import axios from 'axios'
import * as Yup from "yup";
import { AnimatePresence, motion } from "framer-motion"
import {
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap"
import { Formik, ErrorMessage } from "formik"
import { formatInTimeZone } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareXmark,
    faMessage,
    faSquareCheck,
    faThumbsUp,
    faThumbsDown
} from "@fortawesome/free-solid-svg-icons";
import BaseLayout from '../../components/BaseLayout'
import { PageLoaderDots, PageLoader } from '../../components/Elements.js'
import { useAppState } from './context/AppContext.js'
import NotyfContext from "../../contexts/NotyfContext.js";
import { TextWithLineBreaks } from "../../components/Functions.js"
import { useGlobalState } from '../../hooks/useCustomization';
import { getRandomInterval } from "../../components/Functions.js"

const WordCloud = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, showMessages, dispatch } = useAppState();
    const [firstRender, setFirstRender] = useState(true);

    const getData = (setMessages = false) => {
        axios.get(`/api/eventos/messagesDisplay?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&visitante_id=${dataApplication.visitante.visitante_id}`)
            .then(function (response) {
                dispatch({
                    type: 'setMessages', payload: response.data.message
                });
                if (setMessages) {
                    setMessages(response.data.message)
                }
            }).catch(function (error) {
                console.log(error)
            })
    }

    return (
        <BaseLayout showBackButton={dataApplication.apps.length > 1 ? true : false} backButtonHandler={() => handleExit()}>
            <AnimatePresence mode="wait">
                {showMessages ? (
                    <MessageSent key={'messageSent'} getData={getData}></MessageSent>
                ) : (
                    <MessageForm key={'MessageForm'} getData={getData} firstRender={firstRender} setFirstRender={setFirstRender}></MessageForm>
                )}
            </AnimatePresence>
        </BaseLayout>
    )
}

const MessageSent = ({ getData }) => {
    const { dataApplication } = useGlobalState();
    const { appState, setUpdateMessages, setShowMessages, updateMessages } = useAppState();
    const [messages, setMessages] = useState(undefined)

    function removeMessageDisplay(mensagem_id) {
        axios.delete(`/api/eventos/messagesDisplay/${mensagem_id}`)
            .then(function (response) {
                setUpdateMessages((prevState) => prevState + 1)
            }).catch(function (error) {
                console.log(error)
            })
    }

    const ItemList = ({ index }) => {
        const words = JSON.parse(messages[index].message)
        const text = words.join(", ");
        const dateX = new Date(messages[index].data);
        const date = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");

        return (
            <li className="list-group-item" key={'listGroup' + index}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-2 fs-12">
                                {date}
                            </span>
                            <div>
                                {appState.userFeedback && messages[index].lido === 1 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Lido</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={faSquareCheck} />
                                    </OverlayTrigger>
                                }
                                {appState.userFeedback && messages[index].aprovado > 0 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>{messages[index].aprovado === 1 ? 'Aprovado' : 'Reprovado'}</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={messages[index].aprovado === 1 ? faThumbsUp : faThumbsDown} />
                                    </OverlayTrigger>
                                }
                                {appState.deleteHistory &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Excluir</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered cursor-pointer ps-1 primaryColorT" onClick={() => removeMessageDisplay(messages[index].mensagem_id)} size={"lg"} icon={faSquareXmark} />
                                    </OverlayTrigger>
                                }
                            </div>
                        </div>
                        <p className="mb-0">Mensagem: <span className="opacity-75">{text}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        getData(setMessages)
        const intervalId = setInterval(() => {
            getData(setMessages)
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages])

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h5 className="mb-3">Histórico</h5>
            <ul className="list-group text-center mb-3 pt-4">
                {!messages ? (
                    <div class="text-center">
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </div>
                ) : (
                    <>
                        {messages && messages.length > 0 ? (
                            <div>
                                {messages && Object.keys(messages).map(key => (
                                    <ItemList key={'itemList' + key} index={key}></ItemList>
                                ))}
                            </div>
                        ) : (
                            <li className="list-group-item py-4">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center my-2">Não foi encontrado nenhum resultado</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="text-center">
                <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setShowMessages(false)}>Voltar</button>
            </div>
        </motion.div>
    )
}

const MessageForm = ({ getData, firstRender, setFirstRender }) => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowMessages, setUpdateMessages, updateMessages } = useAppState();
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };
    function handleSentmessage() {
        if (appState.showMessageHistory) {
            setUpdateMessages((prevState) => prevState + 1)
            setShowMessages(true)
        }
    }

    const createInitialValues = () => {
        const initialValues = {};
        for (let i = 1; i <= appState.maxMessagePerLimit; i++) {
            initialValues[`input${i}`] = '';
        }
        return { messages: initialValues };
    };

    const createValidationSchema = () => {
        const validations = {};
        for (let i = 1; i <= appState.maxMessagePerLimit; i++) {
            validations[`input${i}`] = Yup.string()
                .required("Campo obrigatório")
                .matches(/^\S*$/, 'Somente uma palavra sem espaços é permitida') // This line ensures one word without spaces
                .max(appState.maxlength, `Máximo de ${appState.maxlength} caracteres`)
                .min(appState.minlength, `Mínimo de ${appState.minlength} caracteres`);
        }
        return Yup.object().shape({ messages: Yup.object().shape(validations) });
    };

    function registerMessage(values, actions) {
        const dateX = new Date();
        const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
        let wordsArray = [];
        Object.values(values.messages).forEach(inputContent => {
            wordsArray = [...wordsArray, ...inputContent.split(/\s+/).filter(word => word)];
        });
        axios.post("/api/eventos/messagesDisplay", {
            evento_id: dataApplication.evento_id,
            customizacao_id: appState.customizacao_id,
            visitante_id: dataApplication.visitante.visitante_id,
            message: JSON.stringify(wordsArray),
            data: now,
            lido: 0,
            aprovado: appState.autoApproval ? 1 : 0,
            favorito: 0,
            data_lido: null
        }).then(function (response) {
            notyf.open({
                type: "success",
                message: 'Enviado com sucesso!',
                ripple: true,
                dismissible: true,
            });
            if (appState.showMessageHistory) {
                setShowMessages(true)
            } else {
                actions.resetForm();
                actions.setSubmitting(false);
                setUpdateMessages((prevState) => prevState + 1)
            }
        }).catch(function (error) {
            actions.resetForm();
            actions.setSubmitting(false);
            notyf.open({
                type: "danger",
                message: 'Houve um erro, tente novamente',
                ripple: true,
                dismissible: true,
            });
        })
    }

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            getData()
        }
        const intervalId = setInterval(() => {
            getData()
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages])

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="col">
                {appState.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.title.value}></TextWithLineBreaks></h5></div>}
                {appState.description.status && <div className="col"><p><TextWithLineBreaks text={appState.description.value}></TextWithLineBreaks></p></div>}
                <button className="btn primaryColor primaryColorText mt-4" onClick={() => handleSentmessage()}>
                    <span className={`${appState.showMessageHistory ? ('cursor-pointer') : ('')}`} onClick={() => handleSentmessage()}>
                        <FontAwesomeIcon className={`vertically-centered me-2`} size={"lg"} icon={faMessage} />
                        {appState.messages && appState.messages.length ? appState.messages.length : 0}
                    </span>
                </button>
            </div>
            {appState.messages && appState.messages.length >= appState.messagesLimit ? (
                <>
                    <span className="badge primaryColor primaryColorText mt-4 fw-normal">Você atingiu o limite de envios</span>
                </>
            ) : (
                <Formik
                    initialValues={createInitialValues()}
                    validationSchema={createValidationSchema()}
                    onSubmit={registerMessage}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize>
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        isSubmitting,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit} className="text-start">
                            <Row className="mx-0 mt-3">
                                {Object.keys(values.messages).map((key, index) => (
                                    <Form.Group as={Col} sm={12} className='mb-3' key={'inputField' + key}>
                                        <Form.Control
                                            name={`messages.${key}`}
                                            value={values.messages[key]}
                                            onChange={handleChange}
                                            isInvalid={touched.messages && !!errors.messages && !!errors.messages[key]}
                                        />
                                        <ErrorMessage name={`messages.${key}`} component="div" className="invalid-feedback" />
                                    </Form.Group>
                                ))}
                            </Row>
                            <div className="text-center mt-3">
                                <button className="btn primaryColor btn-lg primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally}>
                                    {!isSubmitting ? 'Enviar' : <PageLoaderDots color="#fff" width="25" padding={0} />}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )
            }
        </motion.div >
    )
}

export default WordCloud