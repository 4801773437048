import { useAppState } from '../context/AppContext.js'
import onePlayer from '../assets/img/one-player.png'
import twoPlayer from '../assets/img/two-player.png'

const PlayerSelect = () => {
    const { appState, setStart, setDefinitions } = useAppState()

    const handleNextPage = (players) => {
        setDefinitions((prevState) => ({
            ...prevState,
            players: players
        }))
        setStart((prevState) => prevState + 1)
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                <div className="mb-4">
                    <h5 className="subtitle">{appState.memoryGame.allowTwoPlayers.description}</h5>
                </div>
            </div>
            <div className="col-6">
                <div className="card mb-4 pointer" onClick={() => handleNextPage(1)}>
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-12 selectPlayerCard">
                                <div>
                                    <img src={onePlayer} alt="logo" className="selectPlayerImage" />
                                </div>
                            </div>
                            <div className="col-12 pt-3 text-center">
                                <p className="mb-0">1 Jogador</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card mb-3 pointer" onClick={() => handleNextPage(2)}>
                    <div className="row card-body justify-content-center">
                        <div className="col-12 selectPlayerCard">
                            <div>
                                <img src={twoPlayer} alt="logo" className="selectPlayerImage" />
                            </div>
                        </div>
                        <div className="col-12 pt-3 text-center">
                            <p className="mb-0">2 Jogadores</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlayerSelect