import React, { useRef, useState } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import useOuterClick from "../hooks/useOuterClick";

export const PopoverPicker = ({ color, onChangeX, classes }) => {
    const popover = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const isColor = (strColor) => {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== "";
    };

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });

    return (
        <div className={classes} ref={innerRef}>
            <div
                className="swatch"
                style={{ backgroundColor: color }}
                onClick={() => setIsOpen(true)}
            />

            {isOpen && (
                <div className="popover" ref={popover}>
                    <RgbaStringColorPicker color={isColor(color) ? color : ""} onChange={onChangeX} />
                    <input type="text" style={{ borderRadius: '0 0 8px 8px' }} className="w-100 text-center" value={color} onChange={(e) => {
                        onChangeX(e.target.value)
                    }} />
                </div>
            )}
        </div>
    );
};


export const FormikPopoverPicker = ({ color, setFieldValue, submitForm = false, name, classes }) => {
    const popover = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const isColor = (strColor) => {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== "";
    };

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });

    const handleClick = (color) => {
        setFieldValue(name, color)
        if (submitForm) {
            submitForm()
        }
    }

    return (
        <div className={classes} ref={innerRef}>
            <div
                style={{ backgroundColor: color, border: '1px solid var(--baseColorText)', height: '38px', borderRadius: '6px' }}
                onClick={() => setIsOpen(true)}
            />
            {isOpen && (
                <div className="popover" ref={popover}>
                    <RgbaStringColorPicker color={isColor(color) ? color : ""} onChange={(color) => handleClick(color)} />
                    <input type="text" style={{ borderRadius: '0 0 8px 8px' }} className="w-100 text-center" value={color} onChange={(e) => handleClick(e.target.value)} />
                </div>
            )}
        </div>
    );
};

