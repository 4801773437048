import React from 'react';
import { Modal } from 'react-bootstrap';
import { Grid, ThreeDots } from 'react-loader-spinner'

export function ConfirmDialog({ title, body, show, handleClose, handleConfirm }) {

    return (
        <Modal show={show} 
        centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <button className="btn primaryColorT" onClick={() => handleClose()}>
                    Voltar
                </button>
                <button className="btn primaryColor primaryColorText" onClick={() => handleConfirm()}>
                    Continuar
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export const PageLoader = ({ color, width, padding = 2 }) => {
    return (
        <>
            <div className={`loaderScreen py-${padding}`}>
                <Grid
                    visible={true}
                    height={width}
                    width={width}
                    color={color}
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                />
            </div>
        </>
    )
}

export const PageLoaderDots = ({ color, width, padding = 2 }) => {
    return (
        <>
            <div className={`loaderScreen py-${padding}`}>
                <ThreeDots
                    visible={true}
                    height={width}
                    width={width}
                    color={color}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>
    )
}

export default PageLoader