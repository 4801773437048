import React from 'react'
import { AnimatePresence } from "framer-motion"
import { useGlobalState } from '../../hooks/useCustomization';
import { useAppState } from './context/AppContext.js'
import StartAnimation from "./components/StartAnimation.js"
import BaseLayout from "../../components/BaseLayout"
import PlayerSelect from "./components/PlayerSelect"
import Game from "./components/Game.js"
import End from "./components/End.js"
import Ranking from "./components/Ranking.js"
import './assets/style.css'

const MemoryGame = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, start, setStart } = useAppState();

    return (
        <AnimatePresence mode={'wait'}>
            {(() => {
                switch (start) {
                    case 0:
                        return (
                            <BaseLayout key={start} showBackButton={appState.reset.status ? true : false} backButtonHandler={() => handleExit()} >
                                <PlayerSelect key={start} />
                            </BaseLayout>
                        )
                    case 1:
                        return (
                            <StartAnimation key={start} timer={5} value={2} />
                        )
                    case 2:
                        return (
                            <Game key={start} />
                        )
                    case 3:
                        return (
                            <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={() => setStart(4)}>
                                <Ranking />
                            </BaseLayout>
                        )
                    case 4:
                        return (
                            <BaseLayout key={start} showBackButton={dataApplication.apps.length > 1 ? true : false} backButtonHandler={() => handleExit()}>
                                <End />
                            </BaseLayout>
                        )
                    default:
                        return ("")
                }
            })()}
        </AnimatePresence>
    )
}

export default MemoryGame
