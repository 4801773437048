

import React, { useState, useEffect, useContext, useRef } from 'react'
import { formatInTimeZone } from "date-fns-tz";
import { Formik } from "formik"
import * as Yup from "yup";
import { Accordion, Tabs, Tab, Modal, Row, Col, Form, Tooltip, OverlayTrigger, Card } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import NotyfContext from "../../contexts/NotyfContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageLoader, PageLoaderDots, ConfirmDialog } from '../../components/Elements.js'
import {
    faArrowLeft,
    faPlus,
    faRotate
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, updateRoulette, updateRouletteIndex, postRouletteResult } from "./context/AppContext.js"
import { TextWithLineBreaks, convertDate, ColumnSortingTable, filterTableHandle } from "../../components/Functions.js"
import { Wheel } from 'spin-wheel';
import { FormikPopoverPicker } from "../../components/PopoverPicker.js";
import { FormComponent } from '../../Register.js'
import spinSoundFile from "../../assets/sounds/wheel-spin.mp3"
import winnerSoundFile from "../../assets/sounds/30311764_the-winner_by_gamechestaudio_preview.mp3"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const RouletteDrawAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Configurações`}>
                                    <Painel></Painel>
                                </Tab>
                                <Tab eventKey="k2" title={`Resultados (${appState.results.length})`}>
                                    <Resultados></Resultados>
                                </Tab>
                                <Tab eventKey="k3" title={`Simulador de resultado`}>
                                    <Test></Test>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 775px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Painel = () => {
    const { appState, isPlaying, dispatch } = useAppState();
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [modalResult, setModalResult] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [fonts, setFonts] = useState([]);
    const wheelRef = useRef();
    const wheelInstance = useRef();
    const wheelButton = useRef();
    const notyf = useContext(NotyfContext);
    const spinSound = useRef(new Audio(spinSoundFile));
    const winnerSound = useRef(new Audio(winnerSoundFile));

    function openSegment(value) {
        setShowModal(true);
        setModalData(value)
    }

    function removeSegment(values) {
        let updatedItems = {
            ...appState.roulette,
            items: [...appState.roulette.items]
        };
        updatedItems.items = updatedItems.items.filter(item => item.id !== values.id);
        updateRoulette(updatedItems)
        dispatch({ type: 'updateRoulette', payload: updatedItems });
        setShowModal(false);
    }

    function registerData(values, actions) {
        let updatedItems = {
            ...appState.roulette,
            items: [...appState.roulette.items]
        };
        if (values.new) {
            values.id = 'id-' + Date.now().toString(36) + Math.random().toString(36).substr(2)
            const { new: newData, ...dataWithoutNew } = values;
            updatedItems.items.push(dataWithoutNew)
        } else {
            const index = updatedItems.items.findIndex(value => value.id === values.id);
            updatedItems.items[index] = values
        }
        updateRoulette(updatedItems)
        dispatch({ type: 'updateRoulette', payload: updatedItems });
        setShowModal(false);
    }

    function registerDataChange(values, actions) {
        let updatedItems = {
            ...appState.roulette,
            items: [...appState.roulette.items]
        };
        updatedItems = {
            ...updatedItems,
            ...values
        };
        updateRoulette(updatedItems)
        dispatch({ type: 'updateRoulette', payload: updatedItems });
        return

    }

    function spinRoulette() {
        let totalPossibility = 0
        appState.roulette.items.forEach(element => {
            totalPossibility = totalPossibility + element.possibility
        });
        if (totalPossibility < 99) {
            notyf.open({
                type: "danger",
                message: 'A soma das probabilidades precisar ser de 100',
                ripple: true,
                dismissible: true,
            });
        } else {
            if (wheelButton.current) {
                wheelButton.current.style.display = 'none'
            }
            if (appState.roulette.nextResult !== false) {
                let index = appState.roulette.items.findIndex(value => value.id === appState.roulette.nextResult);
                wheelInstance.current.spinToItem(index, appState.roulette.duration, appState.roulette.spinToCenter, appState.roulette.numberOfRevolutions, 1, null)
            } else {
                const preProcessedItems = preProcess(appState.roulette.items);
                const pulledCharacter = binarySearchGachaPull(preProcessedItems);
                let index = appState.roulette.items.findIndex(value => value.id === pulledCharacter.id);
                wheelInstance.current.spinToItem(index, appState.roulette.duration, appState.roulette.spinToCenter, appState.roulette.numberOfRevolutions, 1, null)
            }
        }
    }

    function onSelectFile(e, field, setFieldValue, submitForm) {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];
        if (e.target.files[0].errors) {
            notyf.open({
                type: "danger",
                message: 'Aconteceu algum problema, tente novamente',
                ripple: true,
                dismissible: true,
            });
        } else if (!validImageTypes.includes(fileType)) {
            notyf.open({
                type: "danger",
                message: 'Formato de imagem inválido',
                ripple: true,
                dismissible: true,
            });
        } else if (file.size > 10000000) {
            notyf.open({
                type: "danger",
                message: 'Arquivo muito grande. Tamanho máximo: 10mb',
                ripple: true,
                dismissible: true,
            });
        } else {
            const formData = new FormData();
            formData.append("file", file);
            axios
                .post(`/api/upload/customizacao/${appState.customizacao_id}`, formData)
                .then((response) => {
                    let url = 'https://images.flexinterativa.com.br/customizacao/' + appState.customizacao_id + '/' + response.data.message
                    setFieldValue(field, url)
                    if (submitForm) {
                        submitForm()
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    function handleDragStart(e, draggedItem) {
        e.dataTransfer.setData("text/plain", draggedItem);
    }

    function handleDrop(e, targetItem) {
        e.preventDefault();
        const draggedItem = e.dataTransfer.getData("text/plain");
        if (draggedItem !== targetItem) {
            // Logic to rearrange items
            rearrangeSegments(draggedItem, targetItem);
        }
    }

    function handleDragOver(e) {
        e.preventDefault(); // Necessary to allow dropping
    }

    function rearrangeSegments(draggedItemIndex, targetItemIndex) {
        updateRouletteIndex(draggedItemIndex, targetItemIndex)
    }

    function handleConfirmReset() {
        let updatedItems = defaultRoulette;
        updateRoulette(updatedItems)
        dispatch({ type: 'updateRoulette', payload: updatedItems });
        setShowConfirm(false)
    }

    useEffect(() => {
        spinSound.current.volume = 0;
        winnerSound.current.volume = 0;
        if (isPlaying && appState.apresentation.configs.sound) {
            spinSound.current.volume = 0.7;
            winnerSound.current.volume = 0.7;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying])

    useEffect(() => {
        let newRoulette = { ...appState.roulette, items: [...appState.roulette.items.map(item => ({ ...item }))] };
        if (newRoulette.activatePrizes) {
            newRoulette.items = newRoulette.items.filter(element => element.quantity > 0);
        }
        newRoulette.items.forEach(element => {
            if (element.image && element.image !== '') {
                element.label = ''
            }
        });
        if (wheelRef.current) {
            if (!wheelInstance.current) {
                wheelInstance.current = new Wheel(wheelRef.current, newRoulette);
            } else {
                wheelInstance.current.remove()
                wheelInstance.current = new Wheel(wheelRef.current, newRoulette);
            }
            if (wheelButton.current) {
                wheelButton.current.style.display = 'block'
            }
            wheelInstance.current.onRest = (e) => {
                winnerSound.current.currentTime = 0;
                winnerSound.current.play();
                setShowResult(true)
                setModalResult(appState.roulette.items[e.currentIndex])
            };
            wheelInstance.current.onCurrentIndexChange = (e) => {
                spinSound.current.currentTime = 0;
                spinSound.current.play();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.roulette]);

    useEffect(() => {
        axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBQSHG64iczeP5lb7OvezR9kkn0LSU8vEc&sort=popularity`)
            .then((response) => {
                let placeIDs = []
                response.data.items.slice([0], [100]).map((item, i) => {
                    return placeIDs.push({ font: item.family });
                });
                setFonts(placeIDs)
            }).catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?family=${appState.roulette.itemLabelFont.replace(/ /g, '+')}&display=swap`;
        document.head.appendChild(link);
    }, [appState.roulette.itemLabelFont])

    return (
        <>
            <ConfirmDialog
                show={showConfirm}
                title={"Confirmar ação"}
                body={"Deseja resetar os valores para o padrão? Os segmentos existentes não serão perdidos."}
                handleClose={() => setShowConfirm(false)}
                handleConfirm={handleConfirmReset}
            />
            <Row className="mt-4 mx-0 text-center">
                <div className="col-8 pb-3 position-relative">
                    <h6 className="mb-2">Segmentos
                        <OverlayTrigger
                            overlay={<Tooltip>Adicionar segmento</Tooltip>}
                        >
                            <button className="btn btn-sm primaryColor primaryColorText pointer ms-2" type="button" onClick={(() => openSegment(defaultSegment))}>
                                <FontAwesomeIcon icon={faPlus} size={'md'} className="primaryColorText" />
                            </button>
                        </OverlayTrigger>
                    </h6>
                    <small className="opacity-75">(arraste e solte para ordenar)</small>
                    {appState.roulette.items.length === 0 && <p className="mt-5 text-opacity-75">Nenhum segmento encontrado</p>}
                    <div className="col mt-4 text-center">
                        {appState.roulette.items.map((props, index) => {
                            return (
                                <button key={'segments' + index} className="btn btn-sm primaryColor primaryColorText mx-2 mb-3" onClick={(() => openSegment(props))}
                                    draggable="true"
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDrop={(e) => handleDrop(e, index)}
                                    onDragOver={handleDragOver}>
                                    {props.label}
                                </button>
                            );
                        })}
                    </div>
                    <div className="wheel-wrapper-panel" ref={wheelRef}>
                        {!appState.roulette.isInteractive && appState.roulette.items.length > 0 && <div ref={wheelButton} className="wheelButton" onClick={(() => spinRoulette())}>Clique para rodar a roleta</div>}
                    </div>
                </div>
                <div className="col-4 pb-3">
                    <h6 className="mb-3">Definições avançadas
                        <OverlayTrigger
                            overlay={<Tooltip>Redefinir</Tooltip>}
                        >
                            <button className="btn btn-sm primaryColor primaryColorText pointer ms-3" type="button" onClick={((e) => { setShowConfirm(true); e.preventDefault(); e.stopPropagation(); })}>
                                <FontAwesomeIcon icon={faRotate} className="primaryColorText" />
                            </button>
                        </OverlayTrigger>
                    </h6>
                    <Card className="bg-transparent">
                        <Card.Body className="rouletteConfigsCard p-0">
                            <PerfectScrollbar className="h-100 p-3">
                                <Formik
                                    onSubmit={registerDataChange}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    enableReinitialize
                                    initialValues={{
                                        roulette: true,
                                        register: appState.roulette.register !== undefined ? appState.roulette.register : defaultRoulette.register,
                                        nextResult: appState.roulette.nextResult !== undefined ? appState.roulette.nextResult : defaultRoulette.nextResult,
                                        activatePrizes: appState.roulette.activatePrizes !== undefined ? appState.roulette.activatePrizes : defaultRoulette.activatePrizes,
                                        spinToCenter: appState.roulette.spinToCenter !== undefined ? appState.roulette.spinToCenter : defaultRoulette.spinToCenter,
                                        duration: appState.roulette.duration !== undefined ? appState.roulette.duration : defaultRoulette.duration,
                                        numberOfRevolutions: appState.roulette.numberOfRevolutions !== undefined ? appState.roulette.numberOfRevolutions : defaultRoulette.numberOfRevolutions,
                                        borderColor: appState.roulette.borderColor !== undefined ? appState.roulette.borderColor : defaultRoulette.borderColor,
                                        borderWidth: appState.roulette.borderWidth !== undefined ? appState.roulette.borderWidth : defaultRoulette.borderWidth,
                                        debug: appState.roulette.debug !== undefined ? appState.roulette.debug : defaultRoulette.debug,
                                        image: appState.roulette.image !== undefined ? appState.roulette.image : defaultRoulette.image,
                                        isInteractive: appState.roulette.isInteractive !== undefined ? appState.roulette.isInteractive : defaultRoulette.isInteractive,
                                        itemLabelAlign: appState.roulette.itemLabelAlign !== undefined ? appState.roulette.itemLabelAlign : defaultRoulette.itemLabelAlign,
                                        itemLabelBaselineOffset: appState.roulette.itemLabelBaselineOffset !== undefined ? appState.roulette.itemLabelBaselineOffset : defaultRoulette.itemLabelBaselineOffset,
                                        itemLabelColors: appState.roulette.itemLabelColors !== undefined ? appState.roulette.itemLabelColors : defaultRoulette.itemLabelColors,
                                        itemLabelFont: appState.roulette.itemLabelFont !== undefined ? appState.roulette.itemLabelFont : defaultRoulette.itemLabelFont,
                                        itemLabelFontSizeMax: appState.roulette.itemLabelFontSizeMax !== undefined ? appState.roulette.itemLabelFontSizeMax : defaultRoulette.itemLabelFontSizeMax,
                                        itemLabelRadius: appState.roulette.itemLabelRadius !== undefined ? appState.roulette.itemLabelRadius : defaultRoulette.itemLabelRadius,
                                        itemLabelRadiusMax: appState.roulette.itemLabelRadiusMax !== undefined ? appState.roulette.itemLabelRadiusMax : defaultRoulette.itemLabelRadiusMax,
                                        itemLabelRotation: appState.roulette.itemLabelRotation !== undefined ? appState.roulette.itemLabelRotation : defaultRoulette.itemLabelRotation,
                                        itemLabelStrokeColor: appState.roulette.itemLabelStrokeColor !== undefined ? appState.roulette.itemLabelStrokeColor : defaultRoulette.itemLabelStrokeColor,
                                        itemLabelStrokeWidth: appState.roulette.itemLabelStrokeWidth !== undefined ? appState.roulette.itemLabelStrokeWidth : defaultRoulette.itemLabelStrokeWidth,
                                        lineColor: appState.roulette.lineColor !== undefined ? appState.roulette.lineColor : defaultRoulette.lineColor,
                                        lineWidth: appState.roulette.lineWidth !== undefined ? appState.roulette.lineWidth : defaultRoulette.lineWidth,
                                        overlayImage: appState.roulette.overlayImage !== undefined ? appState.roulette.overlayImage : defaultRoulette.overlayImage,
                                        pixelRatio: appState.roulette.pixelRatio !== undefined ? appState.roulette.pixelRatio : defaultRoulette.pixelRatio,
                                        pointerAngle: appState.roulette.pointerAngle !== undefined ? appState.roulette.pointerAngle : defaultRoulette.pointerAngle,
                                        radius: appState.roulette.radius !== undefined ? appState.roulette.radius : defaultRoulette.radius,
                                        rotationResistance: appState.roulette.rotationResistance !== undefined ? appState.roulette.rotationResistance : defaultRoulette.rotationResistance,
                                        rotationSpeedMax: appState.roulette.rotationSpeedMax !== undefined ? appState.roulette.rotationSpeedMax : defaultRoulette.rotationSpeedMax,
                                    }}
                                >
                                    {({
                                        submitForm,
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        setSubmitting,
                                        setFieldValue,
                                    }) => (
                                        <Form noValidate onChange={handleSubmit} className="text-start">
                                            <Row className="mx-0">
                                                <a className="pb-3" href="https://crazytim.github.io/spin-wheel/props-diagram.svg" target="_blank" rel="noreferrer">Entenda como funciona os segmentos</a>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Check
                                                        checked={values.isInteractive}
                                                        type="checkbox"
                                                        name={`isInteractive`}
                                                        label={'Modo interativo'}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `isInteractive`,
                                                                values.isInteractive === true
                                                                    ? false
                                                                    : true
                                                            );
                                                        }}
                                                        value={values.isInteractive}>
                                                    </Form.Check>
                                                    <Form.Check
                                                        checked={values.activatePrizes}
                                                        type="checkbox"
                                                        name={`activatePrizes`}
                                                        label={'Modo com premiação'}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `activatePrizes`,
                                                                values.activatePrizes === true
                                                                    ? false
                                                                    : true
                                                            );
                                                        }}
                                                        value={values.activatePrizes}>
                                                    </Form.Check>
                                                    <Form.Check
                                                        disabled={values.isInteractive ? true : false}
                                                        checked={values.nextResult !== false ? true : false}
                                                        type="checkbox"
                                                        name={`nextResult`}
                                                        label={'Selecionar próximo resultado'}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `nextResult`,
                                                                values.nextResult !== false
                                                                    ? false
                                                                    : appState.roulette.items[0].id
                                                            );
                                                        }}
                                                        value={values.nextResult}>
                                                    </Form.Check>
                                                    {values.nextResult !== false && <Form.Select
                                                        className="mb-3 mt-2"
                                                        name={'nextResult'}
                                                        onChange={handleChange}
                                                        value={values.nextResult}
                                                        defaultValue={values.nextResult}
                                                    >
                                                        {appState.roulette.items.map((props, index) => {
                                                            return (
                                                                <option key={'nextResult' + index} value={props.id}>{props.label}</option>
                                                            );
                                                        })}
                                                    </Form.Select>}
                                                    <Form.Check
                                                        checked={values.spinToCenter ? true : false}
                                                        type="checkbox"
                                                        name={`spinToCenter`}
                                                        label={'Centralizar seta no segmento'}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `spinToCenter`,
                                                                values.spinToCenter
                                                                    ? false
                                                                    : true
                                                            );
                                                        }}
                                                        value={values.spinToCenter}>
                                                    </Form.Check>
                                                    <Form.Check
                                                        checked={values.debug}
                                                        type="checkbox"
                                                        name={`debug`}
                                                        label={'Debug'}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `debug`,
                                                                values.debug === true
                                                                    ? false
                                                                    : true
                                                            );
                                                        }}
                                                        value={values.debug}>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Fonte</Form.Label>
                                                    <Form.Select
                                                        name="language"
                                                        defaulValue={values.itemLabelFont}
                                                        value={values.itemLabelFont}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                `itemLabelFont`,
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        {fonts.map((element, index) => {
                                                            return (
                                                                <option selected={element.font === values.itemLabelFont ? true : false} style={{ 'fontFamily': element.font }} key={index} value={element.font}>
                                                                    {element.font}
                                                                </option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Duração do giro: <span className="opacity-75">{values.duration / 1000}s</span></Form.Label>
                                                    <Form.Range
                                                        name={'duration'}
                                                        value={values.duration}
                                                        min="1000"
                                                        max="5000"
                                                        step="100"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Total de giros: <span className="opacity-75">{values.numberOfRevolutions}</span></Form.Label>
                                                    <Form.Range
                                                        name={'numberOfRevolutions'}
                                                        value={values.numberOfRevolutions}
                                                        min="1"
                                                        max="5"
                                                        step="1"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Resistência da rotação: <span className="opacity-75">{values.rotationResistance}</span></Form.Label>
                                                    <Form.Range
                                                        name={'rotationResistance'}
                                                        value={values.rotationResistance}
                                                        onChange={handleChange}
                                                        min="-200"
                                                        max="0"
                                                        step="1"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Velocidade máxima da rotação: <span className="opacity-75">{values.rotationSpeedMax}</span></Form.Label>
                                                    <Form.Range
                                                        name={'rotationSpeedMax'}
                                                        value={values.rotationSpeedMax}
                                                        onChange={handleChange}
                                                        min="50"
                                                        max="1000"
                                                        step="1"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Imagem do centro {values.image !== '' && values.image !== null && <span className="badge primaryColor primaryColorText pointer" onClick={() => { setFieldValue('image', ''); submitForm() }}>Remover</span>}</Form.Label>
                                                    {values.image !== '' && values.image !== null ?
                                                        <div className="col colorBlock ms-0 me-2" style={{ backgroundImage: `url(${values.image})` }}></div>
                                                        :
                                                        <Form.Control
                                                            type="file"
                                                            name={'image'}
                                                            onChange={(e) => onSelectFile(e, 'image', setFieldValue, submitForm)}
                                                        />}
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Cor da borda</Form.Label>
                                                    <FormikPopoverPicker color={values.borderColor} setFieldValue={setFieldValue} submitForm={submitForm} name="borderColor" classes="picker" />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>borderWidth: <span className="opacity-75">{values.borderWidth}px</span></Form.Label>
                                                    <Form.Range
                                                        name={'borderWidth'}
                                                        value={values.borderWidth}
                                                        min="00"
                                                        max="25"
                                                        step="1"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Alinhamento da legenda</Form.Label>
                                                    <Form.Select
                                                        name={'itemLabelAlign'}
                                                        onChange={handleChange}
                                                        value={values.itemLabelAlign}
                                                    >
                                                        <option value={'right'}>right</option>
                                                        <option value={'left'}>left</option>
                                                        <option value={'center'}>center</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>BaselineOffset: <span className="opacity-75">{values.itemLabelBaselineOffset}</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelBaselineOffset'}
                                                        value={values.itemLabelBaselineOffset}
                                                        onChange={handleChange}
                                                        min="-0.5"
                                                        max="0.5"
                                                        step="0.01"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Tamanho da fonte do segmento: <span className="opacity-75">{values.itemLabelFontSizeMax}px</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelFontSizeMax'}
                                                        value={values.itemLabelFontSizeMax}
                                                        onChange={handleChange}
                                                        min="10"
                                                        max="100"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>itemLabelRadius: <span className="opacity-75">{values.itemLabelRadius}</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelRadius'}
                                                        value={values.itemLabelRadius}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="1"
                                                        step="0.01"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>itemLabelRadiusMax: <span className="opacity-75">{values.itemLabelRadiusMax}</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelRadiusMax'}
                                                        value={values.itemLabelRadiusMax}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="1"
                                                        step="0.01"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>itemLabelRotation: <span className="opacity-75">{values.itemLabelRotation}</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelRotation'}
                                                        value={values.itemLabelRotation}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="360"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>itemLabelStrokeWidth: <span className="opacity-75">{values.itemLabelStrokeWidth}</span></Form.Label>
                                                    <Form.Range
                                                        name={'itemLabelStrokeWidth'}
                                                        value={values.itemLabelStrokeWidth}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="30"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>lineColor</Form.Label>
                                                    <FormikPopoverPicker color={values.lineColor} setFieldValue={setFieldValue} submitForm={submitForm} name="lineColor" classes="picker" />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>lineWidth: <span className="opacity-75">{values.lineWidth}</span></Form.Label>
                                                    <Form.Range
                                                        name={'lineWidth'}
                                                        value={values.lineWidth}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="20"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>Imagem de sobreposição {values.overlayImage !== '' && values.overlayImage !== null && <span className="badge primaryColor primaryColorText pointer" onClick={() => { setFieldValue('overlayImage', ''); submitForm() }}>Remover</span>}</Form.Label>
                                                    {values.overlayImage !== '' && values.overlayImage !== null ?
                                                        <div className="col colorBlock ms-0 me-2" style={{ backgroundImage: `url(${values.overlayImage})` }}></div>
                                                        :
                                                        <Form.Control
                                                            type="file"
                                                            name={'overlayImage'}
                                                            onChange={(e) => onSelectFile(e, 'overlayImage', setFieldValue, submitForm)}
                                                        />}
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>pixelRatio: <span className="opacity-75">{values.pixelRatio}</span></Form.Label>
                                                    <Form.Range
                                                        name={'pixelRatio'}
                                                        value={values.pixelRatio}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="4"
                                                        step="0.1"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>pointerAngle: <span className="opacity-75">{values.pointerAngle}</span></Form.Label>
                                                    <Form.Range
                                                        name={'pointerAngle'}
                                                        value={values.pointerAngle}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="360"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className='mb-3'>
                                                    <Form.Label>radius: <span className="opacity-75">{values.radius}</span></Form.Label>
                                                    <Form.Range
                                                        name={'radius'}
                                                        value={values.radius}
                                                        onChange={handleChange}
                                                        min="0"
                                                        max="1"
                                                        step="0.01"
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </PerfectScrollbar>
                        </Card.Body>
                    </Card>
                </div>
                {modalData && <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showModal}
                    onHide={() => setShowModal(false)}>
                    <Modal.Body>
                        <Formik
                            onSubmit={registerData}
                            validateOnChange={false}
                            validateOnBlur={true}
                            validationSchema={Yup.object().shape({
                                label: Yup.string()
                                    .required("Campo obrigatório")
                                    .max(100, `Máximo de 100 caracteres`),
                                possibility: Yup.number()
                                    .positive('Número maior que 0')
                                    .min(0, `Mínimo de 0`)
                                    .max(100, `Máximo de 100`),
                                prize: Yup.string()
                                    .max(100, `Máximo de 100 caracteres`),
                                quantity: Yup.number()
                                    .min(0, `Mínimo de 0`)

                            })}
                            initialValues={{
                                id: modalData.id,
                                label: modalData.label,
                                possibility: modalData.possibility,
                                prize: modalData.prize,
                                quantity: modalData.quantity,
                                backgroundColor: modalData.backgroundColor,
                                image: modalData.image,
                                imageOpacity: modalData.imageOpacity,
                                imageRadius: modalData.imageRadius,
                                imageRotation: modalData.imageRotation,
                                imageScale: modalData.imageScale,
                                labelColor: modalData.labelColor,
                                value: modalData.value,
                                weight: modalData.weight,
                                new: modalData.new ? true : false
                            }}
                        >
                            {({
                                submitForm,
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                                dirty,
                                isSubmitting,
                                setSubmitting,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="text-center">
                                        <h5 className="text-center mb-4">{modalData.new ? 'Adicionar segmento' : 'Editar segmento'}</h5>
                                    </div>
                                    <Row className="mx-0">
                                        <Form.Group as={Col} sm={8} className='mb-3'>
                                            <Form.Label>Segmento</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={'label'}
                                                value={values.label}
                                                onChange={handleChange}
                                                isInvalid={!!errors.label}
                                                isValid={touched.label && !errors.label}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.label}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} className='mb-3'>
                                            <Form.Label>Probabilidade</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'possibility'}
                                                value={values.possibility}
                                                onChange={handleChange}
                                                min={0}
                                                max={100}
                                                isInvalid={!!errors.possibility}
                                                isValid={touched.possibility && !errors.possibility}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.possibility}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {appState.roulette.activatePrizes &&
                                            <Form.Group as={Col} sm={8} className='mb-3'>
                                                <Form.Label>Prêmio</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name={'prize'}
                                                    value={values.prize}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.prize}
                                                    isValid={touched.prize && !errors.prize}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.prize}
                                                </Form.Control.Feedback>
                                            </Form.Group>}
                                        {appState.roulette.activatePrizes && <Form.Group as={Col} sm={4} className='mb-3'>
                                            <Form.Label>Quantidade</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name={'quantity'}
                                                value={values.quantity}
                                                onChange={handleChange}
                                                isInvalid={!!errors.quantity}
                                                isValid={touched.quantity && !errors.quantity}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.quantity}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        }
                                        <Accordion className="my-3">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Definições avançadas</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className="mx-0">
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>labelColor</Form.Label>
                                                            <FormikPopoverPicker color={values.labelColor} setFieldValue={setFieldValue} name="labelColor" classes="picker" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>backgroundColor</Form.Label>
                                                            <FormikPopoverPicker color={values.backgroundColor} setFieldValue={setFieldValue} name="backgroundColor" classes="picker" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={12} className='mb-3'>
                                                            <Form.Label>image {values.image !== '' && values.image !== null && <span className="badge primaryColor primaryColorText pointer" onClick={() => { setFieldValue('image', ''); submitForm() }}>Remover</span>}</Form.Label>
                                                            {values.image !== '' && values.image !== null ?
                                                                <div className="col colorBlock ms-0 me-2" style={{ backgroundImage: `url(${values.image})` }}></div>
                                                                :
                                                                <Form.Control
                                                                    type="file"
                                                                    name={'image'}
                                                                    onChange={(e) => onSelectFile(e, 'image', setFieldValue)}
                                                                />}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>imageOpacity: {values.imageOpacity}</Form.Label>
                                                            <Form.Range
                                                                name={'imageOpacity'}
                                                                value={values.imageOpacity}
                                                                min="0"
                                                                max="1"
                                                                step="0.01"
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>imageRadius: {values.imageRadius}</Form.Label>
                                                            <Form.Range
                                                                name={'imageRadius'}
                                                                value={values.imageRadius}
                                                                min="0"
                                                                max="1"
                                                                step="0.01"
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>imageRotation: {values.imageRotation}</Form.Label>
                                                            <Form.Range
                                                                name={'imageRotation'}
                                                                value={values.imageRotation}
                                                                min="0"
                                                                max="360"
                                                                step="1"
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>imageScale: {values.imageScale}</Form.Label>
                                                            <Form.Range
                                                                name={'imageScale'}
                                                                value={values.imageScale}
                                                                min="0"
                                                                max="1"
                                                                step="0.1"
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6} className='mb-3'>
                                                            <Form.Label>weight: {values.weight}</Form.Label>
                                                            <Form.Range
                                                                name={'weight'}
                                                                value={values.weight}
                                                                min="0"
                                                                max="5"
                                                                step="1"
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Row>
                                    <div className="text-center mt-3">
                                        <button className="btn primaryColor primaryColorText me-3" onClick={() => removeSegment(values)} type="button">Deletar </button>
                                        <button className="btn primaryColor primaryColorText me-3" onClick={() => setShowModal(false)} type="button">Voltar</button>
                                        <button className="btn primaryColor primaryColorText" type="submit" disabled={isSubmitting}>Salvar</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>}
                {showResult && <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showResult}
                    onHide={() => {
                        setShowResult(false);
                        if (wheelButton.current) {
                            wheelButton.current.style.display = 'block'
                        }
                    }}>
                    <Modal.Body className="text-center">
                        <h5>Resultado</h5>
                        <button className="btn noClickButton mt-4">
                            {modalResult.label}
                        </button>
                        {appState.roulette.activatePrizes && modalResult.prize !== null && modalResult.prize !== '' && <div className="dataElementsContainer text-start mt-3">
                            Prêmio: <span className="opacity-75">{modalResult.prize}</span>
                        </div>}
                    </Modal.Body>
                </Modal>}
            </Row >
        </>
    )
}


const Test = () => {
    const { appState } = useAppState()
    const notyf = useContext(NotyfContext);
    const [testData, setTestData] = useState()

    function rouletteTest(testCount, items, setTestData) {
        let data = {}
        let totalPossibility = 0
        appState.roulette.items.forEach(element => {
            totalPossibility = totalPossibility + element.possibility
        });
        if (totalPossibility < 99) {
            notyf.open({
                type: "danger",
                message: 'A soma das probabilidades precisar ser de 100',
                ripple: true,
                dismissible: true,
            });
            return
        }
        for (let index = 0; index < testCount; index++) {
            const preProcessedItems = preProcess(items);
            const pulledCharacter = binarySearchGachaPull(preProcessedItems);
            if (data && !data[pulledCharacter.id]) {
                data[pulledCharacter.id] = pulledCharacter
                data[pulledCharacter.id].total = 1
            } else {
                data[pulledCharacter.id].total++
            }
        }
        const itemsArray = Object.values(data);
        itemsArray.sort((a, b) => b.total - a.total);
        setTestData(itemsArray)
    }

    return (
        <Row className="mt-4">
            <p className="mb-3">Veja o resultado previsto da roleta em 100 utilizações</p>
            <div className="mt-3 text-center">
                {(testData && Object.keys(testData).length > 0) ? (
                    <ul class="list-group">
                        {Object.values(testData).map((item) => (
                            <li class="list-group-item text-start" key={item.id}>
                                <p className="mb-0">Segmento: <span className="opacity-75">{item.label}</span></p>
                                <p className="mb-0">Prêmio: <span className="opacity-75">{item.prize}</span></p>
                                <p className="mb-0">Probabilidade: <span className="opacity-75">{item.possibility}%</span></p>
                                <p className="mb-0">Total selecionado: <span className="opacity-75">{item.total} vezes</span></p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <>
                        <p className="opacity-75 mt-5">Nenhum teste realizado</p>
                    </>
                )}
                <button className="btn primaryColor primaryColorText mt-3" onClick={() => rouletteTest(100, appState.roulette.items, setTestData)}>Calcular</button>
            </div>
        </Row>
    )
}

const Resultados = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState();
    const [fields, setFields] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);

    useEffect(() => {
        let fieldsX = []
        fieldsX.push({
            Header: 'Segmento',
            accessor: 'label',
        })
        fieldsX.push({
            Header: 'Prêmio',
            accessor: 'prize',
        })
        dataApplication.customizacao.form.fieldsToShow.forEach((element) => {
            dataApplication.customizacao.form.fields.forEach((element2) => {
                if (element === element2.inputID) {
                    fieldsX.push(filterTableHandle(element2))
                }
            });
        });
        fieldsX.push({
            Header: 'Data do sorteio',
            accessor: 'data',
            Cell: (row) => {
                return convertDate(row.value)
            },
        })
        setFields(fieldsX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (fields) {
        return (
            <div>
                <div className="tablePresenter">
                    <ColumnSortingTable
                        columns={fields}
                        data={appState.results}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "data" ? "center" : "left",
                            },
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </div>
            </div>
        );
    }
}

export const RouletteModal = () => {
    const { setShowPresentation } = useAppState();
    const [step, setStep] = useState(0)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AnimatePresence mode="wait" initial={false}>
            <StepComponent key={'StepComponent' + step} step={step} setStep={setStep}></StepComponent>
        </AnimatePresence>
    )
}

const StepComponent = ({ step, setStep }) => {
    const { dataApplication } = useGlobalState()
    const { appState, setShowPresentation } = useAppState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);

    function handleEndRoulette() {
        setStep(0)
    }

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    function registerUser(values, actions) {
        let json = {}
        let dateX = new Date();
        let data_visitacao = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm");
        if (values) {
            if (values.autorization) {
                delete values.autorization
            }
            Object.entries(values).forEach(entry => {
                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            })
        }
        json = JSON.stringify(json)
        axios.post("/api/eventos/register", {
            evento_id: dataApplication.evento_id,
            json: json,
            data_visitacao: data_visitacao,
        }).then(function (response) {
            if (response.data.message.encripted) {
                setStep((lastStep) => lastStep + 1)
                postRouletteResult(appState.rouletteResult, response.data.message.visitante_id, appState.roulette.activatePrizes)
            } else {
                actions.setSubmitting(false);
                notyf.open({
                    type: "danger",
                    message: 'Houve um erro no cadastro do usuário',
                    ripple: true,
                    dismissible: true,
                });
            }
        }).catch(function (error) {
            actions.setSubmitting(false);
            notyf.open({
                type: "danger",
                message: 'Houve um erro no cadastro do usuário',
                ripple: true,
                dismissible: true,
            });
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (step) {
        default:
        case 0:
            return (
                <motion.div className="w-100 h-100 p-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <WheelComponent key={'wheelRoulete'} setStep={setStep}></WheelComponent>
                </motion.div>
            )
        case 1:
            return (
                <motion.div className='larger-box pointer' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="photoboothLogo">
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                    </div>
                    <div className={`box-full`}>
                        <div className="text-center">
                            {appState.apresentation.register && appState.apresentation.register.title !== '' && <h5 className="mb-5"><TextWithLineBreaks text={appState.apresentation.register.title}></TextWithLineBreaks></h5>}
                        </div>
                        <FormComponent formikRef={formikRef} registerUser={registerUser} onSubmittingStateChange={setIsSubmitting}></FormComponent>
                        <div className="text-center mt-3">
                            <button className="btn primaryColor btn-lg primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally}>
                                {!isSubmitting ? dataApplication.customizacao.form.buttonText : <PageLoaderDots color="#fff" width="25" padding={0} />}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )
        case 2:
            return (
                <motion.div className='default-box pointer' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => handleEndRoulette()}>
                    <div className="photoboothLogo">
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                    </div>
                    <div className={`box-full`}>
                        <div className="mb-4 text-center">
                            {appState.apresentation.endPage.title !== '' && appState.apresentation.endPage.title && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.apresentation.endPage.title}></TextWithLineBreaks></h5></div>}
                            <div className="card mt-4">
                                <div className="card-body py-4 text-center">
                                    <div>
                                        <h5>{appState.rouletteResult.label}</h5>
                                        {appState.roulette.activatePrizes && appState.rouletteResult.prize !== null && appState.rouletteResult.prize !== '' && <p className="mt-3 mb-0">
                                            Prêmio: <span className="opacity-75">{appState.rouletteResult.prize}</span>
                                        </p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cameraButtonContainerEnd">
                            <div className="cardPhotoButton">
                                <h5 className="mb-0">{appState.apresentation.endPage.buttonText}</h5>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )
    }
}

export const WheelComponent = ({ setStep }) => {
    const { appState, isPlaying, dispatch } = useAppState();
    const notyf = useContext(NotyfContext);
    const wheelRef = useRef();
    const wheelInstance = useRef();
    const wheelButton = useRef();
    const spinSound = useRef(new Audio(spinSoundFile));
    const winnerSound = useRef(new Audio(winnerSoundFile));

    function spinRoulette() {
        let totalPossibility = 0
        appState.roulette.items.forEach(element => {
            totalPossibility = totalPossibility + element.possibility
        });
        if (totalPossibility < 99) {
            notyf.open({
                type: "danger",
                message: 'A soma das probabilidades precisar ser de 100',
                ripple: true,
                dismissible: true,
            });
        } else {
            if (wheelButton.current) {
                wheelButton.current.style.display = 'none'
            }
            if (appState.roulette.nextResult !== false) {
                let index = appState.roulette.items.findIndex(value => value.id === appState.roulette.nextResult);
                if (appState.roulette.items[index].quantity <= 0 && appState.roulette.activatePrizes) {
                    wheelInstance.current.spinToItem(index, appState.roulette.duration, appState.roulette.spinToCenter, appState.roulette.numberOfRevolutions, 1, null)
                    return
                }
            }
            const preProcessedItems = preProcess(appState.roulette.items);
            const pulledCharacter = binarySearchGachaPull(preProcessedItems);
            let index = appState.roulette.items.findIndex(value => value.id === pulledCharacter.id);
            wheelInstance.current.spinToItem(index, appState.roulette.duration, appState.roulette.spinToCenter, appState.roulette.numberOfRevolutions, 1, null)
        }
    }

    useEffect(() => {
        spinSound.current.volume = 0;
        winnerSound.current.volume = 0;
        if (isPlaying && appState.apresentation.configs.sound) {
            spinSound.current.volume = 0.7;
            winnerSound.current.volume = 0.7;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying])

    useEffect(() => {
        let newRoulette = { ...appState.roulette, items: [...appState.roulette.items.map(item => ({ ...item }))] };
        if (newRoulette.activatePrizes) {
            newRoulette.items = newRoulette.items.filter(element => element.quantity > 0);
        }
        newRoulette.items.forEach(element => {
            if (element.image && element.image !== '') {
                element.label = ''
            }
        });
        if (wheelRef.current) {
            if (!wheelInstance.current) {
                wheelInstance.current = new Wheel(wheelRef.current, newRoulette);
            } else {
                wheelInstance.current.remove()
                wheelInstance.current = new Wheel(wheelRef.current, newRoulette);

            }
            if (wheelButton.current) {
                wheelButton.current.style.display = 'block'
            }
            wheelInstance.current.onRest = (e) => {
                winnerSound.current.currentTime = 0;
                winnerSound.current.play();
                const { label, prize, id } = appState.roulette.items[e.currentIndex];
                const selectedItem = { label, prize, id };
                if (appState.apresentation.configs.form && appState.apresentation.configs.form === 1) {
                    setStep((lastStep) => lastStep + 1)
                } else {
                    setStep((lastStep) => lastStep + 2)
                }
                postRouletteResult(selectedItem, 0, appState.roulette.activatePrizes)
                dispatch({
                    type: 'setRouletteResult', payload: appState.roulette.items[e.currentIndex]
                });
            };

            wheelInstance.current.onCurrentIndexChange = (e) => {
                spinSound.current.currentTime = 0;
                spinSound.current.play();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.roulette]);

    return (
        <div className="wheel-wrapper" ref={wheelRef}>
            {!appState.roulette.isInteractive && appState.roulette.items && appState.roulette.items.length > 0 &&
                <div ref={wheelButton} className="wheelButton" onClick={(() => spinRoulette())} ><h5 class="mb-0">Clique para rodar a roleta</h5></div>}
        </div>
    )
}

const defaultRoulette = {
    "isInteractive": false,
    "activatePrizes": false,
    "nextResult": false,
    "spinToCenter": false,
    "duration": 3000,
    "numberOfRevolutions": 2,
    "borderColor": "rgba(0,0,0,1)",
    "borderWidth": 1,
    "debug": false,
    "image": "",
    "itemLabelAlign": "right",
    "itemLabelBaselineOffset": 0,
    "itemLabelFont": "sans-serif",
    "itemLabelFontSizeMax": 40,
    "itemLabelRadius": 0.85,
    "itemLabelRadiusMax": 0,
    "itemLabelRotation": 0,
    "itemLabelStrokeColor": "rgba(255,255,255,1)",
    "itemLabelStrokeWidth": 0,
    "lineColor": "rgba(0,0,0,1)",
    "lineWidth": 1,
    "overlayImage": "",
    "pixelRatio": 0,
    "pointerAngle": 0,
    "radius": 1,
    "rotationResistance": -35,
    "rotationSpeedMax": 300,
    "items": [
        {
            "id": "id-fatia01",
            "label": "Fatia01",
            "possibility": 25,
            "prize": "",
            "quantity": 10,
            "backgroundColor": "red",
            "image": null,
            "imageOpacity": 1,
            "imageRadius": 0.5,
            "imageRotation": 1,
            "imageScale": 1,
            "labelColor": "rgba(255, 255, 255, 1)",
            "value": "id-fatia01",
            "weight": 1,
            "new": false
        },
        {
            "id": "id-fatia02",
            "label": "Fatia02",
            "possibility": 25,
            "prize": "",
            "quantity": 10,
            "backgroundColor": "blue",
            "image": null,
            "imageOpacity": 1,
            "imageRadius": 0.5,
            "imageRotation": 1,
            "imageScale": 1,
            "labelColor": "rgba(255, 255, 255, 1)",
            "value": "id-fatia01",
            "weight": 1,
            "new": false
        },
        {
            "id": "id-fatia03",
            "label": "Fatia03",
            "possibility": 25,
            "prize": "",
            "quantity": 10,
            "backgroundColor": "green",
            "image": null,
            "imageOpacity": 1,
            "imageRadius": 0.5,
            "imageRotation": 1,
            "imageScale": 1,
            "labelColor": "rgba(255, 255, 255, 1)",
            "value": "id-fatia01",
            "weight": 1,
            "new": false
        },
        {
            "id": "id-fatia04",
            "label": "Fatia04",
            "possibility": 25,
            "prize": "",
            "quantity": 10,
            "backgroundColor": "yellow",
            "image": null,
            "imageOpacity": 1,
            "imageRadius": 0.5,
            "imageRotation": 1,
            "imageScale": 1,
            "labelColor": "rgba(255, 255, 255, 1)",
            "value": "id-fatia01",
            "weight": 1,
            "new": false
        },
    ]
}

const defaultSegment = {
    "possibility": 0,
    "prize": "",
    "quantity": 0,
    "backgroundColor": "rgba(255,255,255,1)",
    "image": null,
    "imageOpacity": 1,
    "imageRadius": 0.5,
    "imageRotation": 1,
    "imageScale": 1,
    "label": "",
    "labelColor": "rgba(0,0,0,1)",
    "value": null,
    "weight": 1,
    "new": true,
}

function preProcess(items) {
    let cumulative = 0;
    return items.map(props => {
        cumulative += props.possibility;
        return { ...props, cumulativeProbability: cumulative };
    });
}

function binarySearchGachaPull(preProcessedItems) {
    const randomNumber = Math.random() * 100;
    let low = 0;
    let high = preProcessedItems.length - 1;
    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midVal = preProcessedItems[mid];
        if (midVal.cumulativeProbability < randomNumber) {
            low = mid + 1;
        } else if (mid > 0 && preProcessedItems[mid - 1].cumulativeProbability >= randomNumber) {
            high = mid - 1;
        } else {
            return midVal;
        }
    }
    return null; // Fallback if no match found
}

export default RouletteDrawAdmin
