import { useEffect, useState, useRef } from "react"
import timerTick from "../../../assets/sounds/11474222_ticking-analog-timer_by_lucafrancini_preview.mp3"

const Countdown = ({ handleAnswerClick, appState, loadingQuestion, dateUnix, isPlaying }) => {
    const [timeLeft, setTimeLeft] = useState(appState.timer.value)
    const [soundPlayed, setSoundPlayed] = useState(false);
    const audioRef = useRef(new Audio(timerTick));

    useEffect(() => {
        if (!isPlaying) {
            audioRef.current.volume = 0;
        } else {
            audioRef.current.volume = 0.7;
        }
    }, [isPlaying])

    useEffect(() => {
        if (loadingQuestion === false) {
            const intervalId = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft(timeLeft - 1)
                    if (timeLeft === 5 && !soundPlayed && appState.sound) {
                        setSoundPlayed(true)
                        audioRef.current.play();
                    }
                }
            }, 1000)

            if (timeLeft === 0) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                handleAnswerClick(false, 0, null, dateUnix)
            }
            return () => clearInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft])

    return (
        <div className="countdown">{timeLeft}</div>
    )
}

export default Countdown
