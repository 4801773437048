import React, { useEffect } from 'react'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHourglassHalf,
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from './context/AppContext.js'
import { useGlobalState } from '../../hooks/useCustomization';
import { TextWithLineBreaks, SocialMidiaIcons, redirectURL } from "../../components/Functions.js"
import { getRandomInterval } from "../../components/Functions.js"
import { PageLoader } from '../../components/Elements.js'
import { domainConfig } from "../../assets/config"

const PhotoboothAI = () => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, updateData } = useAppState();

    const getData = () => {
        axios.get(`/api/eventos/photoboothai?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&visitante_id=${dataApplication.visitante.visitante_id}`)
            .then(function (response) {
                dispatch({
                    type: 'setResultUser', payload: response.data.message
                });
            }).catch(function (error) {
                dispatch({
                    type: 'setResultUser', payload: false
                });
            })
    }

    useEffect(() => {
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, getRandomInterval(25000, 35000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData])

    if (appState.resultUser === undefined) {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    } else {
        return (
            <BaseLayout>
                <AnimatePresence mode={'wait'} initial={false}>
                    <HandleStatus key={'HandlePrize' + appState.resultUser.status}></HandleStatus>
                </AnimatePresence>
            </BaseLayout>
        )
    }
}

const BaseLayout = ({ children }) => {
    const { dataApplication} = useGlobalState();
    const { appState } = useAppState();

    switch (dataApplication.customizacao.layout.type) {
        default:
        case 1:
            return (
                <div className="layout-01">
                    <div className="logoBox">
                        <div className="logoBoxChildMiddle">
                            <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        </div>
                    </div>
                    <div className="default-box text-center">
                        <div className="box-full">
                            {appState.result.title && <div className="col"><h5 className="mb-3 mx-2"><TextWithLineBreaks text={appState.result.title}></TextWithLineBreaks></h5></div>}
                            {appState.result.description && <div className="col"><p><TextWithLineBreaks text={appState.result.description}></TextWithLineBreaks></p></div>}
                            {children}
                        </div>
                    </div>
                    <div className="footer">
                        {dataApplication.customizacao.layout.footer.type === 'image' ?
                            (
                                <div>
                                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.layout.footer.url} width={dataApplication.customizacao.layout.footer.width} alt={'showFooterImg'}></img>
                                </div>
                            ) : (
                                <div className="baseColorText">
                                    {dataApplication.customizacao.layout.footer.socialMedia.length > 0 && dataApplication.customizacao.layout.footer.socialMedia.map((element, index) => (
                                        <SocialMidiaIcons key={'icons' + index} element={element} index={index} />
                                    ))}
                                </div>
                            )}
                    </div>
                </div>
            )
        case 2:
            return (
                <div className="default-box">
                    <div className="box-full">
                        <div className="logoBox">
                            <div className="logoBoxChildMiddle">
                                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                            </div>
                        </div>
                        <div>
                            {appState.result.title && <div className="col"><h5 className="mb-3 mx-2"><TextWithLineBreaks text={appState.result.title}></TextWithLineBreaks></h5></div>}
                            {appState.result.description && <div className="col"><p><TextWithLineBreaks text={appState.result.description}></TextWithLineBreaks></p></div>}
                            {children}
                        </div>
                    </div>
                </div>
            )
    }
};

const HandleStatus = () => {
    const { appState } = useAppState();

    if (appState.resultUser === false) {
        return (
            <motion.div className="col" initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
                exit={{ opacity: 0 }}>
                <motion.div className="mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <h1>
                        <FontAwesomeIcon className="primaryColorT" icon={faTimesCircle} size={'lg'} />
                    </h1>
                </motion.div>
                <div>
                    <button className="btn primaryColorText primaryColor mt-4">Houve algum problema na criação do seu video, tente novamente</button>
                </div>
            </motion.div>
        )
    }

    switch (appState.resultUser.status) {
        case 'done':
            return (
                <motion.div className="col" initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <motion.div className="mt-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.75 } }}
                        exit={{ opacity: 0 }}>
                        <h1>
                            <FontAwesomeIcon className="primaryColorT" icon={faCheckCircle} size={'lg'} />
                        </h1>
                    </motion.div>
                    <div>
                        <button className="btn primaryColorText primaryColor mt-4" onClick={() => redirectURL(true, appState.resultUser.result_url)}>Download</button>
                    </div>
                </motion.div>
            )
        default:
            return (
                <motion.div className="col" initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <motion.div className="mt-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.75 } }}
                        exit={{ opacity: 0 }}>
                        <h1>
                            <FontAwesomeIcon className="primaryColorT" icon={faHourglassHalf} size={'lg'} />
                        </h1>
                    </motion.div>
                    <div>
                        <button className="btn primaryColorText primaryColor mt-4" disabled>Em processo de criação</button>
                    </div>
                </motion.div>
            )
    }
}

export default PhotoboothAI