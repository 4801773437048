import { createContext, useReducer, useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion"
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import NotyfContext from "../contexts/NotyfContext";
import { useGlobalState } from '../hooks/useCustomization';
import { useTranslation } from "react-i18next";
import { useAppState } from "../apps/09/context/AppContext";
import { domainConfig } from "../assets/config.js"

const INITIALIZE = "INITIALIZE"
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
};

const LoginReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE: {
            return {
                ...state,
                isInitialized: true,
            }
        }
        case SIGN_IN:
            return {
                ...state,
                isInitialized: true,
                isAuthenticated: true,
            };
        case SIGN_OUT:
            return {
                ...state,
                isInitialized: true,
                isAuthenticated: false,
            };

        default:
            return state;
    }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const [state, localDispatch] = useReducer(LoginReducer, initialState);
    const { dataApplication, dispatch } = useGlobalState();
    const { appState } = useAppState();
    const navigate = useNavigate();
    const location = useLocation();
    const notyf = useContext(NotyfContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        const initialize = async () => {
            let encriptID = localStorage.getItem(dataApplication.token) ? JSON.parse(localStorage.getItem(dataApplication.token)) : false
            if (encriptID.encryptedData && encriptID.iv) {
                try {
                    const response = await axios.post(`/api/eventos/loginVisitante`, {
                        "evento_id": dataApplication.evento_id,
                        "customizacao_id": appState.customizacao_id,
                        "visitante_id": encriptID
                    });
                    setTimeout(() => {
                        localStorage.setItem(dataApplication.token, JSON.stringify(response.data.message.encripted));
                        localDispatch({ type: SIGN_IN });
                        dispatch({
                            type: 'setVisitante', payload: response.data.message.data
                        });
                    }, appState.login.loaderTime);
                } catch (err) {
                    setTimeout(() => {
                        localDispatch({ type: INITIALIZE });
                    }, appState.login.loaderTime);
                }
            } else {
                setTimeout(() => {
                    localDispatch({ type: INITIALIZE });
                }, appState.login.loaderTime);
            }
        };
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signIn = async (email, password) => {
        try {
            const response = await axios.post(`/api/eventos/loginVisitante`, {
                "evento_id": dataApplication.evento_id,
                "customizacao_id": appState.customizacao_id
            }, {
                auth: {
                    username: email,
                    password: password,
                },
            });
            dispatch({
                type: 'setVisitante', payload: response.data.message.data
            });
            localStorage.setItem(dataApplication.token, JSON.stringify(response.data.message.encripted));
            localDispatch({ type: SIGN_IN });
            navigate(`/${location.search}`)
        } catch (error) {
            if (error.response) {
                let type = "danger";
                let ripple = true;
                let dismissible = true;
                if (error.response.data["message"] === 1) {
                    notyf.open({
                        type,
                        message: t('t72'),
                        ripple,
                        dismissible,
                    });
                } else if (error.response.data["message"] === 2) {
                    notyf.open({
                        type,
                        message: t('t73'),
                        ripple,
                        dismissible,
                    });
                } else if (error.response.data["message"] === 3) {
                    notyf.open({
                        type,
                        message: t('t64'),
                        ripple,
                        dismissible,
                    });
                } else {
                    throw error.response.data["message"];
                }
            } else {
                throw error.message;
            }
        }
    };

    const signOut = async () => {
        localDispatch({ type: SIGN_OUT });
        localStorage.setItem(dataApplication.token, "");
        navigate(`/login${location.search}`)
    };

    const Loader = () => {
        return (
            <motion.div
                className="loginLoadingContainer"
                key={'loginLoadingContainer'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div>
                    {appState.login.type === 'video' ?
                        <video className="logo" autoPlay loop muted playsInline src={`${domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + appState.login.url}`}></video>
                        :
                        <img src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.login.url} alt="Logo" className="logo"></img>}
                </div>
            </motion.div>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                signIn,
                signOut,
            }}
        >
            <AnimatePresence mode={'wait'} initial={true}>
                {state.isInitialized === true ?
                    (children) : (<Loader />)}
            </AnimatePresence>
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
