import { Helmet } from 'react-helmet-async';
import { domainConfig } from "../../../assets/config.js";

const HelmetStyle = ({ appState }) => {
    
    return (
        <Helmet>
            <style>
                {`
                        .modalPresenter .image-side {
                            flex: ${appState.apresentation.imageSide.value}
                        }
                        .modalPresenter{
                            flex-direction: ${appState.apresentation.imageSide.position === 'start' ? 'row' : 'row-reverse'}
                        }
                        .modalPresenter .text-side{
                            flex: ${appState.apresentation.textSide.value}
                        }
                        .modalPresenter .firstTitle{
                            font-size: ${appState.apresentation.textSide.textSizeH5}!important;
                            color: ${appState.apresentation.textSide.textColor}!important;
                        }
                        .loginLoadingContainer {
                            background-color: ${appState.login.color}
                        }
                        html {
                        --loadingLogoWidth:${appState.login.loadingLogoWidth}!important;
                        }
                        `}
            </style>
            {appState.correctAnswer ? (
                <style>
                    {`
                        html {
                            --incorrectAnswer: ${appState.incorrectAnswer}!important;
                            --correctAnswer: ${appState.correctAnswer}!important;
                          }
                        `}
                </style>
            ) : ("")}
            {appState.apresentation.imageSide.background && appState.apresentation.imageSide.background !== "" ? (
                <style>
                    {`
                        .modalPresenter .image-side {
                            background-image: url("${domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.imageSide.background}")
                        }
                        `}
                </style>
            ) : ("")}
            {appState.apresentation.textSide.background && appState.apresentation.textSide.background !== "" ? (
                <style>
                    {`
                        .modalPresenter .text-side {
                            background-color: ${appState.apresentation.textSide.background}!important;
                        }
                        `}
                </style>
            ) : ("")}
        </Helmet>
    )
};

export default HelmetStyle;