import React, { useEffect, useState, useRef } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import startSound from "../../../assets/sounds/49999900_countdown-start-game_by_garnavutka-nonexclusive_preview.mp3"

const StartAnimation = ({ timer, value }) => {
  const { isPlaying, appState, setStart } = useAppState();
  const [startCountdown, setStartCountdown] = useState(undefined)
  const [number, setNumber] = useState(timer)
  const audioRef = useRef(new Audio(startSound));

  function handleStartCountDown() {
    setStartCountdown(true)
  }

  useEffect(() => {
    if (!isPlaying) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 0.7;
    }
  }, [isPlaying])

  useEffect(() => {
    if (audioRef.current !== undefined && appState.sound === true) {
      audioRef.current.play().then(() => {
        setStartCountdown(true)
      }).catch(error => {
        setStartCountdown(false)
      });
    } else {
      setStartCountdown(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountdown])

  useEffect(() => {
    if (startCountdown) {
      const intervalId = setInterval(() => {
        if (number > 1) {
          setNumber(number - 1)
        }
      }, 1000)

      if (number === 1) {
        setTimeout(() => {
          setStart(value)
        }, 1250);
      }
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountdown, number])

  return (
    <motion.div
      className="default-box"
      initial={{ scale: 0.3 }}
      animate={{
        scale: [0.3, 1, 0.9],
        transition: {
          duration: 0.75,
          ease: "easeInOut",
        }
      }}
      exit={{ opacity: 0 }}
    >
      <div className={`quizzAnimation`}>
        {startCountdown !== undefined && <AnimatePresence mode={'wait'}>
          {startCountdown ? <motion.div
            key={number}
            initial={{ scale: 0.75 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0, scale: 0.75 }}
            transition={{ duration: 0.5 }}
          >
            {number}
          </motion.div>
            :
            <motion.div className="vertically-centered" onClick={() => handleStartCountDown()}
              key={'startHandler'}
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1 }}>
              <FontAwesomeIcon icon={faCirclePlay} className="quizzAnimationbtn" />
            </motion.div>}
        </AnimatePresence>}
      </div>
    </motion.div>
  )
}

export default StartAnimation
