import React, { useContext } from 'react'
import { Dropdown, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faDisplay,
    faImages
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import NotyfContext from "../../../contexts/NotyfContext.js";
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { handleShareLink } from "../../../components/Functions.js"
import Fullscreen from '../../../components/FullScreen.js'
import { domainConfig } from "../../../assets/config.js"
import { PhotoboothModal, PhotoboothGalleryModal } from '../Presenter.js'

export const NavbarPresenter = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { showPresentation, setShowPresentation, showPresentationGallery, setShowPresentationGallery } = useAppState();
    const notyf = useContext(NotyfContext);

    return (
        <>
            <div className="horizontally-centered">
                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo d-inline-flex"></img>
            </div>
            <div className="horizontally-centered">
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Modo de apresentação</Tooltip>}
                >
                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowPresentation(true)}>
                        <FontAwesomeIcon icon={faDisplay} className="primaryColorText" />
                    </button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Modo Galeria</Tooltip>}
                >
                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowPresentationGallery(true)}>
                        <FontAwesomeIcon icon={faImages} className="primaryColorText" />
                    </button>
                </OverlayTrigger>
                <Dropdown className="d-inline" align="end">
                    <Dropdown.Toggle className="btn primaryColor primaryColorText" variant="" id="dropdown-autoclose-true">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Fullscreen />
                        <Dropdown.Item onClick={(() => handleShareLink(domainConfig.aplicativos + "/?token=" + dataApplication.token, notyf))}>Compartilhar link</Dropdown.Item>
                        <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal show={showPresentationGallery} fullscreen={true}>
                <div className="modalPresenter d-flex justify-content-center overflow-hidden">
                    <PhotoboothGalleryModal></PhotoboothGalleryModal>
                </div>
            </Modal>
            <Modal show={showPresentation} fullscreen={true}>
                <div className="modalPresenter d-flex justify-content-center overflow-hidden text-side">
                    <PhotoboothModal></PhotoboothModal>
                </div>
            </Modal>
        </>
    )
}