import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SocialMidiaIcons } from "../components/Functions.js"
import { useGlobalState } from '../hooks/useCustomization.js';
import { domainConfig } from "../assets/config.js"

const BaseLayout = ({ children, logoBox = true, footerBox = true, box = "default-box", showBackButton = false, backButtonHandler = undefined, showForwardButton = false, forwardButtonHandler = undefined }) => {
    const { dataApplication } = useGlobalState();

    switch (dataApplication.customizacao.layout.type) {
        default:
        case 1:
            return (
                <motion.div
                    className="layout-01"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    {logoBox && <div className="logoBox">
                        <div className={showBackButton ? '' : 'logoBoxHideButton'}>
                            <div className="backButton" onClick={backButtonHandler}> {showBackButton && <FontAwesomeIcon icon={faArrowLeft} size={'lg'} />}</div>
                        </div>
                        <div className="logoBoxChildMiddle">
                            <img className="App-logo" src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo"></img>
                        </div>
                        <div className={showForwardButton ? '' : 'logoBoxHideButton'}>
                            <div className="backButton" onClick={forwardButtonHandler}> {showForwardButton && <FontAwesomeIcon icon={faArrowRight} size={'lg'} />}</div>
                        </div>
                    </div>}
                    <div className={box}>
                        <div className={`box-full`}>
                            {children}
                        </div>
                    </div>
                    {footerBox && <div className="footer">
                        {dataApplication.customizacao.layout.footer.type === 'image' ?
                            (
                                <div>
                                    <img className="App-logo" src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.layout.footer.url} width={dataApplication.customizacao.layout.footer.width} alt={'showFooterImg'}></img>
                                </div>
                            ) : (
                                <div className="baseColorText">
                                    {dataApplication.customizacao.layout.footer.socialMedia.length > 0 && dataApplication.customizacao.layout.footer.socialMedia.map((element, index) => (
                                        <SocialMidiaIcons key={'icons' + index} element={element} index={index} />
                                    ))}
                                </div>
                            )}
                    </div>}
                </motion.div>
            )
        case 2:
            return (
                <motion.div
                    className={box}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <div className={`box-full`}>
                        {logoBox && <div className="logoBox">
                            <div className={showBackButton ? '' : 'logoBoxHideButton'}>
                                <div className="backButton" onClick={backButtonHandler}> {showBackButton && <FontAwesomeIcon icon={faArrowLeft} size={'lg'} />}</div>
                            </div>
                            <div className="logoBoxChildMiddle">
                                <img className="App-logo" src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo"></img>
                            </div>
                            <div className={showForwardButton ? '' : 'logoBoxHideButton'}>
                                <div className="backButton" onClick={forwardButtonHandler}> {showForwardButton && <FontAwesomeIcon icon={faArrowRight} size={'lg'} />}</div>
                            </div>
                        </div>}
                        {children}
                    </div>
                </motion.div>
            )
    }
};

export default BaseLayout