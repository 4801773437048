import React, { useReducer, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { formatInTimeZone } from "date-fns-tz";
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"
import NotyfContext from "../../../contexts/NotyfContext.js";
import '../assets/style.css'
import "../i18n";

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setRanking':
      return {
        ...state,
        ranking: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        context: action.payload,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication, setLoading } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateData, setUpdateData] = useState(0)
  const [registerParticipants, setRegisterParticipants] = useState(false)
  const [showPresentation, setShowPresentation] = useState(false)
  const emailField = getEmailField()
  const notyf = useContext(NotyfContext);

  function getEmailField() {
    let id = false
    dataApplication.customizacao.form.fields.forEach((element, key) => {
      if (element.validation === 'email') {
        id = element
      }
    });
    return id
  }

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        console.log('updateData')
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('registerParticipants', (response) => {
        if (response === true) {
          setRegisterParticipants(false)
          notyf.open({
            type: "success",
            message: "Participante(s) adicionado(s) com sucesso",
            ripple: true,
            dismissible: true,
          });
        } else {
          notyf.open({
            type: "danger",
            message: 'Houve um erro, tente novamente',
            ripple: true,
            dismissible: true,
          });
        }
        setLoading(false)
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('editParticipant', (response) => {
        if (response === true) {
          notyf.open({
            type: "success",
            message: 'Participante editado com sucesso',
            ripple: true,
            dismissible: true,
          });
        } else {
          notyf.open({
            type: "danger",
            message: 'Houve um erro, tente novamente',
            ripple: true,
            dismissible: true,
          });
        }
        setLoading(false)
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('deleteParticipant', (response) => {
        if (response === true) {
          notyf.open({
            type: "success",
            message: "Participante removido com sucesso",
            ripple: true,
            dismissible: true,
          });
        } else {
          notyf.open({
            type: "danger",
            message: 'Houve um erro, tente novamente',
            ripple: true,
            dismissible: true,
          });
        }
        setLoading(false)
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, emailField)
        dispatch({ type: 'setRanking', payload: data.ranking });
        dispatch({ type: 'setParticipants', payload: data.participants });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showPresentation, updateData, setUpdateData, setShowPresentation, registerParticipants, setRegisterParticipants
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = (type) => {
  socket.emit('getData', type);
};

const registerParticipants = (props, token) => {
  socket.emit('registerParticipants', props, token);
};

const editParticipant = (data, email, visitante_id) => {
  socket.emit('editParticipant', data, email, visitante_id);
};

const deleteParticipant = (type) => {
  socket.emit('deleteParticipant', type);
};

const updateData = () => {
  socket.emit('updateData');
};
export {
  AppContext,
  GameProvider,
  getData,
  updateData,
  registerParticipants,
  editParticipant,
  deleteParticipant
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, emailField) {
  let newHistory = []
  participants.forEach(participant => {
    try {
      participant.data_ultimo_contato = formatInTimeZone(participant.data_ultimo_contato, "America/Sao_Paulo", "dd/MM/yyyy HH:mm:ss");
    } catch (error) {

    }
    try {
      participant.data_visitacao = formatInTimeZone(participant.data_visitacao, "America/Sao_Paulo", "dd/MM/yyyy HH:mm:ss");
    } catch (error) {

    }
    try {
      participant.data_ultimo_acesso = formatInTimeZone(participant.data_ultimo_acesso, "America/Sao_Paulo", "dd/MM/yyyy HH:mm:ss");
    } catch (error) {

    }
    participant.json = JSON.parse(participant.json)
    participant.participantDetails = participant.json;
    participant.json = typeof participant.json === 'object' && participant.json !== null ? participant.json : {}
    const transformedDetails = {};
    if (Object.keys(participant.participantDetails).length > 1) {
      for (const key in participant.participantDetails) {
        const detail = participant.participantDetails[key];
        if (detail.value === false) {
          detail.value = 'Não assinalado'
        } else if (detail.value === true) {
          detail.value = 'Assinalado'
        }
        transformedDetails[detail.label] = detail.value;
      }
      Object.assign(participant, transformedDetails);
    } else {
      transformedDetails[emailField.label] = participant.email
      Object.assign(participant, transformedDetails);
      participant.participantDetails[emailField.inputID] = { 'label': emailField.label, 'value': participant.email }
    }
    if (participant.status === 1) {
      newHistory.push(participant)
    }
  });

  return { 'participants': participants, 'ranking': newHistory };
}
