import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext'
import { PageLoader } from '../../../components/Elements.js'
import { domainConfig } from "../../../assets/config.js"
import first from "../assets/img/1_place_icon.png"
import second from "../assets/img/2_place_icon.png"
import third from "../assets/img/3_place_icon.png"
import photoFrame2 from "../assets/img/Frame2.png"

const Ranking = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const [ranking, setUserRanking] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        axios
            .get(
                `/api/eventos/networkingParticipants/${dataApplication.visitante.visitante_id}?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}`
            )
            .then(function (response) {
                setTimeout(() => {
                    setUserRanking(response.data.message)
                }, 250);
            })
            .catch(function (error) {
                console.log(error)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!ranking) {
        return (
            <motion.div className="pageLoaderContainer" key={'pageLoader'} initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width="75" />
            </motion.div>
        )
    }

    return (
        <motion.div className="default-box justify-content-start" initial={{ opacity: 0 }} key={'rankingContainer'}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}>
            <div className="box-full">
                <div className="topNavCard">
                    <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => navigate(`/${location.search}`))}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="pageTitle primaryColor">{t('t62')}</div>
                </div>
                <div className="contactCard text-start mb-3">
                    <p className="mb-1">{t('t89')}: <span>{dataApplication.visitante.ranking}</span></p>
                    <p className="mb-1">{t('t57')}: <span>{dataApplication.visitante.total_contatos}</span></p>
                    <p className="mb-1">{t('t90')}: <span>{dataApplication.visitante.data_ultimo_contato}</span></p>
                </div>
                <div className="ranking-group">
                    {ranking.length > 0 ? (
                        <>
                            {ranking && ranking.map((props, index) => {
                                index++
                                return (
                                    <div className="rankingCard primaryColor" key={'rankingC' + index}>
                                        {(() => {
                                            switch (index) {
                                                case 1:
                                                    return <img src={first} alt=""></img>
                                                case 2:
                                                    return <img src={second} alt=""></img>
                                                case 3:
                                                    return <img src={third} alt=""></img>
                                                default:
                                                    return ""
                                            }
                                        })()}
                                        <div>
                                            <img className="avatarProfile3 my-2 ms-3" src={props.IsAdded === 1 || props.visitante_id === dataApplication.visitante.visitante_id ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + props.img : photoFrame2} alt="" />
                                        </div>
                                        <div className="rankingCardChildren">
                                            {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                if (field && props.json[field.inputID]) {
                                                    return (
                                                        <p key={'contactsParticipantDetails' + index} className={index < 4 ? "lineH mb-2 rankingText" : "lineH mb-0 rankingText"}>{props.json[field.inputID].value}</p>
                                                    );
                                                }
                                                return ""
                                            })}
                                            <div>
                                                <small className="mb-0">{props.total_contatos} {t('t57')}</small>
                                                <p className="mb-0">{index < 4 ? ("") : (index + '#')}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </>
                    ) : (
                        <div className="ranking-group pt-2">
                            <p>Nenhum contato encontrado</p>
                        </div>
                    )}
                </div>
            </div>
        </motion.div >
    )
}

export default Ranking