import { Helmet } from 'react-helmet-async';

const HelmetStyle = ({ appState }) => {

    return (
        <Helmet>
            <style>
               
            </style>
        </Helmet>
    )
};

export default HelmetStyle;