

import React, { useState, useEffect, useMemo, useContext } from 'react'
import { formatInTimeZone } from "date-fns-tz";
import ReactWordcloud from 'react-wordcloud';
import { Tooltip, OverlayTrigger, Row, Col, Pagination, Form } from 'react-bootstrap'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faThumbsDown,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import NotyfContext from "../../contexts/NotyfContext.js";
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, updateData } from "./context/AppContext.js"
import { findHighestValue } from "../../components/Functions.js"
import QRCode from "react-qr-code";
import { TextWithLineBreaks, convertDate } from "../../components/Functions.js"
import { PageLoader } from "../../components/Elements"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const WordCloudAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateMessages } = useAppState();
    const [pageIndex, setPageIndex] = useState(0);
    const [filter, setFilter] = useState('approved')

    const setFilterHandler = (value) => {
        setFilter(value)
        setPageIndex(0)
    }

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }

        if (appState.apresentation.textSide.fontFamily && appState.apresentation.textSide.fontFamily !== dataApplication.customizacao.fontFamily) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `https://fonts.googleapis.com/css2?family=${appState.apresentation.textSide.fontFamily.replace(/ /g, '+')}&display=swap`;
            document.head.appendChild(link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages, appState.isConnected]);

    if (appState.messages) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="btn-group mb-3 presenterTab" role="group" aria-label="Large button group">
                                    <button type="button" onClick={() => setFilterHandler('notApproved')} className={`btn ${filter === 'notApproved' ? 'active' : ''}`}>Reprovado ({appState.messages.notApproved.length})</button>
                                    <button type="button" onClick={() => setFilterHandler('waiting')} className={`btn ${filter === 'waiting' ? 'active' : ''}`}>Esperando aprovação ({appState.messages.waiting.length})</button>
                                    <button type="button" onClick={() => setFilterHandler('approved')} className={`btn ${filter === 'approved' ? 'active' : ''}`}>Aprovado ({appState.messages.approved.length})</button>
                                </div>
                                <div>
                                    <AnimatePresence mode="wait">
                                        <Questions key={filter + pageIndex} filter={filter} pageIndex={pageIndex} setPageIndex={setPageIndex}></Questions>
                                    </AnimatePresence>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 775px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Questions = ({ filter, pageIndex, setPageIndex }) => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState();

    // Pagination state
    const [pageSize, setPageSize] = useState(10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const messages = appState.messages[filter] || [];

    const pageCount = useMemo(() => Math.ceil(messages.length / pageSize), [messages.length, pageSize]);

    const currentPageMessages = useMemo(() => {
        const start = pageIndex * pageSize;
        return messages.slice(start, start + pageSize);
    }, [pageIndex, pageSize, messages]);

    const canPreviousPage = pageIndex > 0;
    const canNextPage = pageIndex < pageCount - 1;

    // Pagination handlers
    const gotoPage = (page) => setPageIndex(page);
    const previousPage = () => setPageIndex((old) => Math.max(old - 1, 0));
    const nextPage = () => setPageIndex((old) => Math.min(old + 1, pageCount - 1));

    const selectCardType = (data) => {
        if (data.aprovado === 2) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Aprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 1, data.mensagem_id, setLoading)} >
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsUp} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        } else if (data.aprovado === 0) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Aprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', data.aprovado === 1, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsUp} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Reprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 2, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsDown} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        }
    }

    return (
        <>
            <motion.div className="row" key={filter} initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.25 } }}
                exit={{ opacity: 0 }}>
                {currentPageMessages && currentPageMessages.length > 0 ? (
                    <AnimatePresence layout LayoutRoot>
                        {currentPageMessages && Object.keys(currentPageMessages).map(key => (
                            <motion.div key={'cardContainer' + currentPageMessages[key].mensagem_id} className="col-6 mb-3"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                layout>
                                <div className="cardContainer">
                                    <div className="cardMessage">
                                        <div className="cardTypeDate">{convertDate(currentPageMessages[key].data)}</div>
                                        {selectCardType(currentPageMessages[key])}
                                        <p className="mt-4 pt-2">{currentPageMessages[key].message}</p>
                                        {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                            if (currentPageMessages[key].participantDetails && currentPageMessages[key].participantDetails[field.inputID]) {
                                                const isLast = index === dataApplication.customizacao.form.fieldsToShow.length - 1;
                                                return (
                                                    <span className={`badge primaryColor primaryColorText fw-normal mb-2 ${isLast ? '' : 'me-2'}`} key={'field' + index}>
                                                        {currentPageMessages[key].participantDetails[field.inputID].value}
                                                    </span>
                                                );
                                            }
                                            return null; // Handle cases where the field is not found (optional)
                                        })}
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                ) : (
                    <p className="mt-5">Não há informações disponíveis</p>
                )}
            </motion.div>
            <Row className="text-start">
                <Col md="6">
                    <span className="mx-2">
                        Página{" "}
                        <span>
                            {pageIndex + 1} de {pageCount}
                        </span>
                    </span>
                    <span className="ms-3 me-2">Mostrar:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md="6">
                    <Pagination className="float-end">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
        </>
    )
}

export const WordCloudModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowPresentation } = useAppState();
    const [valueMedia, setValueMedia] = useState({ media: 50 })
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        notyf.open({
            type: "success",
            message: "Para fechar o modal basta utilizar o 'ESC'",
            ripple: true,
            duration: 5000,
            dismissible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowPresentation(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = {
        enableTooltip: true,
        deterministic: false,
        fontFamily: appState.apresentation.textSide.fontFamily,
        fontSizes: [appState.apresentation.textSide.fontSizeMin, appState.apresentation.textSide.fontSizeMax],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };

    const callbacks = {
        getWordColor: (word) => (word.value > valueMedia ? dataApplication.customizacao.primaryColor : dataApplication.customizacao.baseColorText),
        getWordTooltip: (word) =>
            `A palavra "${word.text}" aparece ${word.value} vezes.`,
    };

    const allWords = useMemo(() => {
        return appState.messages.all.wordCloud
    }, [appState.messages.all.wordCloud]);

    useEffect(() => {
        setValueMedia(findHighestValue(appState.messages.all.wordCloud) / 2)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && <div className="modalQRCodeShare">
                <div>
                    {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                    <QRCode className="bg-white p-3 qrcodeContainer" value={appState.apresentation.showQRCode.link !== '' ? appState.apresentation.showQRCode.link : domainConfig.aplicativos + '/?token=' + dataApplication.token} />
                </div>
            </div>}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                    {appState.apresentation.title.status && <h5 className="my-5"><TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks></h5>}
                </div>
            </div>
            <div className="wordcloud-container">
                {allWords ? (
                    <ReactWordcloud
                        options={options}
                        callbacks={callbacks}
                        words={allWords}
                    />) : (
                    <div className="swiper-noSlide">
                        <h5>Não foi encontrado nenhum resultado</h5>
                    </div>
                )
                }
            </div>
        </>
    )
}

const toggleMessageStatus = (e, type, value, mensagem_id, setLoading) => {
    if (setLoading) {
        setLoading(true)
    }
    e.preventDefault();
    e.stopPropagation();
    let updateDataJson = {};
    updateDataJson[type] = value
    if (type === 'lido') {
        if (value === 1) {
            const dateX = new Date();
            const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
            updateDataJson['data_lido'] = now
        } else {
            updateDataJson['data_lido'] = null
        }

    }
    axios.put(`/api/eventos/messagesDisplay/${mensagem_id}`, updateDataJson)
        .then(function (response) {
            updateData()
        })
        .catch(function (error) {
            console.log(error);
        });
};

export default WordCloudAdmin