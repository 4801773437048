import React, { useEffect, useState, useContext, useRef } from 'react'
import { Row, Form, FloatingLabel, Col, Modal } from 'react-bootstrap'
import { AnimatePresence, motion } from "framer-motion"
import { useSwipeable } from 'react-swipeable';
import { Formik } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faArrowLeft, faCamera } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import * as Yup from "yup"
import NotyfContext from "../../../contexts/NotyfContext";
import useAuth from "../../../hooks/useAuth";
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext'
import { useTranslation } from "react-i18next";
import tutorial01 from "../assets/img/ilustração1.png"
import tutorial02 from "../assets/img/ilustração2.png"
import { domainConfig } from "../../../assets/config"
import { redirectURL, dataURItoBlob } from '../../../components/Functions';
import photoFrame from "../assets/img/photo-frame.png"
import { FormComponent } from "../../../Register"

const Login = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState()
    const json = localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id) ? JSON.parse(localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id)) : { 'language': 'pt', 'tutorial': true }
    const [type, setType] = useState((json.tutorial === true) ? 'tutorial' : 'login')

    return (
        <AnimatePresence mode="wait">
            <LoginHandler key={type} type={type} setType={setType} json={json}></LoginHandler>
        </AnimatePresence>
    )
}

const LoginHandler = ({ type, setType, json }) => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState()
    const [tutorial, setTutorial] = useState(1)
    const [isExternalSubmitting, setExternalSubmitting] = useState(false);
    const [language, setLanguage] = useState(json.language ? json.language : 'pt')
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)
    const visitanteID = useRef(0)
    const { t, i18n } = useTranslation();
    const notyf = useContext(NotyfContext);

    function handleLanguage(lg) {
        setLanguage(lg)
        i18n.changeLanguage(lg)
        swipeLeft()
    }

    function swipeLeft() {
        if (tutorial < 3) {
            setTutorial((prevCount) => prevCount + 1)
        }
    }

    function swipeRight() {
        if (tutorial > 1) {
            setTutorial((prevCount) => prevCount - 1)
        }
    }

    function handleEndTutorial() {
        localStorage.setItem(dataApplication.token + '&customization_id=' + appState.customizacao_id, JSON.stringify({ 'tutorial': false, 'language': language }));
        setType('login')
    }

    function handleRegister() {
        setType('register')
    }

    const formikRef = useRef(null);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => swipeLeft(),
        onSwipedRight: () => swipeRight(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    function registerUser(values, actions) {
        if (!isExternalSubmitting) {
            if (visitanteID.current === 0) {
                notyf.open({
                    type: "danger",
                    message: t('t23'),
                    ripple: true,
                    dismissible: true,
                });
                return
            }
            if (selectedFile === undefined) {
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: t('t24'),
                    ripple: true,
                    dismissible: true,
                });
                return
            }
            setLoading(true)
            setExternalSubmitting(true)
            let axiosJson = {
                evento_title: dataApplication.titulo,
                evento_id: dataApplication.evento_id,
                customizacao_id: appState.customizacao_id,
                visitante_id: visitanteID.current,
                subject: appState.email.registerAccount.subject,
                html: appState.email.registerAccount.html,
                email: "",
                json: {},
            }
            if (values.autorization) {
                delete values.autorization
            }
            Object.entries(values).forEach(entry => {
                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                if (field) {
                    if (field.validation === 'email') {
                        axiosJson.email = entry[1]
                    }
                    axiosJson.json[entry[0]] = { 'label': field.label, 'value': entry[1] }
                }
            })
            axiosJson.json = JSON.stringify(axiosJson.json)
            const formData = new FormData();
            formData.append("file", selectedFile);
            axios
                .post(`/api/upload/customizacao/${appState.customizacao_id}`, formData)
                .then((responseX) => {
                    if (responseX.data.success === true) {
                        axiosJson.img = responseX.data.message
                        axios.put(`/api/eventos/registerData`, axiosJson).then(async function (response) {
                            actions.setSubmitting(false)
                            setExternalSubmitting(false)
                            setLoading(false)
                            setType('login')
                            notyf.open({
                                type: "success",
                                message: t('t84'),
                                ripple: true,
                                dismissible: true,
                            });
                        }).catch(function (error) {
                            notyf.open({
                                type: "danger",
                                message: t('t23'),
                                ripple: true,
                                dismissible: true,
                            });
                        })
                    } else {
                        actions.setSubmitting(false)
                        setExternalSubmitting(false)
                        setLoading(false)
                        notyf.open({
                            type: "danger",
                            message: t('t23'),
                            ripple: true,
                            dismissible: true,
                        });
                    }
                })
                .catch((error) => {
                    actions.setSubmitting(false)
                    setExternalSubmitting(false)
                    setLoading(false)
                    notyf.open({
                        type: "danger",
                        message: t('t23'),
                        ripple: true,
                        dismissible: true,
                    });
                });
        }
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])


    useEffect(() => {
        i18n.changeLanguage(language)
        if (!type) {
            if (json.tutorial) {
                setType('tutorial')
            } else {
                setType('login')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    switch (type) {
        case 'tutorial':
            return (
                <motion.div {...handlers} className="default-box"
                    initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="box-full">
                        <AnimatePresence mode="wait">
                            {(() => {
                                switch (tutorial) {
                                    case 1:
                                        return (
                                            <motion.div key={'tutorial1'} initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}>
                                                <Row className="row my-5">
                                                    <h3 className="baseColorText pb-5">{t("t1")}:</h3>
                                                    <Col sm={12}>
                                                        <button className={`btn btn-large ${language === 'pt' ? ("primaryColor primaryColorText") : ("primaryColor-outline")}`} onClick={() => handleLanguage('pt')}>{t('t2')}</button>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="languageSeparator"></div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <button className={`btn btn-large ${language === 'en' ? ("primaryColor primaryColorText") : ("primaryColor-outline")}`} onClick={() => handleLanguage('en')}>{t('t3')}</button>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <div className="languageSeparator"></div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <button className={`btn btn-large ${language === 'es' ? ("primaryColor primaryColorText") : ("primaryColor-outline")}`} onClick={() => handleLanguage('es')}>{t('t4')}</button>
                                                    </Col>
                                                </Row>
                                                <div className='tutorialSlideController pt-4'>
                                                    <div className={tutorial === 1 ? "" : "opacity-50"} onClick={(() => setTutorial(1))}></div>
                                                    <div className={tutorial === 2 ? "" : "opacity-50"} onClick={(() => setTutorial(2))}></div>
                                                    <div className={tutorial === 3 ? "" : "opacity-50"} onClick={(() => setTutorial(3))}></div>
                                                </div>
                                            </motion.div>
                                        )
                                    case 2:
                                        return (
                                            <motion.div key={'tutorial2'} initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}>
                                                <div className="my-auto">
                                                    <img className="pb-5" src={tutorial01} alt=""></img>
                                                    <h3 className="primaryColorT">{t('t5')}<br></br>{t('t6')}.</h3>
                                                    <p className="mt-4 mb-0 fs-16 baseColorText">{t('t7')}<br></br>{t('t8')}!</p>
                                                </div>
                                                <div>
                                                    <p className="tutorialSkip mt-5 primaryColorT" onClick={(() => handleEndTutorial())}>{t('t9')}</p>
                                                    <div className='tutorialSlideController pt-4'>
                                                        <div className={tutorial === 1 ? "" : "opacity-50"} onClick={(() => setTutorial(1))}></div>
                                                        <div className={tutorial === 2 ? "" : "opacity-50"} onClick={(() => setTutorial(2))}></div>
                                                        <div className={tutorial === 3 ? "" : "opacity-50"} onClick={(() => setTutorial(3))}></div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        )
                                    case 3:
                                        return (
                                            <motion.div key={'tutorial3'} initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}>
                                                <div className="my-auto">
                                                    <img className="pb-5" src={tutorial02} alt=""></img>
                                                    <h3 className="primaryColorT">{t('t10')}<br></br>{t('t11')}</h3>
                                                    <p className="mt-4 mb-0 fs-16 baseColorText">{t('t12')}<br></br>{t('t13')}</p>
                                                </div>
                                                <button className="btn btn-large primaryColor primaryColorText mt-5 mb-2" onClick={(() => handleEndTutorial())}>{t('t14')}</button>
                                                <div className='tutorialSlideController pt-4'>
                                                    <div className={tutorial === 1 ? "" : "opacity-50"} onClick={(() => setTutorial(1))}></div>
                                                    <div className={tutorial === 2 ? "" : "opacity-50"} onClick={(() => setTutorial(2))}></div>
                                                    <div className={tutorial === 3 ? "" : "opacity-50"} onClick={(() => setTutorial(3))}></div>
                                                </div>
                                            </motion.div>)
                                    default:
                                        return ""
                                }
                            })()}
                        </AnimatePresence>
                    </div>
                </motion.div>
            )
        case 'login':
            return (
                <motion.div className="layout-01 p-0" initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <div className="default-box align-items-start text-center p-0">
                        <div className="logoBox">
                            <div className="logoBoxChildMiddle">
                                <img className="logoLogin" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.login.banner} alt="logo-login"></img>
                            </div>
                        </div>
                        <div className="my-auto px-5 py-4">
                            <div className="text-start">
                                <h3 className="baseColorText pb-3">{t('t15')}</h3>
                            </div>
                            <LoginForm></LoginForm>
                        </div>
                        <div className="p-4 mx-auto">
                            <p className="primaryColorT pointer" onClick={(() => redirectURL(true, 'https://flexinterativa.com.br/politica-privacidade/'))}>{t('t16')}</p>
                            <p className="baseColorText mb-0">{t('t17')}?</p>
                            <p className="primaryColorT pointer mb-0" onClick={(() => handleRegister())}>{t('t18')}</p>
                        </div>
                    </div>
                </motion.div>
            )

        case 'register':
            return (
                <motion.div className="default-box justify-content-start"
                    initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="box-full">
                        <div className="topNavCard">
                            <div className="backIconSize iconSize primaryColor primaryColorText btn" onClick={(() => setType('login'))}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </div>
                            <div className="pageTitle primaryColor">{t('t27')}</div>
                        </div>
                        <div className="col-md-12 text-center mb-3">
                            <label
                                className="position-relative pointer"
                                htmlFor="my-file">
                                <img className="avatarProfileRegister" src={!preview ? photoFrame : preview} alt="" />
                                <div className="cameraIcon">
                                    <FontAwesomeIcon icon={faCamera} />
                                </div>
                            </label>
                            <input
                                accept="image/*"
                                className="d-none"
                                type="file"
                                id="my-file"
                                onChange={(e) => {
                                    onSelectFile(e, setSelectedFile, notyf, t)
                                }}
                            />
                        </div>
                        <FormComponent formikRef={formikRef} registerUser={registerUser} customizacao_id={appState.customizacao_id} t={t} visitanteID={visitanteID}></FormComponent>
                        <div className="text-center mt-3">
                            <button className="btn primaryColor primaryColorText" type="submit" disabled={isExternalSubmitting} onClick={submitFormExternally} >
                                {dataApplication.customizacao.form.buttonText}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )

        // eslint-disable-next-line no-fallthrough
        default:
            return ("")
    }
}

export const onSelectFile = (e, setSelectedFile, notyf, t) => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }
    const file = e.target.files[0];
    const fileType = e.target.files[0].type;
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (e.target.files[0].errors) {
        notyf.open({
            type: "danger",
            message: t('t23'),
            ripple: true,
            dismissible: true,
        });
    } else if (!validImageTypes.includes(fileType)) {
        notyf.open({
            type: "danger",
            message: t('t25'),
            ripple: true,
            dismissible: true,
        });
    } else {
        var reader = new FileReader();
        reader.onload = function (e) {
            var img = document.createElement("img");
            img.src = e.target.result;
            img.onload = function (event) {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 500;
                var MAX_HEIGHT = 500;
                var width = img.width;
                var height = img.height;
                // Add the resizing logic
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                //Specify the resizing result
                canvas.width = width;
                canvas.height = height;
                ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                let dataurl = canvas.toDataURL(fileType);
                const blob = dataURItoBlob(dataurl);
                const resultFile = new File([blob], file.name, { type: fileType });
                setSelectedFile(resultFile)
            }
        };
        reader.readAsDataURL(file);
    }
}

export const LoginForm = () => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState()
    const { signIn } = useAuth();
    const notyf = useContext(NotyfContext);
    const [passwordShown, setPasswordShown] = useState(false);
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [isExternalSubmitting, setExternalSubmitting] = useState(false);

    const schemaRegister = Yup.object().shape({
        email: Yup.string()
            .required(t('t43'))
            .email(t('t48'))
    })

    return (
        <div>
            <Formik
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        await signIn(values.email, values.senha);
                    } catch (error) {
                        notyf.open({
                            type: "danger",
                            message: error,
                            ripple: true,
                            dismissible: true,
                        });
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    email: "",
                    senha: "",
                }}
                enableReinitialize
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="text-start">
                            <FloatingLabel className="loginInput mb-1" controlId="email" label="Email">
                                <Form.Control
                                    type="email"
                                    placeholder={t('t19')}
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                            <FloatingLabel className="loginInput mt-1" controlId="senha" label="Senha">
                                <Form.Control
                                    type={passwordShown ? "text" : "password"}
                                    name="senha"
                                    className="pe-5"
                                    placeholder={t('t20')}
                                    value={values.senha}
                                    onChange={handleChange}
                                />
                                <div className="input-group-text" id="showPassword" onClick={(() => setPasswordShown(!passwordShown))}>
                                    <FontAwesomeIcon className="baseColorText" icon={passwordShown ? faEye : faEyeSlash} />
                                </div>
                            </FloatingLabel>
                            <div className="justify-content-end d-flex text-end mt-2">
                                <p className="pointer primaryColorT" onClick={(() => setModalShow(true))}>{t('t21')}</p>
                            </div>
                        </Row>
                        <button className="btn btn-lg btn-large primaryColor primaryColorText my-4 text-center" type="submit" disabled={isSubmitting}>{t('t22')}</button>
                    </Form>
                )
                }
            </Formik>
            <Modal
                className="m-auto"
                show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Formik
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            setLoading(true)
                            setExternalSubmitting(true)
                            axios.post(`/api/eventos/resetPassword`, {
                                "evento_title": dataApplication.titulo,
                                "evento_id": dataApplication.evento_id,
                                "customizacao_id": appState.customizacao_id,
                                "subject": appState.email.resetPassword.subject,
                                "html": appState.email.resetPassword.html,
                                "email": values.email
                            }).then((response) => {
                                if (response.status === 200) {
                                    setModalShow(false)
                                    notyf.open({
                                        type: "success",
                                        message: t('t84'),
                                        ripple: true,
                                        dismissible: true,
                                    });
                                } else if (response.status === 202) {
                                    notyf.open({
                                        type: "danger",
                                        message: t('t50'),
                                        ripple: true,
                                        dismissible: true,
                                    });
                                } else {
                                    setModalShow(false)
                                    notyf.open({
                                        type: "danger",
                                        message: t('t23'),
                                        ripple: true,
                                        dismissible: true,
                                    });
                                }
                                setLoading(false)
                                setExternalSubmitting(false);
                            }).catch((error) => {
                                setLoading(false)
                                setExternalSubmitting(false);
                                notyf.open({
                                    type: "danger",
                                    message: t('t23'),
                                    ripple: true,
                                    dismissible: true,
                                });
                            });
                        } catch (error) {
                            setLoading(false)
                            setExternalSubmitting(false);
                            notyf.open({
                                type: "danger",
                                message: error,
                                ripple: true,
                                dismissible: true,
                            });
                        }
                    }}
                    validationSchema={schemaRegister}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        email: "",
                    }}
                    enableReinitialize
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="resetUserModal">
                                <div className="text-center">
                                    <button type="button" className="btn btn-outline me-3" onClick={(() => setModalShow(false))}>{t('t55')}</button>
                                    <button className="btn primaryColor primaryColorText" type="submit" disabled={isExternalSubmitting}>{t('t83')}</button>
                                </div>
                                <Form.Group as={Col} md="12" controlId="email" className="mb-3">
                                    <Form.Label>{t('t19')}<span>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        isInvalid={!!errors.email}
                                        isValid={touched.email && !errors.email}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Form>
                    )
                    }
                </Formik >
            </Modal>
        </div>
    )
}

export default Login