import React, { useEffect, useState, useContext, useRef } from "react";
import axios from 'axios'
import { useGlobalState } from '../hooks/useCustomization.js';
import { PopoverPicker } from "./PopoverPicker.js";
import useOuterClick from "../hooks/useOuterClick.js";
import { Form, Col } from 'react-bootstrap'
import NotyfContext from "../contexts/NotyfContext.js";
import { domainConfig } from "../assets/config.js"

const Settings = () => {
    const { dataApplication } = useGlobalState();
    const [primaryColor, setPrimaryColor] = useState(dataApplication.customizacao.primaryColor);
    const [primaryColorText, setPrimaryColorText] = useState(dataApplication.customizacao.primaryColorText);
    const [backgroundColor, setBackgroundColor] = useState(dataApplication.customizacao.background.color);
    const [baseColorText, setBaseColorText] = useState(dataApplication.customizacao.baseColorText);
    const [selectedFont, setSelectedFont] = useState(dataApplication.customizacao.fontFamily);
    const [transparency, setTransparency] = useState(dataApplication.customizacao.transparency);
    const [boxShadow, setBoxShadow] = useState(dataApplication.customizacao.boxShadow);
    const [fonts, setFonts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const notyf = useContext(NotyfContext);
    const backgroundBlock = useRef(null)
    const logoBlock = useRef(null)

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });

    function handleColorPrimaryColor(e) {
        setPrimaryColor(e)
        document.documentElement.style.setProperty('--primaryColor', e);
    }

    function handleColorPrimaryColorText(e) {
        setPrimaryColorText(e)
        document.documentElement.style.setProperty('--primaryColorText', e);
    }

    function handleColorBackgroundColor(e) {
        setBackgroundColor(e)
        document.documentElement.style.setProperty('--backgroundColor', e);
        document.body.style.backgroundImage = "none";
        backgroundBlock.current.style.backgroundImage = "none";
    }

    function handleColorBaseColorText(e) {
        setBaseColorText(e)
        document.documentElement.style.setProperty('--baseColorText', e);
    }

    function handlefont(e) {
        setSelectedFont(e)
        document.documentElement.style.setProperty('--font', e);
    }

    function handleTransparency(e) {
        setTransparency(e)
        document.documentElement.style.setProperty('--transparency', e);
    }

    function handleShadow(e) {
        setBoxShadow(e)
        document.documentElement.style.setProperty('--boxShadow', e);
    }

    function onSelectFile(e, type) {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (e.target.files[0].errors) {
            notyf.open({
                type: "danger",
                message: 'Aconteceu algum problema, tente novamente',
                ripple: true,
                dismissible: true,
            });
        } else if (!validImageTypes.includes(fileType)) {
            notyf.open({
                type: "danger",
                message: 'Formato de imagem inválido',
                ripple: true,
                dismissible: true,
            });
        } else {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (type === 'background') {
                    setBackgroundColor('rgba(255, 255, 255, 0)')
                    document.documentElement.style.setProperty('--backgroundColor', 'rgba(255, 255, 255, 0)');
                    document.body.style.backgroundImage = "url(" + e.target.result + ")";
                    backgroundBlock.current.style.backgroundImage = "url(" + e.target.result + ")";
                } else if (type === 'logo') {
                    dataApplication.customizacao.logo = e.target.result;
                    logoBlock.current.style.backgroundImage = "url(" + e.target.result + ")";
                    const elements = document.querySelectorAll('.App-logo');
                    elements.forEach(element => {
                        element.src = e.target.result;
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        logoBlock.current.style.backgroundImage = "url(" + domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo + ")";
        backgroundBlock.current.style.backgroundImage = "url(" + domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.background.url + ")";
        axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBQSHG64iczeP5lb7OvezR9kkn0LSU8vEc&sort=popularity`)
            .then((response) => {
                let placeIDs = []
                response.data.items.slice([0], [50]).map((item, i) => {
                    return placeIDs.push({ font: item.family });
                });
                setFonts(placeIDs)
            }).catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFont]);

    return (
        <div
            ref={innerRef}
            className={`settings ${isOpen ? "open" : ""}`}
        >
            <div className="settings-toggle">
                <div
                    className="settings-toggle-option settings-toggle-option-text js-settings-toggle bg-dark"
                    title="Theme Builder"
                    onClick={() => setIsOpen(true)}
                >
                    Customizar
                </div>
            </div>
            <div className="settings-panel">
                <div className="settings-content">
                    <div className="settings-body">
                        <div className="modal-header">
                            <div className="modal-title h4">
                                Customização
                            </div>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setIsOpen(false)}></button>
                        </div>
                        <hr></hr>
                        <div className="mb-3 text-start">
                            <span className="d-block font-size-lg fw-bold">Logomarca</span>
                            <div className="row">
                                <div className="col mb-2">
                                    <div className="d-flex justify-content-start">
                                        <label
                                            className="position-relative pointer w-100 d-flex"
                                            htmlFor="my-file2"
                                        >
                                            <div className="btn btn-sm btn-dark mt-1 w-100">Adicionar imagem</div>
                                        </label>
                                        <input
                                            className="d-none"
                                            type="file"
                                            id="my-file2"
                                            onChange={(e) => {
                                                onSelectFile(e, 'logo');
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-0 mx-n1 mb-3">
                                <div className="col colorBlock ms-0 me-2" ref={logoBlock}>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 text-start">
                            <span className="d-block font-size-lg fw-bold">Esquema de cores</span>
                            <span className="d-block text-muted mb-2">
                                Selecione as cores de fundo e dos textos para alterar a customização atual
                            </span>
                            <div className="row mb-2">
                                <div className="d-flex justify-content-start">
                                    <PopoverPicker color={backgroundColor} onChangeX={handleColorBackgroundColor} classes="me-1 picker" />
                                    <PopoverPicker color={baseColorText} onChangeX={handleColorBaseColorText} classes="me-1 picker" />
                                    <PopoverPicker color={transparency} onChangeX={handleTransparency} classes="me-1 picker" />
                                    <PopoverPicker color={boxShadow} onChangeX={handleShadow} classes="me-1 picker" />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label
                                    className="position-relative pointer"
                                    htmlFor="my-file"
                                >
                                    <div className="btn btn-sm btn-dark w-100">Adicionar imagem</div>
                                </label>
                                <input
                                    className="d-none"
                                    type="file"
                                    id="my-file"
                                    onChange={(e) => {
                                        onSelectFile(e, 'background');
                                    }}
                                />
                            </div>
                            <div className="row g-0 mx-n1 mb-3">
                                <div className="col colorBlock ms-0 me-2" ref={backgroundBlock} style={{
                                    backgroundColor: `${backgroundColor}`,
                                }}>
                                    <div className="p-4" style={{
                                        backgroundColor: `${transparency}`, boxShadow: `0 .5rem 1rem ${boxShadow}`
                                    }}>
                                        <span style={{
                                            color: `${baseColorText}`,
                                        }} className="text-center h6 mb-0">Cor de fundo</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-2">
                                    <div className="d-flex justify-content-start">
                                        <PopoverPicker color={primaryColor} onChangeX={handleColorPrimaryColor} classes="me-1 picker" />
                                        <PopoverPicker color={primaryColorText} onChangeX={handleColorPrimaryColorText} classes="ms-1 picker" />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-0 mx-n1 mb-2">
                                <div className="col colorBlock ms-0 me-2" style={{
                                    backgroundColor: `${primaryColor}`,
                                }}>
                                    <span style={{
                                        color: `${primaryColorText}`,
                                    }} className="text-center h6 mb-0">Cor primária</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 text-start">
                            <span className="d-block font-size-lg fw-bold">Fonte</span>
                            <span className="d-block text-muted mb-2">
                                Será usada a mesma fonte para todos os textos
                            </span>
                            <Form.Group as={Col} md="12" controlId="language" className='mb-3'>
                                <Form.Select
                                    name="language"
                                    value={selectedFont}
                                    onChange={(e) => {
                                        handlefont(e.target.value, 'logo');
                                    }}
                                >
                                    {fonts.map((element, index) => {
                                        return (
                                            <option style={{ 'fontFamily': element.font }} key={index} value={element.font}>
                                                {element.font}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    {/* <div className="settings-footer">
                        <div className="d-grid">
                            <button className="btn btn-dark"
                                onClick={() => setIsOpen(false)}>
                                Voltar
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >
    );
};

export default Settings;
