import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'

const StartAnimation = ({ startMedia, baseValue = 5 }) => {
  const [startCountdown, setStartCountdown] = useState(undefined)
  const [number, setNumber] = useState(baseValue)

  useEffect(() => {
    setStartCountdown(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountdown])

  useEffect(() => {
    if (startCountdown) {
      const intervalId = setInterval(() => {
        if (number > 1) {
          setNumber(number - 1)
        }
      }, 1000)

      if (number === 1) {
        setTimeout(() => {
          startMedia()
        }, 1000);
      }
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountdown, number])

  return (
    <motion.div
      key={number}
      initial={{ scale: 0.75 }}
      animate={{ scale: 1 }}
      exit={{ opacity: 0, scale: 0.75 }}
      transition={{ duration: 0.5 }}
    >
      <span>{number}</span>
    </motion.div>
  )
}

export default StartAnimation
