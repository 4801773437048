import React from 'react'
import { Dropdown, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faQrcode,
    faDisplay,
    faVolumeHigh,
    faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import Fullscreen from '../../../components/FullScreen.js'
import { domainConfig } from "../../../assets/config.js"
import { PrizeDrawQRCodeModal, PrizeDrawModal } from '../Presenter.js'

export const NavbarPresenter = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, showPresentation, showQRCode, setShowQRCode, setShowPresentation, isPlaying, setIsPlaying } = useAppState();

    return (
        <>
            <div className="horizontally-centered">
                <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo d-inline-flex"></img>
            </div>
            <div></div>
            <div className="horizontally-centered">
                <button className="btn me-2 primaryColor primaryColorText" onClick={() => setIsPlaying(!isPlaying)}>
                    {isPlaying === true ? (
                        <FontAwesomeIcon icon={faVolumeHigh} />) : (<FontAwesomeIcon icon={faVolumeXmark} />)}
                </button>
                {appState.prizeDrawType === 2 && <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Modo QRCode</Tooltip>}
                >
                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowQRCode(true)}>
                        <FontAwesomeIcon icon={faQrcode} className="primaryColorText" />
                    </button>
                </OverlayTrigger>}
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={<Tooltip>Modo de apresentação</Tooltip>}
                >

                    <button className="btn primaryColor primaryColorText pointer me-2" type="button" onClick={() => setShowPresentation(true)}>
                        <FontAwesomeIcon icon={faDisplay} className="primaryColorText" />
                    </button>
                </OverlayTrigger>
                <Dropdown className="d-inline" align="end">
                    <Dropdown.Toggle className="btn primaryColor primaryColorText" variant="" id="dropdown-autoclose-true">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Fullscreen />
                        <Dropdown.Item onClick={(() => handleExit())}>Voltar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal show={showQRCode} fullscreen={true}>
                <div className="modalPresenter">
                    <div className="image-side"></div>
                    <div className="text-side">
                        <PrizeDrawQRCodeModal />
                    </div>
                </div>
            </Modal>

            <Modal show={showPresentation} fullscreen={true}>
                <div className="modalPresenter">
                    <div className="image-side"></div>
                    <div className="text-side">
                        <PrizeDrawModal />
                    </div>
                </div>
            </Modal>
        </>
    )
}